/*
 * @Author: beenlezhang
 * @Date: 2022-04-06 20:20:47
 * @LastEditors: henghaowu
 * @LastEditTime: 2022-08-05
 * @Descripttion:
 */
interface Map {
  [key: string]: any;
}

const APPLY_COLUMNS: Map[] = [
  { title: '申请内容', dataIndex: 'content', key: 'content' },
  { title: '内容类型', dataIndex: 'categoryName', key: 'categoryName' },
  { title: '申请权限类型', dataIndex: 'roleName', key: 'roleName' },
  { title: '审批通过的权限', dataIndex: 'roleName', key: 'roleName' },
  { title: '当前审批人', dataIndex: 'handlerName', key: 'handler' },
  { title: '申请内容的路径', dataIndex: 'location', key: 'location', ellipsis: true },
  { title: '审批进度', dataIndex: 'currentStateName', key: 'currentState' },
];

const APPROVE_COLUMNS: Map[] = [
  { title: '申请人', dataIndex: 'applicantName', key: 'content' },
  { title: '申请内容', dataIndex: 'content', key: 'categoryName' },
  { title: '内容类型', dataIndex: 'categoryName', key: 'roleName' },
  { title: '申请内容的路径', dataIndex: 'location', key: 'roleName', ellipsis: true },
  { title: '申请权限类型', dataIndex: 'roleName', key: 'handler' },
  { title: '审批通过的权限', dataIndex: 'roleName', key: 'location' },
  { title: '审批进度', dataIndex: 'currentStateName', key: 'currentState' },
];

const APPLY_ACTION_COLUMNS: Map[] = [
  ...APPLY_COLUMNS,
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
    width: '170px',
  },
];

const APPROVE_ACTION_COLUMNS: Map[] = [
  ...APPROVE_COLUMNS,
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
    width: '170px',
  },
];

const QUICK_LOGIN_METHODS = ['wechat', 'wework'];

const QUICK_LOGIN_NAME: Map = {
  wechat: {
    name: '微信',
    channel: 'WX',
  },
  wework: {
    name: '企业微信',
    channel: 'QYWX',
  },
};

export {
  APPLY_COLUMNS,
  APPLY_ACTION_COLUMNS,
  APPROVE_COLUMNS,
  APPROVE_ACTION_COLUMNS,
  QUICK_LOGIN_METHODS,
  QUICK_LOGIN_NAME,
};
