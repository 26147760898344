import { EditTableProps } from '@/@types/auth';
import { isEqual } from 'lodash-es';

interface Message {
  type: string;
  message: string;
}

export default {
  default(
    { level1, level2 }: { level1?: string[]; level2?: string[] },
    callback?: (info: Message) => void
  ) {
    // 一级审批人不能为空
    if (level1?.length === 0) {
      callback?.({
        type: 'warning',
        message: '空间的一级审批人不可为空，请选择一级审批人',
      });
      return false;
    }
    // 一二级审批人不能相同
    if (isEqual(level1?.sort(), level2?.sort())) {
      callback?.({
        type: 'warning',
        message: '空间的一级审批人和二级审批人，不可相同',
      });
      return false;
    }
    return true;
  },
  custom(tableData: EditTableProps['tableData'], callback?: (info: Message) => void) {
    for (const row of tableData) {
      // 角色组不能为空
      if (!row.ids?.length) {
        callback?.({
          type: 'warning',
          message: '角色组不能为空，请至少选择一个角色组',
        });
        return false;
      }
      // 一级审批人不能为空
      if (!row.level1?.length) {
        callback?.({
          type: 'warning',
          message: '空间的一级审批人不可为空，请选择一级审批人',
        });
        return false;
      }
      // 一二级审批人不能相同
      if (isEqual(row.level1?.sort(), row.level2?.sort())) {
        callback?.({
          type: 'warning',
          message: '空间的一级审批人和二级审批人，不可相同',
        });
        return false;
      }
    }

    return true;
  },
};
