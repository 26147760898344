/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-10-16 16:05:34
 * @Descripttion:
 */
import { StateType } from '@/@types';
import { Module } from 'vuex';
import { unlimitedQuota } from '@/config/constant';

const state = {
  appKeys: '',
  status: 0,
  isAdmin: false,
  // 当前用户是否有管理空间权限
  hasEditAuth: false,
  // 行业列表
  industryList: [],
  // 企业成员列表
  memberList: [],
  // 空间树
  bizCount: 0,
  // 成员数
  memberCount: 0,
  appList: [],
  // 企业信息
  detail: {
    logo: '',
    contact: '',
    phone: '',
    app_keys: '',
    enabled_busi: null,
    create_time: '',
    corporation_name: '',
    corporation_id: '',
    allowEmailSuffixes: '',
    first: '',
    managerList: [],
  },
  spaceDetail: {},
  busiAdminList: [], // 业务管理员列表
  quotaMap: {
    CorpResource_BusiNumPerCorp: unlimitedQuota,
    CorpResource_BizNumPerCorp: unlimitedQuota,
    CorpResource_MemberNumPerCorp: unlimitedQuota,
  }, // 套件云市场配置列表 -1为无限制，大于0为限额
};

type CompanyStateType = typeof state;

const company: Module<CompanyStateType, StateType> = {
  namespaced: true,
  ...state,
};

export { CompanyStateType, state };

export default company;
