/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-09-30 17:35:46
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-09-30 17:36:14
 * @Description: 计时器
 *
 */
import { ref, computed } from 'vue';
import { useIntervalFn } from '@vueuse/core';

export const useAuthTimeout = () => {
  const counter = ref(0);
  const { resume } = useIntervalFn(() => {
    if (counter.value === 0) return;
    counter.value -= 1;
  }, 1000);
  const resetCounter = () => {
    counter.value = 60;
    resume();
  };
  const timeEnd = computed(() => counter.value === 0);
  return {
    counter,
    timeEnd,
    resetCounter,
  };
};
