/*
 * @Author: beenlezhang
 * @Date: 2021-11-15 10:34:09
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-04 16:48:52
 * @Descripttion:
 */
import { loginTip } from '@/config/constant';

// 校验用户名
export const checkName = (value: string): string => {
  if (!value?.trim()) {
    return loginTip.emptyName;
  }
  return value?.length <= 20 ? '' : loginTip.name20Less;
};

// 校验强密码
export const checkPassword = (value: string): string => {
  const pattern = /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*?[@$!%*?&`~#^()\-_+=[\]{}\\|:;'"<>,./]).*$/;
  return pattern.test(value) ? '' : loginTip.weakPwd;
};

// 校验强密码
export const checkPasswordStatus = (value: string): {} => {
  const passwordValidate = /^[A-Za-z](?=.*?[0-9])(?=.*?[_!@#$%^&*+-.~])[A-Za-z0-9_!@#$%^&*+-.~]{7,}$/;
  return {
    moreThan8: value.length >= 8,
    charValid: passwordValidate.test(value),
  };
};

// 校验确认密码
export const checkRepeatPassword = (value: string, password: string): string => {
  return password === value && value ? '' : loginTip.pwdNotEqual;
};

// 校验手机号
export const checkPhone = (value: string, required: boolean): string => {
  if (!value && !required) {
    return '';
  }
  return /^1[3-9]\d{9}$/.test(value) ? '' : loginTip.invalidPhone;
};

// 校验带区号的手机号，首字母为+或者数字，后面全是数字
export const checkPhoneWithOptionalAreaCode = (value: string, required: boolean): string => {
  if (!value && !required) {
    return '';
  }
  return /^\+?[0-9]*$/.test(value) ? '' : loginTip.invalidPhone;
};

// 校验邮箱
export const checkEmail = (value = ''): string => {
  return /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/.test(value)
    ? ''
    : loginTip.invalidEmail;
};

// 校验验证码
export const checkVerifyCode = (value = ''): string => {
  return /^[A-ZA-z0-9]{4,6}$/.test(value) ? '' : loginTip.invalidCodeFormat;
};
