/*
 * @Author: beenlezhang
 * @Date: 2021-02-22 10:01:12
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-24 15:08:42
 * @Descripttion:
 */
/**
 * 所有跟用户相关的接口
 */

import {
  HttpResponse,
  BizParams,
  SaveRoleParams,
  MemberParams,
  DeleteRoleParams,
  ApprovalParams,
  AuthorizeParams,
  SearchMemberParam,
  ConfigAuthParams,
  InviteItem,
} from '@/@types';
import {
  AuthListParams,
  ModuleParam,
  UpdateRoleInfoParam,
  AddRoleParams,
  SaveMemberParams,
  DeleteMemberParams,
  ApiSaveData,
} from '@/@types/auth';
import Axios from './axios';

class AuthService {
  // 检查是否是空间管理员
  public static async checkBizAdmin(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/check_biz_admin', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取空间信息
  public static async getBizInfo(params: BizParams): Promise<HttpResponse> {
    return Axios('/server/tob/biz', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取空间信息
  public static async getBizList(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/union', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  /**
   * 获取审批列表
   * @param page 页码
   * @param limit 每页数量
   * @param create_time_asc 按申请时间升序排序方式，0-降序，1-升序
   * @param status 审批状态，0-待审批，1-通过，2-拒绝，-1-全部
   * @param approval 审批人，0-自己，1-其他人，-1-全部
   * @param data_type 审批数据类型，0-不限，1-BIZ，2-资产
   */
  public static async getApprovalList(params: ApprovalParams): Promise<HttpResponse> {
    return Axios('/server/tob/list', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 权限审批
  public static async authorize(params: AuthorizeParams): Promise<HttpResponse> {
    return Axios('/server/tob/authorize', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 保存角色组权限
  public static async saveRoleAuth(params: BizParams, data: SaveRoleParams): Promise<HttpResponse> {
    return Axios('/server/tob/role', {
      method: 'put',
      responseType: 'json',
      params,
      data,
    });
  }

  // 创建角色组
  public static async addRole(params: BizParams, data: AddRoleParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 删除角色组
  public static async deleteRole(params: BizParams, data: DeleteRoleParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role', {
      method: 'delete',
      responseType: 'json',
      params,
      data,
    });
  }

  // 获取成员列表
  public static async getMemberList(params: MemberParams): Promise<HttpResponse> {
    return Axios('/server/tob/role_member', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 更新成员列表
  public static async updateMember(
    params: BizParams,
    data: SaveMemberParams
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/role_member', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 获取成员列表
  public static async getRoleMemberList(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role_member', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 删除成员
  public static async deleteMember(
    params: BizParams,
    data: DeleteMemberParams
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/role_member', {
      method: 'delete',
      responseType: 'json',
      params,
      data,
    });
  }

  // 编辑团队成员
  public static searchMember(params: SearchMemberParam): Promise<HttpResponse> {
    return Axios('/server/tob/search', {
      method: 'get',
      params,
    });
  }

  // 获取appkey
  public static getAppKey(): Promise<HttpResponse> {
    return Axios('/server/tob/app_key/auth', {
      method: 'get',
    });
  }

  // 获取appkey、微信app_id
  public static getAppConfig(params: ConfigAuthParams): Promise<HttpResponse> {
    // return Axios('/server/tob/config/auth', {
    return Axios('/authserver/login/config', {
      method: 'get',
      params,
    });
  }

  // 获取appkey、微信app_id
  public static getPlatformApp(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/platform/app', {
      method: 'get',
      params,
    });
  }

  // 获取申请列表
  public static getApplyList(data: AuthListParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/apply/list', {
      method: 'post',
      data,
    });
  }

  // 获取审批列表
  public static getApproveList(data: AuthListParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/apply/list/approval', {
      method: 'post',
      data,
    });
  }

  // 催一催
  public static repush(data: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/apply/repush', {
      method: 'post',
      data,
    });
  }

  // 审批拒绝
  public static applyApprove(data: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/apply/approve', {
      method: 'post',
      data,
    });
  }

  // 获取用户角色组
  public static getMyRole(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role/listMyRole', {
      method: 'get',
      params,
    });
  }

  // 获取角色组列表
  public static getRoleList(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role', {
      method: 'get',
      params,
    });
  }

  // 获取资产审批人
  public static async getAssetApprover(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/process/single', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取资产审批人
  public static async getBizApprover(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/process/v1/biz', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取资产审批人
  public static async getRoleAdminList(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role/admins', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 修改资产审批人
  public static async updateAssetApprover(data: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/process/single?', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改空间审批人
  public static async updateBizApprover(data: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/process/single?', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改空间审批人
  public static async getMyRoleList(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role/listMyRole', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  public static async searchOrgByOrgNameLike(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/org/queryOrgByOrgNameLike', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  public static async getNextOrgInfoListByCorpId(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/org/getNextOrgInfoListByCorpId', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  public static async searchUserByKey(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/listOaUserByKey', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  public static async searchUserByKeyCommon(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/searchMember', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 修改空间审批人
  public static async updateRoleModule(
    params: BizParams,
    data: ModuleParam
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/role', {
      method: 'put',
      responseType: 'json',
      params,
      data,
    });
  }

  // 编辑角色组信息
  public static async updateRoleInfo(
    params: BizParams,
    data: UpdateRoleInfoParam
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/role/updateRoleInfo', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 申请加入角色组
  public static async applyRole(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/apply/applyBizRole', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 申请加入角色组
  public static async getBizCorporation(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/get/corporation', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 防回放接口
  public static async getPrKey(): Promise<HttpResponse> {
    return Axios('/authserver/platform/pr/key', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取企业开通的app
  public static async getCorporationAppList(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/get/corporation/apps', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 确认接受邀请
  public static async acceptInvite(params: InviteItem): Promise<HttpResponse> {
    return Axios('/authserver/corporation/outer/invite', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 取消邀请
  public static async cnacelInvite(params: InviteItem): Promise<HttpResponse> {
    return Axios('/authserver/corporation/outer/invite', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  public static async getPlatformList(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/platform/getShowApp', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  public static async getListBaseInfoByAppkey(data: BizParams): Promise<HttpResponse> {
    return Axios(`/authserver/web/asset/listBaseInfoByAppKey?appKey=${data?.appKey}`, {
      method: 'post',
      responseType: 'json',
      data,
    });
  }
  public static async queryAssetByPage(data: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/asset/queryAssetByPage', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }
  public static async queryAssetTree(data: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/asset/queryAssetTree', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }
  public static async getPermInfo(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/listBizCategoryPermission', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  public static async updateBizCategoryPermission(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/updateBizCategoryPermission', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }
  public static async updateRoleName(params: BizParams, data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/role/updateRoleName', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }
  public static async listMemberWithRolesByPage(params: BizParams): Promise<HttpResponse> {
    return Axios('/authserver/web/role/listMemberWithRolesByPage', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  public static async removeBizPermission(params: BizParams, data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/role/removeBizPermission', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }
  public static async copyPermission(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/asset/copyPermission', {
      method: 'post',
      responseType: 'json',
      params,
    });
  }
  public static async grantAsset(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/asset/grant', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }
  public static async handlerQuery(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/apply/handler/query', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  public static async queryAssetByPageWithPerm(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/asset/queryAssetByPageWithPerm', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 查询权限类型配置
  public static async queryAuthListConfig(params: {
    appKey: string;
    categoryKey: string;
  }): Promise<HttpResponse> {
    return Axios('/authserver/web/action/queryActionConfig', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 查询具备GRANT权限的Action
  public static async queryCanGrantAction(params: {
    appKey: string;
    categoryKey: string;
  }): Promise<HttpResponse> {
    return Axios('/authserver/web/action/queryCanGrantAction', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 资产权限导出
  public static async downloadDataAuthAction(data: {
    appKeys: string[];
    bizIds: string[];
  }): Promise<HttpResponse> {
    return Axios('/authserver/web/asset/exportPermission', {
      method: 'post',
      data,
    });
  }

  // 角色信息导出
  public static async downloadRoleInfoAction(data: {
    appKeys: string[];
    bizIds: string[];
  }): Promise<HttpResponse> {
    return Axios('/authserver/web/role/exportPermission', {
      method: 'post',
      data,
    });
  }

  public static async getCommonConfigFromRainbow(): Promise<HttpResponse> {
    return Axios('/authserver/web/commonConfig/getFrontendCommonConfig', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取空间审批流程
  public static async getSpaceApplyProcess(): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/process/v1/biz', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 保存空间审批流程
  public static async saveSpaceApplyProcess(data: ApiSaveData): Promise<HttpResponse> {
    return Axios('/authserver/web/auth/process/saveBizProcess', {
      method: 'post',
      data,
    });
  }

  // 创建推送管理配置
  public static async createPushConfig(data: any): Promise<HttpResponse> {
    const options = {
      baseURL: '',
      url: `/api/suiteserver/web/download_behavior/push_config/create`,
      method: 'post' as any,
      data,
      withCredentials: true,
    };
    return Axios.request(options);
  }
  // 查看配置详情
  public static async getPushConfigDetail(data: any): Promise<HttpResponse | any> {
    const options = {
      baseURL: '',
      url: `/api/suiteserver/web/download_behavior/push_config/detail`,
      method: 'get' as any,
      params: data,
      withCredentials: true,
    };
    return Axios.request(options);
  }
  // 推送管理状态
  public static async changePushConfigStatus(data: any): Promise<HttpResponse> {
    const options = {
      baseURL: '',
      url: `/api/suiteserver/web/download_behavior/push_config/change_status`,
      method: 'post' as any,
      params: data,
      withCredentials: true,
    };
    return Axios.request(options);
  }
  // 创建推送管理配置
  public static async editPushConfig(data: any): Promise<HttpResponse> {
    const options = {
      baseURL: '',
      url: `/api/suiteserver/web/download_behavior/push_config/edit`,
      method: 'post' as any,
      data,
      withCredentials: true,
    };
    return Axios.request(options);
  }
  // 获取全公司用户列表：key为查询关键词
  public static async getTencentUserList(params: any): Promise<HttpResponse> {
    const options = {
      baseURL: '',
      url: `/api/datatalk/server/common/oa/users`,
      method: 'get' as any,
      params,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      withCredentials: true,
    };
    return Axios.request(options);
  }
  // 修改推送时间
  public static async downPushAction(data: any): Promise<HttpResponse> {
    const options = {
      baseURL: '',
      url: `/api/datatalk/server/download_push/action`,
      method: 'post' as any,
      params: {
        bizId: data.bizId,
      },
      data,
      withCredentials: true,
    };
    return Axios.request(options);
  }
}

export default AuthService;
