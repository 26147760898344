import { ElCheckbox, ElMessageBox } from 'element-plus';
import { h, ref } from 'vue';

const NOTIFY_ROW_COL_RULE_UPDATE = 'NOTIFY_ROW_COL_RULE_UPDATE';

export async function notifyOnSave() {
  if (localStorage.getItem(NOTIFY_ROW_COL_RULE_UPDATE)) {
    return;
  }
  const hideOnNext = ref<boolean>(false);
  await ElMessageBox({
    title: '提示',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    message: h(() =>
      h('div', { style: 'font-size: 14px; line-height: 24px;' }, [
        h(
          'div',
          null,
          '删除或修改字段key后，已使用该字段key构建的行列规则即失效；请保存后及时修改权限规则。'
        ),
        h(ElCheckbox, {
          modelValue: hideOnNext.value,
          label: '不再提醒',
          ['onUpdate:modelValue']: (val: boolean) => {
            hideOnNext.value = val;
          },
        }),
      ])
    ),
  });
  if (hideOnNext.value) {
    localStorage.setItem(NOTIFY_ROW_COL_RULE_UPDATE, 'true');
  }
}
