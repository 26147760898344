/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-11-17 10:52:06
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-11-17 10:54:41
 * @Description: 上报相关
 *
 */
import beaconSDK from '@/utils/report';
import { useDebounceFn } from '@vueuse/core';

const debounceFn = useDebounceFn(() => {
  beaconSDK.sdkReport('page_visit', {});
}, 200);

export const reportPv = (): void => {
  debounceFn();
};
