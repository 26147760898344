// 城市列表
const tmp = [
  {
    english: 'Chinese Mainland',
    name: '中国大陆',
    code: '+86',
  },
  {
    english: 'Taiwan',
    name: '中国台湾',
    code: '+886',
  },
  {
    english: 'Hong Kong',
    name: '中国香港',
    code: '+852',
  },
  {
    english: 'Macao',
    name: '中国澳门',
    code: '+853',
  },
  {
    english: 'Afghanistan',
    name: '阿富汗',
    code: '+93',
  },
  {
    english: 'Albania',
    name: '阿尔巴尼亚',
    code: '+355',
  },
  {
    english: 'Algeria',
    name: '阿尔及利亚',
    code: '+213',
  },
  {
    english: 'American Samoa',
    name: '美属萨摩亚',
    code: '+1684',
  },
  {
    english: 'Andorra',
    name: '安道尔',
    code: '+376',
  },
  {
    english: 'Angola',
    name: '安哥拉',
    code: '+244',
  },
  {
    english: 'Anguilla',
    name: '安圭拉',
    code: '+1264',
  },
  {
    english: 'Antigua and Barbuda',
    name: '安提瓜和巴布达',
    code: '+1268',
  },
  {
    english: 'Argentina',
    name: '阿根廷',
    code: '+54',
  },
  {
    english: 'Armenia',
    name: '亚美尼亚',
    code: '+374',
  },
  {
    english: 'Aruba',
    name: '阿鲁巴',
    code: '+297',
  },
  {
    english: 'Australia',
    name: '澳大利亚',
    code: '+61',
  },
  {
    english: 'Austria',
    name: '奥地利',
    code: '+43',
  },
  {
    english: 'Azerbaijan',
    name: '阿塞拜疆',
    code: '+994',
  },
  {
    english: 'Bahamas',
    name: '巴哈马',
    code: '+1242',
  },
  {
    english: 'Bahrain',
    name: '巴林',
    code: '+973',
  },
  {
    english: 'Bangladesh',
    name: '孟加拉国',
    code: '+880',
  },
  {
    english: 'Barbados',
    name: '巴巴多斯',
    code: '+1246',
  },
  {
    english: 'Belarus',
    name: '白俄罗斯',
    code: '+375',
  },
  {
    english: 'Belgium',
    name: '比利时',
    code: '+32',
  },
  {
    english: 'Belize',
    name: '伯利兹',
    code: '+501',
  },
  {
    english: 'Benin',
    name: '贝宁',
    code: '+229',
  },
  {
    english: 'Bermuda',
    name: '百慕大群岛',
    code: '+1441',
  },
  {
    english: 'Bhutan',
    name: '不丹',
    code: '+975',
  },
  {
    english: 'Bolivia',
    name: '玻利维亚',
    code: '+591',
  },
  {
    english: 'Bosnia and Herzegovina',
    name: '波斯尼亚和黑塞哥维那',
    code: '+387',
  },
  {
    english: 'Botswana',
    name: '博茨瓦纳',
    code: '+267',
  },
  {
    english: 'Brazil',
    name: '巴西',
    code: '+55',
  },
  {
    english: 'Brunei',
    name: '文莱',
    code: '+673',
  },
  {
    english: 'Bulgaria',
    name: '保加利亚',
    code: '+359',
  },
  {
    english: 'Burkina Faso',
    name: '布基纳法索',
    code: '+226',
  },
  {
    english: 'Burundi',
    name: '布隆迪',
    code: '+257',
  },
  {
    english: 'Cambodia',
    name: '柬埔寨',
    code: '+855',
  },
  {
    english: 'Cameroon',
    name: '喀麦隆',
    code: '+237',
  },
  {
    english: 'Canada',
    name: '加拿大',
    code: '+1',
  },
  {
    english: 'Cape Verde',
    name: '佛得角',
    code: '+238',
  },
  {
    english: 'Cayman Islands',
    name: '开曼群岛',
    code: '+1345',
  },
  {
    english: 'Central African Republic',
    name: '中非共和国',
    code: '+236',
  },
  {
    english: 'Chad',
    name: '乍得',
    code: '+235',
  },
  {
    english: 'Chile',
    name: '智利',
    code: '+56',
  },
  {
    english: 'Colombia',
    name: '哥伦比亚',
    code: '+57',
  },
  {
    english: 'Comoros',
    name: '科摩罗',
    code: '+269',
  },
  {
    english: 'Cook Islands',
    name: '库克群岛',
    code: '+682',
  },
  {
    english: 'Costa Rica',
    name: '哥斯达黎加',
    code: '+506',
  },
  {
    english: "Côte d'Ivoire",
    name: '科特迪瓦',
    code: '+225',
  },
  {
    english: 'Croatia',
    name: '克罗地亚',
    code: '+385',
  },
  {
    english: 'Curacao',
    name: '库拉索',
    code: '+599',
  },
  {
    english: 'Cyprus',
    name: '塞浦路斯',
    code: '+357',
  },
  {
    english: 'Czechia',
    name: '捷克',
    code: '+420',
  },
  {
    english: 'Democratic Republic of the Congo',
    name: '刚果民主共和国',
    code: '+243',
  },
  {
    english: 'Denmark',
    name: '丹麦',
    code: '+45',
  },
  {
    english: 'Djibouti',
    name: '吉布提',
    code: '+253',
  },
  {
    english: 'Dominica',
    name: '多米尼克',
    code: '+1767',
  },
  {
    english: 'Dominican Republic',
    name: '多米尼加共和国',
    code: '+1809/1829/1849',
  },
  {
    english: 'Ecuador',
    name: '厄瓜多尔',
    code: '+593',
  },
  {
    english: 'Egypt',
    name: '埃及',
    code: '+20',
  },
  {
    english: 'El Salvador',
    name: '萨尔瓦多',
    code: '+503',
  },
  {
    english: 'Equatorial Guinea',
    name: '赤道几内亚',
    code: '+240',
  },
  {
    english: 'Eritrea',
    name: '厄立特里亚',
    code: '+291',
  },
  {
    english: 'Estonia',
    name: '爱沙尼亚',
    code: '+372',
  },
  {
    english: 'Eswatini',
    name: '斯威士兰',
    code: '+268',
  },
  {
    english: 'Ethiopia',
    name: '埃塞俄比亚',
    code: '+251',
  },
  {
    english: 'Faroe Islands',
    name: '法罗群岛',
    code: '+298',
  },
  {
    english: 'Fiji',
    name: '斐济',
    code: '+679',
  },
  {
    english: 'Finland',
    name: '芬兰',
    code: '+358',
  },
  {
    english: 'France',
    name: '法国',
    code: '+33',
  },
  {
    english: 'French Guiana',
    name: '法属圭亚那',
    code: '+594',
  },
  {
    english: 'French Polynesia',
    name: '法属波利尼西亚',
    code: '+689',
  },
  {
    english: 'Gabon',
    name: '加蓬',
    code: '+241',
  },
  {
    english: 'Gambia',
    name: '冈比亚',
    code: '+220',
  },
  {
    english: 'Georgia',
    name: '格鲁吉亚',
    code: '+995',
  },
  {
    english: 'Germany',
    name: '德国',
    code: '+49',
  },
  {
    english: 'Ghana',
    name: '加纳',
    code: '+233',
  },
  {
    english: 'Gibraltar',
    name: '直布罗陀',
    code: '+350',
  },
  {
    english: 'Greece',
    name: '希腊',
    code: '+30',
  },
  {
    english: 'Greenland',
    name: '格陵兰岛',
    code: '+299',
  },
  {
    english: 'Grenada',
    name: '格林纳达',
    code: '+1473',
  },
  {
    english: 'Guadeloupe',
    name: '瓜德罗普岛',
    code: '+590',
  },
  {
    english: 'Guam',
    name: '关岛',
    code: '+1671',
  },
  {
    english: 'Guatemala',
    name: '危地马拉',
    code: '+502',
  },
  {
    english: 'Guinea',
    name: '几内亚',
    code: '+224',
  },
  {
    english: 'Guinea-Bissau',
    name: '几内亚比绍共和国',
    code: '+245',
  },
  {
    english: 'Guyana',
    name: '圭亚那',
    code: '+592',
  },
  {
    english: 'Haiti',
    name: '海地',
    code: '+509',
  },
  {
    english: 'Honduras',
    name: '洪都拉斯',
    code: '+504',
  },
  {
    english: 'Hungary',
    name: '匈牙利',
    code: '+36',
  },
  {
    english: 'Iceland',
    name: '冰岛',
    code: '+354',
  },
  {
    english: 'India',
    name: '印度',
    code: '+91',
  },
  {
    english: 'Indonesia',
    name: '印度尼西亚',
    code: '+62',
  },
  {
    english: 'Iraq',
    name: '伊拉克',
    code: '+964',
  },
  {
    english: 'Ireland',
    name: '爱尔兰',
    code: '+353',
  },
  {
    english: 'Israel',
    name: '以色列',
    code: '+972',
  },
  {
    english: 'Italy',
    name: '意大利',
    code: '+39',
  },
  {
    english: 'Jamaica',
    name: '牙买加',
    code: '+1876',
  },
  {
    english: 'Japan',
    name: '日本',
    code: '+81',
  },
  {
    english: 'Jordan',
    name: '约旦',
    code: '+962',
  },
  {
    english: 'Kazakhstan',
    name: '哈萨克斯坦',
    code: '+7',
  },
  {
    english: 'Kenya',
    name: '肯尼亚',
    code: '+254',
  },
  {
    english: 'Kiribati',
    name: '基里巴斯',
    code: '+686',
  },
  {
    english: 'Kuwait',
    name: '科威特',
    code: '+965',
  },
  {
    english: 'Kyrgyzstan',
    name: '吉尔吉斯斯坦',
    code: '+996',
  },
  {
    english: 'Laos',
    name: '老挝',
    code: '+856',
  },
  {
    english: 'Latvia',
    name: '拉脱维亚',
    code: '+371',
  },
  {
    english: 'Lebanon',
    name: '黎巴嫩',
    code: '+961',
  },
  {
    english: 'Lesotho',
    name: '莱索托',
    code: '+266',
  },
  {
    english: 'Liberia',
    name: '利比里亚',
    code: '+231',
  },
  {
    english: 'Libya',
    name: '利比亚',
    code: '+218',
  },
  {
    english: 'Liechtenstein',
    name: '列支敦士登',
    code: '+423',
  },
  {
    english: 'Lithuania',
    name: '立陶宛',
    code: '+370',
  },
  {
    english: 'Luxembourg',
    name: '卢森堡',
    code: '+352',
  },
  {
    english: 'North Macedonia',
    name: '北马其顿',
    code: '+389',
  },
  {
    english: 'Madagascar',
    name: '马达加斯加',
    code: '+261',
  },
  {
    english: 'Malawi',
    name: '马拉维',
    code: '+265',
  },
  {
    english: 'Malaysia',
    name: '马来西亚',
    code: '+60',
  },
  {
    english: 'Maldives',
    name: '马尔代夫',
    code: '+960',
  },
  {
    english: 'Mali',
    name: '马里',
    code: '+223',
  },
  {
    english: 'Malta',
    name: '马耳他',
    code: '+356',
  },
  {
    english: 'Martinique',
    name: '马提尼克',
    code: '+596',
  },
  {
    english: 'Mauritania',
    name: '毛里塔尼亚',
    code: '+222',
  },
  {
    english: 'Mauritius',
    name: '毛里求斯',
    code: '+230',
  },
  {
    english: 'Mayotte',
    name: '马约特',
    code: '+262',
  },
  {
    english: 'Mexico',
    name: '墨西哥',
    code: '+52',
  },
  {
    english: 'Moldova',
    name: '摩尔多瓦',
    code: '+373',
  },
  {
    english: 'Monaco',
    name: '摩纳哥',
    code: '+377',
  },
  {
    english: 'Mongolia',
    name: '蒙古',
    code: '+976',
  },
  {
    english: 'Montenegro',
    name: '黑山',
    code: '+382',
  },
  {
    english: 'Montserrat',
    name: '蒙特塞拉特岛',
    code: '+1664',
  },
  {
    english: 'Morocco',
    name: '摩洛哥',
    code: '+212',
  },
  {
    english: 'Mozambique',
    name: '莫桑比克',
    code: '+258',
  },
  {
    english: 'Myanmar',
    name: '缅甸',
    code: '+95',
  },
  {
    english: 'Namibia',
    name: '纳米比亚',
    code: '+264',
  },
  {
    english: 'Nepal',
    name: '尼泊尔',
    code: '+977',
  },
  {
    english: 'Netherlands',
    name: '荷兰',
    code: '+31',
  },
  {
    english: 'New Caledonia',
    name: '新喀里多尼亚',
    code: '+687',
  },
  {
    english: 'New Zealand',
    name: '新西兰',
    code: '+64',
  },
  {
    english: 'Nicaragua',
    name: '尼加拉瓜',
    code: '+505',
  },
  {
    english: 'Niger',
    name: '尼日尔',
    code: '+227',
  },
  {
    english: 'Nigeria',
    name: '尼日利亚',
    code: '+234',
  },
  {
    english: 'Norway',
    name: '挪威',
    code: '+47',
  },
  {
    english: 'Oman',
    name: '阿曼',
    code: '+968',
  },
  {
    english: 'Pakistan',
    name: '巴基斯坦',
    code: '+92',
  },
  {
    english: 'Palau',
    name: '帕劳',
    code: '+680',
  },
  {
    english: 'Palestine',
    name: '巴勒斯坦',
    code: '+970',
  },
  {
    english: 'Panama',
    name: '巴拿马',
    code: '+507',
  },
  {
    english: 'Papua New Guinea',
    name: '巴布亚新几内亚',
    code: '+675',
  },
  {
    english: 'Paraguay',
    name: '巴拉圭',
    code: '+595',
  },
  {
    english: 'Peru',
    name: '秘鲁',
    code: '+51',
  },
  {
    english: 'Philippines',
    name: '菲律宾',
    code: '+63',
  },
  {
    english: 'Poland',
    name: '波兰',
    code: '+48',
  },
  {
    english: 'Portugal',
    name: '葡萄牙',
    code: '+351',
  },
  {
    english: 'Puerto Rico',
    name: '波多黎各',
    code: '+1787',
  },
  {
    english: 'Qatar',
    name: '卡塔尔',
    code: '+974',
  },
  {
    english: 'Republic Of The Congo',
    name: '刚果共和国',
    code: '+242',
  },
  {
    english: 'Réunion Island',
    name: '留尼汪',
    code: '+262',
  },
  {
    english: 'Romania',
    name: '罗马尼亚',
    code: '+40',
  },
  {
    english: 'Rwanda',
    name: '卢旺达',
    code: '+250',
  },
  {
    english: 'Saint Kitts and Nevis',
    name: '圣基茨和尼维斯',
    code: '+1869',
  },
  {
    english: 'Saint Lucia',
    name: '圣露西亚',
    code: '+1758',
  },
  {
    english: 'Saint Pierre and Miquelon',
    name: '圣彼埃尔和密克隆岛',
    code: '+508',
  },
  {
    english: 'Saint Vincent and The Grenadines',
    name: '圣文森特和格林纳丁斯',
    code: '+1784',
  },
  {
    english: 'Samoa',
    name: '萨摩亚',
    code: '+685',
  },
  {
    english: 'San Marino',
    name: '圣马力诺',
    code: '+378',
  },
  {
    english: 'Sao Tome and Principe',
    name: '圣多美和普林西比',
    code: '+239',
  },
  {
    english: 'Saudi Arabia',
    name: '沙特阿拉伯',
    code: '+966',
  },
  {
    english: 'Senegal',
    name: '塞内加尔',
    code: '+221',
  },
  {
    english: 'Serbia',
    name: '塞尔维亚',
    code: '+381',
  },
  {
    english: 'Seychelles',
    name: '塞舌尔',
    code: '+248',
  },
  {
    english: 'Sierra Leone',
    name: '塞拉利昂',
    code: '+232',
  },
  {
    english: 'Singapore',
    name: '新加坡',
    code: '+65',
  },
  {
    english: 'Sint Maarten (Dutch Part)',
    name: '圣马丁岛（荷兰部分）',
    code: '+1721',
  },
  {
    english: 'Slovakia',
    name: '斯洛伐克',
    code: '+421',
  },
  {
    english: 'Slovenia',
    name: '斯洛文尼亚',
    code: '+386',
  },
  {
    english: 'Solomon Islands',
    name: '所罗门群岛',
    code: '+677',
  },
  {
    english: 'Somalia',
    name: '索马里',
    code: '+252',
  },
  {
    english: 'South Africa',
    name: '南非',
    code: '+27',
  },
  {
    english: 'South Korea',
    name: '韩国',
    code: '+82',
  },
  {
    english: 'Spain',
    name: '西班牙',
    code: '+34',
  },
  {
    english: 'Sri Lanka',
    name: '斯里兰卡',
    code: '+94',
  },
  {
    english: 'Suriname',
    name: '苏里南',
    code: '+597',
  },
  {
    english: 'Sweden',
    name: '瑞典',
    code: '+46',
  },
  {
    english: 'Switzerland',
    name: '瑞士',
    code: '+41',
  },
  {
    english: 'Tajikistan',
    name: '塔吉克斯坦',
    code: '+992',
  },
  {
    english: 'Tanzania',
    name: '坦桑尼亚',
    code: '+255',
  },
  {
    english: 'Thailand',
    name: '泰国',
    code: '+66',
  },
  {
    english: 'Timor-Leste',
    name: '东帝汶',
    code: '+670',
  },
  {
    english: 'Togo',
    name: '多哥',
    code: '+228',
  },
  {
    english: 'Tonga',
    name: '汤加',
    code: '+676',
  },
  {
    english: 'Trinidad and Tobago',
    name: '特立尼达和多巴哥',
    code: '+1868',
  },
  {
    english: 'Tunisia',
    name: '突尼斯',
    code: '+216',
  },
  {
    english: 'Turkey',
    name: '土耳其',
    code: '+90',
  },
  {
    english: 'Turkmenistan',
    name: '土库曼斯坦',
    code: '+993',
  },
  {
    english: 'Turks and Caicos Islands',
    name: '特克斯和凯科斯群岛',
    code: '+1649',
  },
  {
    english: 'Uganda',
    name: '乌干达',
    code: '+256',
  },
  {
    english: 'Ukraine',
    name: '乌克兰',
    code: '+380',
  },
  {
    english: 'United Arab Emirates',
    name: '阿拉伯联合酋长国',
    code: '+971',
  },
  {
    english: 'United Kingdom',
    name: '英国',
    code: '+44',
  },
  {
    english: 'United States',
    name: '美国',
    code: '+1',
  },
  {
    english: 'Uruguay',
    name: '乌拉圭',
    code: '+598',
  },
  {
    english: 'Uzbekistan',
    name: '乌兹别克斯坦',
    code: '+998',
  },
  {
    english: 'Vanuatu',
    name: '瓦努阿图',
    code: '+678',
  },
  {
    english: 'Venezuela',
    name: '委内瑞拉',
    code: '+58',
  },
  {
    english: 'Vietnam',
    name: '越南',
    code: '+84',
  },
  {
    english: 'Virgin Islands, British',
    name: '英属维尔京群岛',
    code: '+1284',
  },
  {
    english: 'Virgin Islands, US',
    name: '美属维尔京群岛',
    code: '+1340',
  },
  {
    english: 'Yemen',
    name: '也门',
    code: '+967',
  },
  {
    english: 'Zambia',
    name: '赞比亚',
    code: '+260',
  },
  {
    english: 'Zimbabwe',
    name: '津巴布韦',
    code: '+263',
  },
].map(i => ({
  ...i,
  fullLabel: `${i.code} ${i.name} (${i.english})`,
  text: `${i.code} ${i.name} (${i.english})`,
  value: i.code,
  label: i.code,
}));
export const countryCodeList = tmp;
