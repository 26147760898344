import { keyBy } from 'lodash-es';

export const OPERATOR_LIST = [
  { operator: 'EQ', label: '等于', type: 'NUMBER' },
  { operator: 'NE', label: '不等于', type: 'NUMBER' },
  { operator: 'GT', label: '大于', type: 'NUMBER' },
  { operator: 'GE', label: '大于等于', type: 'NUMBER' },
  { operator: 'LT', label: '小于', type: 'NUMBER' },
  { operator: 'LE', label: '小于等于', type: 'NUMBER' },
  { operator: 'BETWEEN', label: '在区间', type: 'NUMBER' },

  { operator: 'IN', label: '是', type: 'STRING' },
  { operator: 'NOT_IN', label: '不是', type: 'STRING' },
  { operator: 'LIKE', label: '匹配', type: 'STRING' },
  { operator: 'NOT_LIKE', label: '不匹配', type: 'STRING' },

  { operator: 'FT', label: '绝对时间', type: 'DATETIME' },
  { operator: 'RT', label: '相对时间', type: 'DATETIME' },
];

export const OPERATOR_MAP = keyBy(OPERATOR_LIST, 'operator');

export function getRulePairText(ruleConf: {
  value?: string;
  physicalName: string;
  relationalOperator?: string;
}) {
  const { value, physicalName, relationalOperator } = ruleConf;
  const opName = relationalOperator && OPERATOR_MAP[relationalOperator]?.label;
  if (opName && value) {
    return `”${physicalName}“${opName}”${value}“`;
  }
  return `”${physicalName}“`;
}
