import Axios from 'axios';
import { ElMessage } from 'element-plus';
import { RowColType, RowConfig, ColConfig } from '../components/DeclareDrawer/types';
import { RowColAuthDetail, RowColAuthData, RuleApplyInfo } from './types';

const axios = Axios.create({
  baseURL: '/api',
  withCredentials: true, // 跨域请求时发送cookie
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 2000000, // 毫秒
});

axios.interceptors.response.use(async response => {
  if (response.status !== 200) {
    ElMessage.error('服务异常，请稍后重试');
    return Promise.reject(response);
  }
  if (response.data.code) {
    ElMessage.error(response.data.msg);
    return Promise.reject(response);
  }
  return response.data;
});

export const getRowRuleList = (params: {
  bizId: string;
}): Promise<{
  list: RowColAuthDetail[];
  total: number;
}> =>
  axios('/data/manager/v2/rowandcol/config/global/row/page', {
    baseURL: '/api',
    method: 'get',
    params: { ...params, withOwnAuth: true },
    responseType: 'json',
  }).then(({ data: { list, total } }) => ({
    list: list.map((rule: RowColAuthData) => ({
      ...rule,
      ruleConfig: JSON.parse(rule.ruleConfig),
    })),
    total,
  }));

export const getColRuleList = (params: {
  bizId: string;
}): Promise<{
  list: RowColAuthDetail[];
  total: number;
}> =>
  axios('/data/manager/v2/rowandcol/config/global/col/page', {
    baseURL: '/api',
    method: 'get',
    params: { ...params, withOwnAuth: true },
    responseType: 'json',
  }).then(({ data: { list, total } }) => ({
    list: list.map((rule: RowColAuthData) => ({
      ...rule,
      ruleConfig: JSON.parse(rule.ruleConfig),
    })),
    total,
  }));

export const deleteRowColRule = (
  data: { id: number; rowColType: RowColType },
  params: { bizId: string }
): Promise<any> =>
  axios('/data/manager/v2/rowandcol/config/delete', {
    baseURL: '/api',
    method: 'post',
    responseType: 'json',
    params,
    data,
  }).then(({ data }) => data);

export const getGlobalRows = (params: { bizId: string }): Promise<any> =>
  axios('/data/manager/v2/global/sensitive/rows', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

export const getGlobalCols = (params: { bizId: string }): Promise<any> =>
  axios('/data/manager/v2/global/sensitive/cols', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

export const saveGlobalRows = (
  globalSensitiveRows: RowConfig[],
  params: { bizId: string }
): Promise<any> =>
  axios('/data/manager/v2/global/sensitive/rows/save', {
    baseURL: '/api',
    method: 'post',
    responseType: 'json',
    params,
    data: { globalSensitiveRows },
  });

export const saveGlobalCols = (
  globalSensitiveCols: ColConfig[],
  params: { bizId: string }
): Promise<any> =>
  axios('/data/manager/v2/global/sensitive/cols/save', {
    baseURL: '/api',
    method: 'post',
    responseType: 'json',
    params,
    data: { globalSensitiveCols },
  });

export const getPermissionModule = (params: { bizId: string }): Promise<string[]> =>
  axios('/data/manager/v2/third/dataauth/listPermissionModule', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

export const applyBatchRule = (
  batchApplyDataList: RuleApplyInfo[],
  params: { bizId: string }
): Promise<any> =>
  axios('/data/manager/v2/third/dataauth/asset/apply/batch', {
    baseURL: '/api',
    method: 'post',
    params,
    responseType: 'json',
    data: { batchApplyDataList },
  });

export const getDataResourceTree = (params: { bizId: string }): Promise<any> =>
  axios('/data/manager/v2/resource/catalog/treeWithResource', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

export const saveRowColRuleWhiteList = (
  resourceIds: number[],
  params: { bizId: string }
): Promise<any> =>
  axios('/data/manager/v2/rowandcol/white/add', {
    baseURL: '/api',
    method: 'post',
    responseType: 'json',
    params,
    data: { resourceIds },
  });

export const getRowColRuleWhiteList = (params: {
  bizId: string;
}): Promise<{ resourceId: number }[]> =>
  axios('/data/manager/v2/rowandcol/white/list', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

// 是否是空间管理员
export const checkBizAdmin = (params: { bizId: string }): Promise<boolean> =>
  axios('/data/manager/v2/third/dataauth/check/admin', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

// 数据管家详情页是否可查看
export const checkDetailViewStatus = (params: { bizId: string }): Promise<boolean> =>
  axios('/data/manager/v2/biz/info/config/resource/detail/check', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);

// 更新数据管家详情页是查看状态
export const updateDetailViewStatus = (params: {
  viewStatus: boolean;
  bizId: string;
}): Promise<boolean> =>
  axios('/data/manager/v2/biz/info/config/detail/check/update', {
    baseURL: '/api',
    method: 'get',
    params,
    responseType: 'json',
  }).then(({ data }) => data);
