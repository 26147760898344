/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-10-28 13:33:53
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-11-17 10:54:50
 * @Description: 统计站点
 *
 */
enum SiteType {
  domestic = 'domestic',
  international = 'international',
}

export const getCurSiteType = (): SiteType => SiteType.domestic;

export const getSiteReportParams = (): { site_type: SiteType } => ({
  site_type: getCurSiteType(),
});
