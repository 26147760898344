import AuthService from '@/api/auth';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import DtMessage from '@/components/DtMessage';

interface HistoryObj {
  approvalStep: number;
  approverUserName: string;
  approver: string;
  approvalResult: string;
  modifyInfo: string;
  modifiedActionDesc: string;
  modifiedRoleName: string;
  approvalTime: string;
}

interface ResultObj {
  realAuthType?: string;
}

interface ApproveRow {
  id: string;
  action: string;
  modifyId: string;
  roleId: string;
}

interface EditRow {
  action: string;
  appKey: string;
  categoryKey: string;
  modifyList: { [key: string]: string }[];
  state: number;
  bizId: string;
}

interface ApplyParams {
  applyId: string;
  approvalResult: string;
  modifiedAction?: string;
  modifiedRoleId?: string;
}

export const getApprovalHistoryDesc = (historyArr: HistoryObj[], approvalStep: number) => {
  const resultObj: ResultObj = {};
  let result = '';
  const level = ['直属上', '一', '二'];
  const dict = {
    PASS: '通过',
    REJECT: '拒绝',
  };
  if (!Array.isArray(historyArr) || historyArr.length === 0) {
    return { approvalHistoryDesc: `等待${level[approvalStep]}级审批` };
  }
  historyArr.forEach(item => {
    result += `${level[item.approvalStep]}级审批人${(item.approverUserName || item.approver) +
      dict[item.approvalResult as keyof typeof dict]};`;
    if (item.modifyInfo) {
      result += `${item.modifyInfo};`;
      resultObj.realAuthType = item.modifiedActionDesc || item.modifiedRoleName;
    }
  });
  if (historyArr.length < approvalStep && historyArr[0].approvalResult === 'PASS') {
    result += '等待二级审批';
  }
  return {
    approvalHistoryDesc: result,
    firstApprovalTime: historyArr[0]?.approvalTime,
    secondApprovalTime: historyArr[1]?.approvalTime || '无',
    ...resultObj,
  };
};

export const doApprove = async (row: ApproveRow, approvalResult: string, callback: Function) => {
  const params: ApplyParams = { applyId: row.id, approvalResult };
  if (approvalResult === 'PASS') {
    if (row.action && row.modifyId !== row.action) {
      // 修改权限类型
      params.modifiedAction = row.modifyId;
    } else if (row.roleId && row.modifyId !== row.roleId) {
      // 修改空间角色组
      params.modifiedRoleId = row.modifyId;
    }
  }
  const data = await AuthService.applyApprove(params);
  if (data.data?.code === 1) {
    DtMessage({
      type: 'success',
      message: '操作成功',
    });
  }
  callback();
};

export const editAuthApprove = async (row: EditRow) => {
  if (row.action) {
    const res = await AuthService.queryAuthListConfig({
      appKey: row.appKey,
      categoryKey: row.categoryKey,
    });
    if (res.data?.code === 1) {
      row.modifyList = res.data.result.actionList.map(
        ({ desc, key }: { desc: string; key: string }) => ({
          label: desc,
          value: key,
        })
      );
      row.state = 2;
    }
  } else {
    row.modifyList = [];
    const data = await AuthService.getRoleList({
      bizId: row.bizId,
      appKey: row.appKey,
    });
    if (data.data?.code === 1) {
      const { result } = data.data;
      (result || []).forEach(({ name, id }: { name: string; id: string }) => {
        row.modifyList.push({ label: name, value: id });
      });
      row.state = 2;
    }
  }
};

/**
 * 获取权限类型列表
 */
export const getAuthListConfig = async ({
  appKey,
  categoryKey,
}: {
  appKey: string;
  categoryKey: string;
}): Promise<string[]> => {
  const res = await AuthService.queryAuthListConfig({ appKey, categoryKey });
  if (res.data?.code === 1) return res.data.result.actionList;
  DtMessage({
    type: 'warning',
    message: res.data?.msg,
  });
  return [];
};
