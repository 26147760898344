import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-98c2a938"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "layout"
};
var _hoisted_2 = {
  key: 1,
  class: "educatioin-layout"
};
var _hoisted_3 = {
  class: "app-content"
};
var _hoisted_4 = {
  key: 0,
  class: "app-education"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_app_nav = _resolveComponent("app-nav");

  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.routeInfo.nav && (!_ctx.isInMicroApp || _ctx.isMobileDevice) ? (_openBlock(), _createBlock(_component_app_nav, {
    key: 0,
    showCompany: _ctx.routeInfo.company,
    showAvatar: _ctx.routeInfo.showAvatar,
    showBizlist: _ctx.routeInfo.bizlist,
    showCorpList: _ctx.routeInfo.showCorpList
  }, null, 8, ["showCompany", "showAvatar", "showBizlist", "showCorpList"])) : _createCommentVNode("", true), _ctx.routeInfo.education ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view)]), !_ctx.isMobileDevice ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true)])) : (_openBlock(), _createBlock(_component_router_view, {
    key: 2
  }))]);
}