/*
 * @Author: beenlezhang
 * @Date: 2021-02-22 10:01:12
 * @LastEditors: yanhongchen
 * @LastEditTime: 2023-03-20 15:18:46
 * @Descripttion: 所有跟用户相关的接口
 */
import { HttpResponse } from '@/@types';
import {
  MemberListParams,
  CheckExistParam,
  CreateCompanyParams,
  CreateCompanyData,
  BizCountParam,
  CorporationParams,
  AddMemberParams,
  FetchSpaceListParams,
  CreateSpaceParams,
  FetchCorporationParams,
  QueryActiveCodeParams,
  AppDetailParams,
} from '@/@types/company';
import Axios from './axios';

class CompanyService {
  // 判断企业是否已存在
  public static async checkCompanyExist(params: CheckExistParam): Promise<HttpResponse> {
    return Axios('/server/tob/organization/exist', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 是否是企业管理员
  public static async checkCompanyAdmin(params: CorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/admin/', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取用户的企业信息以及企业状态
  public static async getCompanyInfo(params: CorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取企业下空间数
  public static async getComapnayBizCount(params: BizCountParam): Promise<HttpResponse> {
    return Axios('/server/tob/organization/biz/count', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取企业下的用户数
  public static async getComapnayMemberCount(): Promise<HttpResponse> {
    return Axios('/server/tob/organization/user/count', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取企业下的成员列表
  public static async getCompanyMemberList(
    params: CorporationParams,
    data: MemberListParams
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/list/member', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 获取邀请详情
  public static async getInviteMemberList(
    params: CorporationParams,
    data: MemberListParams
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/list/invitation/', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 获取企业下的成员列表
  public static async getSpaceList(
    params: CorporationParams,
    data: MemberListParams
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/list/biz/', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // tob登录创建企业接口
  public static async createCompany(
    params: CreateCompanyParams,
    data: CreateCompanyData
  ): Promise<HttpResponse> {
    return Axios('/server/tob/create/organization', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 手动添加企业成员
  public static async addCompanyMember(
    params: CorporationParams,
    data: AddMemberParams
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/add/manul/', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 上传并添加
  public static async uploadCompanyMember(
    params: CorporationParams,
    data: any
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/add/batch/', {
      method: 'post',
      responseType: 'json',
      headers: { 'content-type': 'multipart/form-data' },
      params,
      data,
    });
  }

  // 获取用户集团列表
  public static async getCompanyList(): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/list', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取企业申请列表（未审核和审核被拒的企业）
  public static async getNopassCompanyList(): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/list/not/pass', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取还没审核或审核没通过的企业申请详情
  public static async getCompanyDetail(params: CorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/apply', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 申请新建集团
  public static async applyCompany(data: CreateCompanyData): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/apply', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 更新-还没审核或审核没通过的企业申请信息
  public static async updateNopassCompany(
    params: CorporationParams,
    data: CreateCompanyData
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/update/apply', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 获取应用列表
  public static async getPlatformList(): Promise<HttpResponse> {
    return Axios('/authserver/web/platform/app', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取行业信息列表
  public static async getIndustryList(): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/industries', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 上传集团logo
  public static async updateCompanyLogo(
    params: CorporationParams,
    data: any
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/logo/upload/', {
      method: 'post',
      responseType: 'json',
      headers: { 'content-type': 'multipart/form-data' },
      params,
      data,
    });
  }

  // 更新企业基本信息，若只是修改其中某个字段，body里只需带上那个字段即可，更新多个字段的话就传多个字段
  public static async updateCompanyInfo(
    params: CorporationParams,
    data: CreateCompanyData
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/update/', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 查看集团信息
  public static async deleteMember(params: CorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/remove/member/', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 搜索企业成员
  public static async searchCompanyMember(params: CorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/search/', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 查看企业管理员列表
  public static async getManagerList(params: CorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/list/admin/', {
      method: 'post',
      responseType: 'json',
      params,
    });
  }

  // 查看企业管理员列表
  public static async updateManagerList(
    params: CorporationParams,
    data: CreateCompanyData
  ): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/update/admin/', {
      method: 'post',
      responseType: 'json',
      params,
      data,
    });
  }

  // 我的空间列表
  public static async getMySpaceList(params: FetchSpaceListParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/list/with/admins', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 新建空间
  public static async createSpace(data: CreateSpaceParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/create', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 获取企业信息
  public static async getCorporationInfo(params: FetchCorporationParams): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/findByBizId', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取开通产品详情
  public static async getAppActiveDetail(params: AppDetailParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/getApplicationDetailByCorporationId', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 激活码信息查询
  public static async queryActiveCode(data: QueryActiveCodeParams): Promise<HttpResponse> {
    return Axios('/authserver/web/billing/queryActiveCode', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 查询管理员角色的企业信息
  public static async queryManagerCroporation(): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/listByAdmin', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 记录用户进入的租户ID
  public static async recordEnterCorporation(params: AppDetailParams): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/enterCorporation', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  public static async getEnteredLastCorporation(): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/lastCorporation', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 查询企业业务信息
  public static async queryBusinessList(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/busi/list', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 更新企业业务信息
  public static async updateBusinessInfo(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/busi/update', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 创建业务
  public static async createBusinessInfo(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/busi/create', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 开启业务模块
  public static async enabledBusi(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/enabledBusi', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 获取指定节点下的子节点
  public static async getChildNodeList(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/sre/getChildNodeList', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 更新空间信息
  public static async updateBusi(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/updateBiz', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 对未归属业务空间关联业务 & 更新空间信息
  public static async bizAssociateBusi(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/bizAssociateBusi', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 更新空间默认权限信息
  public static async updateBizCategoryPermission(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/updateBizCategoryPermission', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 查询空间所属企业开通的应用列表
  public static async queryOpenedApps(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/get/corporation/apps', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取指定空间已开通和未开通的应用列表(主线)
  public static async queryAllApps(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/get/apps', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取必须的基础appKey
  public static async getRequiredAppKeys(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/corporation/getRequiredAppKeys', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 给指定空间开通应用
  public static async openApps(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/open/appsForMaster', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 获取业务管理员列表
  public static async getBusiAdminList(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/busi/listByBusiIds', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 查询给定应用空间高级功能开启情况
  public static async getAppKeyFunctions(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/getAppKeyFunctions', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 空间高级功能配置
  public static async setBizFunction(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/bizFunctionConfig', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 校验是否有修改关联业务和高级功能权限
  public static async checkUpdatePermission(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/biz/checkUpdatePermission', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 获取成本负责人
  public static async getObsManager(params: any): Promise<HttpResponse> {
    return Axios('/authserver/web/sre/getObsManager', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 按照 bisi codes 查询 业务成员列表
  public static async getBusiMember(data: any): Promise<HttpResponse> {
    return Axios('/authserver/web/busi/listByBusiCodes', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }
}

export default CompanyService;
