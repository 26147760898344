/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-04 15:08:46
 * @Descripttion: action
 */
import UserService from '@/api/user';
import AuthService from '@/api/auth';
import { setStoreState } from '@/store/utils';
import { LoginParams } from '@/@types/login';
/**
 * @description 所有跟用户相关的内容
 * @return status 返回状态  err_code：1，逻辑正确，err_code：0，发生错误。
 */

const userActions = {
  // 获取用户信息
  getUserDetail(store: any) {
    return UserService.getUserInfo().then(res => {
      if (res.data?.code === 1) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { userDetail } = store.state;
        const data = res.data?.result;
        setStoreState('user', 'userDetail', {
          ...userDetail,
          id: data.user_id,
          name: data.user_name,
          email: data.email,
          phone: data.phone,
          areaCode: data.areaCode,
          login_name: data.user_name,
          avatar: data.avatar,
        });
      }
    });
  },
  // 获取微信等其它登录方式的信息
  getOtherLoginInfo(store: any) {
    return UserService.getOtherLoginInfo().then(res => {
      if (res.data?.code === 1) {
        const wechatData = res.data?.result.filter((item: any) => item.account_type === 'WX');
        const weworkData = res.data?.result.filter((item: any) => item.account_type === 'QYWX');
        const { userDetail } = store.state;
        setStoreState('user', 'userDetail', {
          ...userDetail,
          wechat: {
            isBind: !!wechatData.length,
            name: wechatData.length ? wechatData[0]?.account_name : '',
          },
          wework: {
            isBind: !!weworkData.length,
            name: weworkData.length ? weworkData[0]?.account_name : '',
          },
        });
      }
    });
  },
  // 登录
  async login({}, params: LoginParams) {
    return await UserService.login(params);
  },
  async checkBizAdmin(store: any, params: any) {
    const data = await AuthService.checkBizAdmin(params);
    const isBizAdmin = data.data?.code === 1 && data.data?.result;
    const { userDetail } = store.state;
    setStoreState('user', 'userDetail', {
      ...userDetail,
      isAdmin: isBizAdmin,
    });
    return data;
  },
  async setUserId(store: any, params: any) {
    const { userDetail } = store.state;
    setStoreState('user', 'userDetail', {
      ...userDetail,
      id: params.userId,
    });
  },
};

type UserActionsType = typeof userActions;

export { UserActionsType };
export default userActions;
