/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: beenlezhang
 * @LastEditTime: 2021-03-11 16:11:38
 * @Descripttion:
 */
export default {
  __set(state: any, msg: { key: string; val: any }) {
    state[msg.key] = msg.val;
  },
  setApprovalTab(state: any, tab: string) {
    state.approvalTab = tab;
  },
};
