/*
 * @Author: henghaowu
 * @Date: 2022-07-28
 * @LastEditors: henghaowu
 * @LastEditTime: 2022-08-04
 * @Description:
 */
import { Ref, ref } from 'vue';
import {
  AdvancedSettingModalInstance,
  SettingInfo,
} from '../../Components/advancedSettingModal/types';
import { useRoute } from 'vue-router';
import CompanyService from '@/api/company';

/**
 * 根据 appKey 获取高级设置
 */
export const useAdvancedSetting = function useSetting(
  AdvancedSettingModalInstance: Ref<AdvancedSettingModalInstance | undefined>
): {
  onOpenSettingDiolog: (appKey: string, disabled: boolean) => void;
  settingList: Ref<SettingInfo[]>;
} {
  const route = useRoute();

  const settingList = ref<SettingInfo[]>([]);
  /**
   * 打开高级设置弹窗
   */
  const onOpenSettingDiolog = async (appKey: string, disabled: boolean) => {
    if (disabled) return;
    const result = await CompanyService.getAppKeyFunctions({
      appGroup: 'beacon',
      bizId: route.params.biz_url,
      appKey,
    });
    settingList.value = result.data.result;
    AdvancedSettingModalInstance.value?.open();
  };

  return {
    onOpenSettingDiolog,
    settingList,
  };
};
