import $router from '@/router';
import { gotoPage } from '@/views/one-login/composables/use-fake-router';
import { buildUrl } from '@/views/one-login/apis/request';
import { JoinCorpState } from '@/views/one-login/types';
import { getInviteInfo } from '@/views/one-login/apis/invite';
import { getUserInfo } from '@/views/one-login/apis/login';

/**
 * 租户邀请相关函数
 */
export const useCorpInvite = (): {
  judgeJumpInvitePage: () => boolean;
  goInviteRegistResultPage: (inviteCode: string, status: JoinCorpState) => Promise<void>;
} => {
  /**
   * 判断是否要跳转租户邀请页
   */
  const judgeJumpInvitePage = (): boolean => {
    const route = $router.currentRoute.value;
    // 链接邀请方式
    if (route.query.inviteCode) {
      gotoPage.inviteLink({ inviteCode: route.query.inviteCode as string });
      // 手机/邮箱邀请方式
    } else if (route.query.corpInvitationCode) {
      location.href = `${buildUrl(
        '/api/dataauth/authserver/web/corporation/joinInviteCorp'
      )}?inviteCode=${route.query.corpInvitationCode}`;
    }
    return !!route.query.inviteCode || !!route.query.corpInvitationCode;
  };

  const jumpWithUserInfo = async (
    userName: string,
    inviteCode: string,
    dftState: JoinCorpState = JoinCorpState.NotJoinCorp
  ): Promise<void> => {
    const { corpAdmins = [], corporationName = '' } = await getInviteInfo({
      inviteCode,
    });
    // 获取成功，跳转确认页面
    gotoPage.inviteResult({
      inviteState: dftState,
      corpName: corporationName,
      corpManager: corpAdmins.join(','),
      beInvitedMember: userName,
    });
  };
  /**
   * 前往登录成功页
   * @param inviteCode
   */
  const goInviteRegistResultPage = async (
    inviteCode: string,
    status: JoinCorpState
  ): Promise<void> => {
    let userName = '';
    try {
      // 投后等其他版本在统一登录页面，会有cookie隔离问题。
      // 此时username拿不到，默认为空即可，不影响跳转
      const res = await getUserInfo();
      userName = res.user_name;
    } finally {
      jumpWithUserInfo(userName, inviteCode, status);
    }
  };
  return {
    judgeJumpInvitePage,
    goInviteRegistResultPage,
  };
};
