export enum DataAssetType {
  RESOURCE = 'RESOURCE',
  DATASOURCE = 'DATASOURCE',
  SPACE_AUTHENTICATION = 'SPACE_AUTHENTICATION',
}

export interface DataAuthInfo {
  bizId: string;
  category: DataAssetType;
  dataId: string;
  dataName: string;
}

export enum DataAuthAction {
  EXE = 'EXE',
  READ = 'READ',
  DOWNLOAD = 'DOWNLOAD',
  WRITE = 'WRITE',
  MODIFY = 'MODIFY',
  GRANT = 'GRANT',
}

export interface AuthContent {
  operator: string;
  operatorName: string;
  actions: DataAuthAction[];
  operatorType: 'USER' | 'ROLE' | 'ORG';
  status: 'IN_BIZ' | 'NOT_IN_BIZ';
}

export interface DataAuthPanelInfo {
  appKey: 'data_manager';
  bizId: string;
  category: DataAssetType;
  dataId: string;
  dataName: null;
  authContentList: AuthContent[];
}

export interface DataAuthApprover {
  approvalStep: 1 | 2;
  avatar: null | string;
  loginName: string;
  memberType: 'USER';
  name: string;
}

export interface BizRoleMember {
  avatar: string;
  effectiveTime: string;
  expiryTime: string;
  loginName: string;
  memberType: 'USER';
  name: string;
}

export interface BizMember {
  avatar: null | string;
  loginName: string;
  memberType: 'USER';
  name: string;
}

export interface BizRoleWithMember {
  id: string;
  name: string; // 空间管理员
  remark: null | string;
  roleType: 'BIZ_ADMIN_ROLE';
  members: BizRoleMember[];
}

export interface AuthUserList {
  avatar: string;
  user_id: string;
  user_name: string;
}

export type AppKeyType =
  | 'dataauth'
  | 'console'
  | 'datareporting'
  | 'venus'
  | 'wuji'
  | 'datatalk'
  | 'datainsight'
  | 'dataaudience'
  | 'datalink'
  | 'tab'
  | 'datapy'
  | 'mixquery'
  | 'datahub'
  | 'metacat'
  | 'datamanager'
  | 'push'
  | 'monitor'
  | 'resourcemanagement'
  | 'template'
  | 'mobile-control-app'
  | 'xingpan'
  | 'tx';

// 后端 AppId
export type BEAppKeyType =
  | 'beacon_olap'
  | 'data_talk'
  | 'data_link'
  | 'data_py'
  | 'datahub3_team'
  | 'tab'
  | 'mix_query'
  | 'console'
  | 'cdp'
  | 'data_manager'
  | 'data_auth'
  | 'metacat'
  | 'monitor'
  | 'push'
  | 'resource_management'
  | 'venus'
  | 'wuji'
  | 'template'
  | 'data_reporting'
  | 'xingpan'
  | 'tx';

// 获取空间角色信息参数
export interface GetSpaceRole {
  bizId: string | number;
  appKey: BEAppKeyType;
}

// 单个空间角色组信息
export interface SpaceRole {
  extra?: {
    canApply: 0 | 1; // 0 false 1 true
    templateId: number;
  };
  remark: string; // 空间信息备注
  roleId: string; // 角色组id
  roleName: string; // 角色组名
  roleType: string; // 角色组类型
  userInRole: boolean; // 当前用户是否属于当前角色组
}

// 权限申请的推送渠道
export type PushChannel = 'API' | 'EMAIL' | 'MYOA' | 'QYWX';

// 申请空间组角色参数
export interface ApplySpaceRole {
  apiConfigReq?: {
    apiApprovalKey?: string;
    applyPushApi?: string;
    applyPushSignKey?: string;
  };
  appKey: BEAppKeyType; // 后端子应用名
  bizId: string; // 空间id
  extra?: {
    canApply?: 0 | 1;
    templateId?: number;
  };
  location: string; // 当前 url 信息
  pushChannels?: PushChannel[];
  roleId: string; // 申请的角色组id
  roleName: string; // 角色组名
}

// 审批人信息
interface Approver {
  loginName: string;
  memberType?: string;
  name: string;
}

// 审批人等级信息
interface ApproverStep {
  approverList: Approver[];
  approvalStep: number; // 第几级申请
  approvalStepName: string; // 申请
}

// 空间角色组申请返回体
export interface ApplySpaceRoleResult {
  applyId: number; // 空间权限申请id
  approveLink: string; // 空间权限申请链接
  approvalStepInfos: ApproverStep[]; // 审批人等级信息
  extra: {
    authProvider: string;
    approverList: string[];
  } | null;
}

//  根据 appKey 及 bizid 获取空间信息
export type GeneralSpaceInfo<T = any> = {
  [P in keyof T]: T;
};
