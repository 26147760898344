/*
 * @Author: xqbzheng
 * @Description: 租户邀请相关接口
 */
import request, { buildUrl } from '@/views/one-login/apis/request';
import { MemberItem } from '@/@types/company';
import { InviteTimeGroup, InviteType, JoinCorpState, MemberStatus } from '@/views/one-login/types';

interface GetInviteInfoByInviteCodeReq {
  inviteCode: string;
}

interface GetInviteInfoByInviteCodeRes {
  corpAdmins: string[];
  corporationName: string;
  inviteState: JoinCorpState;
}
/**
 * 根据InviteCode查询邀请信息
 * @param req
 */
export const getInviteInfo = (
  req: GetInviteInfoByInviteCodeReq
): Promise<GetInviteInfoByInviteCodeRes> => {
  return request
    .get(buildUrl('/api/dataauth/authserver/corporation/getCorpInfoByInviteCode'), req, {}, true)
    .then(res => res?.result);
};

interface ManualAddCorpMemberReq {
  accounts: {
    // 手机/邮箱
    accountId: string;
    // 当account为手机则需要添加区号
    areaCode?: string;
  }[];
  // 是否为管理员
  admin: boolean;
  corporationId: string;
}

/**
 * 手动添加成员
 * @param params ManualAddCorpMemberReq
 */
export const manualAddCorpMember = (params: ManualAddCorpMemberReq): Promise<boolean> => {
  return request
    .post(buildUrl('/api/dataauth/authserver/web/corporation/add/manual'), params, {}, false)
    .then(res => res?.result);
};

interface GenerateInviteCodeReq {
  // 租户id
  corporationId: string;
  // 是否是管理员
  admin: boolean;
  // 时间单位
  timeUnit: string;
  // 时间长度
  timeSpan: number;
}

/**
 * 生成邀请码
 * @param query
 */
export const generateInviteCode = (query: GenerateInviteCodeReq): Promise<string> => {
  return request
    .get(buildUrl('/api/dataauth/authserver/web/corporation/generateInviteCode'), query, {}, true)
    .then(res => res?.result);
};

interface SwitchInviteUrlReq {
  // 租户id
  corporationId: string;
  // 开关
  open: boolean;
}
/**
 * 关闭邀请码
 * @param query
 */
export const switchInviteUrlConf = (data: SwitchInviteUrlReq): Promise<boolean> => {
  return request
    .post(buildUrl('/api/dataauth/authserver/web/corporation/switchInviteUrl'), data, {}, true)
    .then(res => res?.result);
};

export interface GetMemberListParamsReq {
  // 是否按时间降序排序
  createTimeDesc?: number;
  // 是否只查询邮箱
  hasEmail?: number;
  // 是否只查找非机器人超管，1表示是，其它情况都表示否
  ignoreRobotAdmin?: number;
  // 成员身份 0-普通成员，1-创建人，2-企业管理员，-99或不传-不限身份
  memberRole?: number;
  // 成员状态,1 正常2 已移除3 待处理邀请
  memberStatus: MemberStatus;
  // 搜索名称
  name: string;
  pageNo: number;
  pageSize: number;
}

export interface GetMemberListQueryReq {
  corporation_id: string;
}

export interface GetMemberListRes {
  count: number;
  itemList: MemberItem[];
  pageNo: number;
  pageSize: number;
  totalCount: number;
}
/**
 * 获取成员列表
 * @param query
 */
export const getMemberList = (
  data: GetMemberListParamsReq,
  params: GetMemberListQueryReq
): Promise<GetMemberListRes> => {
  return request
    .post(
      buildUrl('/api/dataauth/authserver/web/corporation/list/member'),
      data,
      {
        params,
      },
      true
    )
    .then(res => res?.result);
};

interface RemoveMemberReq {
  account?: {
    accountId: string;
    areaCode: string;
  };
  inviteType?: InviteType;
  corporationId: string;
  userId?: string | null;
}
/**
 * 移除成员
 * @param query
 */
export const removeMember = (params: RemoveMemberReq): Promise<boolean> => {
  return request
    .post(buildUrl('/api/dataauth/authserver/web/corporation/remove/corpMember'), params, {}, true)
    .then(res => res?.result);
};

interface ResendMemberReq {
  account: {
    accountId: string;
    areaCode: string;
  };
  corporationId: string;
  inviteType: InviteType;
}
/**
 * 获取成员列表
 * @param query
 */
export const resendInvite = (data: ResendMemberReq): Promise<boolean> => {
  return request
    .post(buildUrl('/api/dataauth/authserver/web/corporation/resend/invite'), data, {}, true)
    .then(res => res?.result);
};

/**
 * 获取邀请配置信息
 * @param data
 */
export const getInviteConf = (data: { corporationId: string }): Promise<boolean> => {
  return request
    .get(buildUrl('/api/dataauth/authserver/web/corporation/getCorpInviteSwitch'), data, {}, true)
    .then(res => res?.result);
};
