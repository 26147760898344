/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: beenlezhang
 * @LastEditTime: 2022-04-27 11:30:06
 * @Descripttion: user action
 */
import AuthService from '@/api/auth';
import { setStoreState } from '@/store/utils';
import { BizParams } from '@/@types';
/**
 * @description 所有跟用户相关的内容
 * @return status 返回状态  err_code：1，逻辑正确，err_code：0，发生错误。
 */

const AuthActions = {
  // 获取用户信息
  async getRoleList({}, params: BizParams) {
    const res = await AuthService.getRoleList(params);
    // res.data.data = res.data.data.map((item: RoleItem) => {
    //   item.operation_list = item.operation_list.map(operation => {
    //     operation.action = operation.action.map(action => {
    //       action.label = action.content;
    //       action.value = action.operation;
    //       return action;
    //     });
    //     operation.value = operation.action
    //       .filter(action => action.mark)
    //       .map(action => {
    //         return action.operation;
    //       });
    //     return operation;
    //   });
    //   return item;
    // });
    setStoreState('auth', 'roleList', res.data.data);
  },
  // 获取appkey,微信appid
  async getAppConfig() {
    const res = await AuthService.getAppConfig({ items: 'app_key,wx_web_app_id,qywx_web_app_id' });
    if (res.data?.code === 1) {
      setStoreState('auth', 'appKey', res.data?.result?.app_key || '');
      setStoreState('auth', 'wxAppId', res.data?.result?.wx_web_app_id || '');
      setStoreState('auth', 'qywxAppId', res.data?.result?.qywx_web_app_id || '');
    }
  },

  async getCommonConfigFromRainbow() {
    const res = await AuthService.getCommonConfigFromRainbow();
    if (res.data?.code === 1) {
      setStoreState('auth', 'commonConfigFromRainbow', res.data?.result || {});
    }
  },
};

type AuthActionsType = typeof AuthActions;

export { AuthActionsType };
export default AuthActions;
