import Axios from 'axios';
import { BizRoleWithMember, BizMember } from './types';
import { BaseMessage } from '@/components/base-message';

const axios = Axios.create({
  baseURL: '/api',
  withCredentials: true, // 跨域请求时发送cookie
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 2000000, // 毫秒
});

axios.interceptors.response.use(async response => {
  if (response.status !== 200) {
    BaseMessage({ type: 'error', message: '服务异常，请稍后重试' });
    return Promise.reject(response);
  }
  if (response.data.code) {
    BaseMessage({ type: 'error', message: response.data.msg });
    return Promise.reject(response);
  }
  return response.data;
});

export interface OrgResultItem {
  corporationId: string;
  orgId: string;
  orgName: string;
}

export interface UserResultItem {
  avatar: string;
  email: string;
  is_biz_auth: boolean;
  is_ioa_user: 0 | 1;
  related_oa_user_id: string;
  user_id: string;
  user_name: string;
}

export const searchAuthTarget = (params: {
  bizId: string;
  keyword: string;
}): Promise<{
  orgInfoList: OrgResultItem[];
  userInfoList: UserResultItem[];
}> =>
  Axios.get('/api/datatalk/server/auth/query/name/like', { params }).then(({ data }) => data.data);

export const getOrgUserTree = (params: {
  bizId: string;
  corpId: string | number;
  orgId: string | number;
}): Promise<any> =>
  Axios.get('/api/data/manager/v2/third/dataauth/org/query/tree', { params }).then(
    ({ data }) => data.data
  );

// 查询当前空间角色列表，成员列表信息
export const getBizMemberList = (params: {
  bizId: string;
}): Promise<{
  bizRoleWithMemberList: BizRoleWithMember[];
  memberSet: BizMember[];
}> =>
  Axios.get('/api/datatalk/server/auth/biz/member/query/list', { params }).then(
    ({ data }) => data.data
  );
