/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-11-01 11:32:19
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-11-17 10:54:12
 * @Description: 处理回调地址
 *
 */
import $router from '@/router';
import { useDAReleasesStore } from '@/views/one-login/store/common';
import { BaseQuery, LimitedRedirect } from '@/views/one-login/types';
import { isMobile } from '@/utils';

const getHost = (url: string): string => {
  try {
    const obj = new URL(url);
    return obj.host;
  } catch (e) {
    return '';
  }
};

const useRedirectUrl = (): string => {
  try {
    const limitedRedirect: LimitedRedirect = useDAReleasesStore()?.releaseConfig
      ?.limitedRedirect || {
      rules: [],
      default: '',
    };
    const { dataAuthRedirectUrl = '' } = $router.currentRoute.value.query as BaseQuery;
    const mobileUrl = isMobile()
      ? (useDAReleasesStore()?.releaseConfig?.mobileCtlUrl as string)
      : '';
    // 没有自定义规则情况
    if (!limitedRedirect.rules) {
      // 如果query上没有dataAuthRedirectUrl，就用default值
      return dataAuthRedirectUrl ?? (mobileUrl || limitedRedirect.default);
    }
    // 校验自定义规则是否有通过的
    const valid = limitedRedirect.rules.some(rule =>
      RegExp(`${rule}`).test(getHost(dataAuthRedirectUrl))
    );
    if (valid) {
      return dataAuthRedirectUrl;
    }
    // 不通过走默认地址
    return mobileUrl || limitedRedirect.default;
  } catch (e) {
    console.log('登录注册处理重定向地址失败', e);
    return '';
  }
};

export default useRedirectUrl;
