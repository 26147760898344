import { buildUrl } from '@/views/one-login/apis/request';
import $router from '@/router';
import useRedirectUrl from '@/views/one-login/composables/use-redirect-url';

interface JumpParams {
  ticket?: string;
}
export const useGoSetCookieUrl = (params: JumpParams = {}): void => {
  // 主动传进来的ticket
  // 默认值为路由上的ticket
  const { ticket = $router.currentRoute.value.query.ticket } = params;
  const url = buildUrl(
    `/api/dataauth/authserver/login/addAuthCookies?ticket=${ticket}&redirectUrl=${encodeURIComponent(
      useRedirectUrl()
    )}`,
    true
  );
  // 保留此日志，后续登录跳转有问题方便定位
  console.log('落地地址', url);
  location.href = url;
};
