export interface FormState {
  name: string;
  password: string;
  repeatPassword: string;
  phone: string;
  verifyCode?: string;
  agreementChecked?: boolean;
  fullName?: string;
}

// 登录参数
export interface LoginParams {
  account?: string;
  password?: string;
  bind_code?: string;
  loginChannel?: string;
  data_auth_redirect_url?: string;
}

export interface RegisterParams {
  email: string;
  password: string;
  phone: string;
  verification_code?: string;
  login_req_key?: string;
  user_info_key?: string;
  user_name?: string;
  avatar?: string;
  department?: string;
  data_auth_redirect_url?: string;
  corp_invitation_code?: string;
}

export interface CheckExistParam {
  account: string;
}

export interface CheckEmailParam {
  account: string;
  condition: number;
}

export interface SendCodeParam {
  receive_address: string;
  send_type: string;
  areaCode?: string;
}

export interface BindWxParams {
  login_req_key?: string;
  user_info_key?: string;
  email: string;
  password: string;
}

export interface ResetPasswordParam {
  email: string;
  password: string;
  verification_code: string;
}

export interface WxloginKeyParam {
  loginChannel: string;
  data_auth_redirect_url: string;
  ap_bind_redirect_url: string;
  dataAuthRedirectUrl?: string;
  corp_invitation_code?: string;
}

export interface QywxLoginRedirectUrlParam {
  loginChannel: string;
  dataAuthRedirectUrl: string;
}

export interface UrlQueryParam {
  redirect_url?: string;
  dataAuthRedirectUrl?: string;
  corp_invitation_code?: string;
  needLoginParam?: string;
}

export interface ModifyFormState {
  name?: string;
  verifyCode?: string;
  email?: string;
  wechat?: string;
  phone?: string;
  password?: string;
  secondPassword?: string;
  beforeVerifyCode?: string;
}

export interface UpdateUserParams {
  name?: string;
}

export interface VerifyCodeParam {
  email?: string;
  phone?: string;
  password?: string;
  areaCode?: string;
  verification_code: string;
  old_verification_code?: string;
  sendType?: string;
}

export interface BindInfoParams {
  areaCode?: string;
  password?: string;
  receiveAddress: string;
  sendType: string;
  verificationCode: string;
  key?: string;
}

export interface InviteCodeParam {
  code: string;
}

export interface LoginChannelParam {
  loginChannel: string;
}

export interface CheckLoginParam {
  app_key: string;
  redirect_url: string;
}

export declare class TencentCaptcha {
  constructor(appid: string, cb: any, option: any);
  show(): void;
}

export enum SendType {
  phone = 'PHONE',
  email = 'EMAIL',
}

export enum CorporationType {
  '个人租户',
  '企业租户',
  'demo租户',
}
