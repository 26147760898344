/*
 * @Author: beenlezhang
 * @Date: 2022-04-06 20:20:47
 * @LastEditors: kenjiding kenjiding@tencent.com
 * @LastEditTime: 2022-05-26 11:06:37
 * @Descripttion:
 */
import { UrlQueryParam } from '@/@types/login';

// 获取登录后重定向url
export const getAuthRedirectUrl = (query: UrlQueryParam) => {
  const daRedirectUrl = query.dataAuthRedirectUrl || query.redirect_url;
  const redirectUrl = query.corp_invitation_code ? `${location.origin}` : daRedirectUrl || '';
  return redirectUrl ? redirectUrl : location.origin;
};

export const getUrlQuery = (query: UrlQueryParam, param = '') => {
  // 重定向后，是否带重定向地址带上其他参数
  let needLoginParamQuery = '';
  if (query.needLoginParam) {
    needLoginParamQuery = `needLoginParam=${query.needLoginParam !== 'false'}`;
  }
  // 添加额外的参数
  const otherQuery = `${param ? `${param}&` : ''}${needLoginParamQuery}`;
  const daRedirectUrl = query.dataAuthRedirectUrl || query.redirect_url;
  const url = daRedirectUrl?.toString() || '';
  const redirectUrl = url ? `?redirect_url=${encodeURIComponent(url)}` : '';
  return redirectUrl ? `${redirectUrl}&${otherQuery}` : `?${otherQuery}`;
};

// 通过location.href跳转页面
export const genHrefOpenUrl = (url = ''): string => {
  let tempUrl = decodeURIComponent(url);
  // url是否以 / 开头, 例如：/login 则是当前项目的路由url，需要添加套件的/dataauth前缀。如果是 http://xxx.com/login的url直接返回
  const isRelativePath = /^\/(?!\/).*/g.test(url);
  if (window.__MICRO_APP_ENVIRONMENT__ && isRelativePath) {
    tempUrl = `/${window.__MICRO_APP_NAME__}${url}`;
  }

  return tempUrl;
};
