/*
 * @Author: beenlezhang
 * @Date: 2022-01-06 15:31:10
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-04 18:22:16
 * @Descripttion: 数据上报
 */
import { get } from 'lodash-es';
import BeaconAction from '@tencent/beacon-web-sdk';
import store from '@/store';
import { locationHref } from '@/config/constant';
import { getCurSiteType } from '@/views/one-login/composables/use-site';
import $router from '@/router';

const beaconSDK = new BeaconAction({
  appkey: get(window, 'AppConfig.beaconSDKappKey', ''),
  strictMode: false,
});

/**
 * 判断当前设备类型 用于埋点上报
 * @returns 判断当前设备类型 用于埋点上报
 *  pc  默认设备
 *  iframe iframe嵌入
 *  applet 小程序
 *  h5 移动设备、微信内置浏览器
 */

export function checkDeviceType() {
  let deviceType = 'pc';
  const userAgent = navigator.userAgent.toLowerCase();
  if (parent !== window) {
    deviceType = 'iframe';
  } else if (/micromessenger/i.test(userAgent)) {
    if (/miniprogram/i.test(userAgent)) {
      deviceType = 'applet';
    } else {
      deviceType = 'h5';
    }
  } else if (/mobile|android|iphone|ipad|phone/i.test(userAgent)) {
    deviceType = 'h5';
  } else {
    deviceType = 'pc';
  }
  return deviceType;
}

/**
 * 额外参数
 */
const genExtra = (): {
  site_type?: string;
  oper_id?: string;
} => {
  const extra: {
    oper_id?: string;
    site_type?: string;
  } = {};
  if (window.AppConfig.reportSiteType) {
    extra.site_type = getCurSiteType();
    extra.oper_id = $router.currentRoute.value.query.oper_id as string;
  }
  return extra;
};

/**
 * 默认上报公共参数
 * @return {Object}
 */
const defaultParams = () => {
  // 获取当前设备类型
  const deviceType = checkDeviceType();
  return {
    login_name: store.state.user.userDetail.id,
    url: locationHref,
    // PC端：pc、移动端：h5、小程序：applet、嵌入：iframe
    device_type: deviceType,
    release_version: window.AppConfig.releaseVersion,
    product_name: window.AppConfig.productName,
    ...genExtra(),
  };
};

/**
 * sdkReport 埋点上报
 * @param event
 * @param params 事件参数KEY
 */
const sdkReport = (event = '', params = {}, sync = false): void => {
  // console.log('埋点', event, params);
  if (sync) {
    beaconSDK.onDirectUserAction(event, {
      ...defaultParams(),
      ...params,
    });
    return;
  }
  beaconSDK.onUserAction(event, {
    ...defaultParams(),
    ...params,
  });
};

export default {
  sdkReport,
};
