/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-11-16 21:57:26
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-11-17 10:55:22
 * @Description: 发送验证码服务
 *
 */
import {
  sendVerificationCode,
  SendVerificationCodeRes,
  SendVerificationCodeType,
} from '@/views/one-login/apis/verification-code';
import {
  BackendAuthCertificate,
  useTencentCaptCha,
} from '@/views/one-login/composables/use-tencent-captcha';
import { ResStatus } from '@/views/one-login/apis/types';
import { BaseMessage } from '@/components/base-message';
import beaconSDK from '@/utils/report';
import { useDAReleasesStore } from '@/views/one-login/store/common';
const { openCaptcha } = useTencentCaptCha();

// 手机注册表单参数
interface SendPhoneFormParams {
  areaCode: string;
  receiveAddress: string;
  checkRegister: boolean;
  sendType: SendVerificationCodeType;
}
// 邮箱注册表单参数
interface SendEmailFormParams {
  receiveAddress: string;
  checkEmailSuffix: boolean;
  checkRegister: boolean;
  sendType: SendVerificationCodeType;
}

interface AuthCodereportParams {
  click_area: string;
}
export const sendPhoneVerificationCode = async (
  req: SendPhoneFormParams,
  reportParams?: AuthCodereportParams
): Promise<void | ResStatus> => {
  const { ticket = '', randomString = '' }: BackendAuthCertificate = (await openCaptcha()) || {};
  try {
    const code = await sendVerificationCode({
      ...req,
      ticket,
      randomString,
    });
    beaconSDK.sdkReport('get_verification_code', {
      code_type: SendVerificationCodeType.Phone,
      login_register_version: useDAReleasesStore().release,
      feedback: code,
      ...reportParams,
    });
    if (code === ResStatus.InvalidExist) {
      BaseMessage({ type: 'error', message: '号码不存在，请检查号码是否正确' });
      return;
    }
    if (code === ResStatus.InvalidPhoneExist) {
      BaseMessage({ type: 'error', message: '号码已经注册' });
      return code;
    }
    BaseMessage({ type: 'success', message: '发送成功, 请注意查看短信' });
  } catch (e) {
    beaconSDK.sdkReport('get_verification_code', {
      code_type: SendVerificationCodeType.Phone,
      login_register_version: useDAReleasesStore().release,
      feedback: JSON.stringify(e),
      ...reportParams,
    });
  }
};

export const sendEmailVerificationCode = async (
  req: SendEmailFormParams,
  reportParams?: AuthCodereportParams
): Promise<void | ResStatus> => {
  const { ticket = '', randomString = '' }: BackendAuthCertificate = (await openCaptcha()) || {};
  try {
    const code = await sendVerificationCode({
      ...req,
      randomString,
      ticket,
    });
    beaconSDK.sdkReport('get_verification_code', {
      code_type: SendVerificationCodeType.Email,
      login_register_version: useDAReleasesStore().release,
      feedback: code,
      ...reportParams,
    });
    if (code === ResStatus.InvalidEmailExist) {
      BaseMessage({ type: 'error', message: '邮箱已注册' });
      return code;
    }
    if (code === ResStatus.InvalidExist) {
      BaseMessage({ type: 'error', message: '邮箱不存在，请检查邮箱填写是否正确' });
      return;
    }
    BaseMessage({ type: 'success', message: '获取成功, 请注意查看邮件' });
  } catch (e) {
    beaconSDK.sdkReport('get_verification_code', {
      code_type: SendVerificationCodeType.Email,
      login_register_version: useDAReleasesStore().release,
      feedback: JSON.stringify(e),
      ...reportParams,
    });
  }
};
