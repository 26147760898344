/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: yanhongchen
 * @LastEditTime: 2023-03-21 11:13:21
 * @Descripttion: action
 */
import CompanyService from '@/api/company';
import { cloneDeep } from 'lodash-es';
import { setStoreState } from '@/store/utils';
import { HttpResponse } from '@/@types';
import BeaconPlugin from '@tencent/beacon-plugin';
import { useRoute } from 'vue-router';
import { BaseMessage } from '@/components/base-message';
import { unlimitedQuota, AdminType } from '@/config/constant';
import {
  BizCountParam,
  CorporationParams,
  CreateCompanyData,
  PostApiParams,
} from '@/@types/company';
import { key } from '@/store';

/**
 * @description 所有跟用户相关的内容
 * @return status 返回状态  code：0，逻辑正确，code：1，发生错误。
 */

const companyActions = {
  // 获取用户信息
  async getCompanyInfo(store: any, params: CorporationParams) {
    const result = await CompanyService.getCompanyInfo(params);
    if (result.data?.code === 1) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { status, ...rest } = result.data?.result;
      const { detail } = store.state;
      setStoreState('company', 'status', status);
      setStoreState('company', 'detail', {
        ...detail,
        ...rest,
      });
    }
    return result;
  },

  async getBeaconPlugin(store: any, params: CorporationParams) {
    const { corporation_id } = params;
    const pluginConfigList = await BeaconPlugin.load('', {
      corpId: corporation_id,
      group: 'CorpResource',
    });
    console.log('pluginConfigList', pluginConfigList);
    const { quotaMap } = store.state;
    const newQuotaMap = cloneDeep(quotaMap);
    Object.keys(quotaMap).forEach(key => {
      const singleQuota = pluginConfigList?.find(({ code }) => code === key);
      if (singleQuota === undefined) return;
      const { limit = { limit: unlimitedQuota } } = singleQuota;
      [newQuotaMap[key]] = Object.values(limit);
    });
    setStoreState('company', 'quotaMap', newQuotaMap);
    setStoreState('company', 'detail', {
      ...store.state.detail,
      corporation_id,
    });
  },

  async getAppActiveDetail(store: any, params: any) {
    const result = await CompanyService.getAppActiveDetail({
      corporationId: params.corporationId,
    });
    if (result.data?.code === 1) {
      setStoreState('company', 'appList', result.data.result || []);
    } else {
      BaseMessage({
        message: result.data.msg,
        type: 'error',
      });
    }
    return result;
  },

  // 获取用户信息
  async getManagerList(store: any, params: CorporationParams) {
    const result = await CompanyService.getManagerList(params);
    if (result.data?.code === 1) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { detail } = store.state;
      setStoreState('company', 'detail', {
        ...detail,
        managerList: result.data.result,
      });
    }
    return result;
  },

  // 是否是企业管理员
  async checkCompanyAdmin({}, params: CorporationParams) {
    const result = await CompanyService.checkCompanyAdmin(params);
    if (result?.data?.code === 1) {
      setStoreState('company', 'isAdmin', result.data.result);
    }
    return result;
  },

  // 是否有修改关联业务和高级功能权限
  async checkUpdatePermission({}, params: { appGroup: string; bizId: string }) {
    const result = await CompanyService.checkUpdatePermission(params);
    if (result?.data?.code === 1) {
      setStoreState('company', 'hasEditAuth', result.data.result);
    }
    return result;
  },

  async getIndustryList() {
    const result = await CompanyService.getIndustryList();
    if (result?.data?.code === 1) {
      setStoreState('company', 'industryList', result.data.result);
    }
    return result;
  },

  // 获取行业列表
  async getPlatformList() {
    const result = await CompanyService.getPlatformList();
    if (result?.data?.code === 1) {
      const appList = result.data.result.map((item: any) => ({
        label: item.app_name,
        value: item.app_key,
        tooltip: item.app_info,
      }));
      setStoreState('company', 'appKeys', appList);
    }
    return result;
  },

  // 获取还没审核或审核没通过的企业申请详情
  async getNopassCompanyDetail({}, params: CorporationParams) {
    const result = await CompanyService.getCompanyDetail(params);
    if (result?.data?.code === 1) {
      setStoreState('company', 'detail', result.data.result);
    }
    return result;
  },

  // 企业空间数
  getComapnayBizCount({}, params: BizCountParam) {
    CompanyService.getComapnayBizCount(params).then((res: HttpResponse) => {
      if (res?.data?.code === 0) {
        setStoreState('company', 'bizCount', res.data.data);
      }
    });
  },

  // 企业成员数
  getComapnayMemberCount() {
    CompanyService.getComapnayMemberCount().then((res: HttpResponse) => {
      if (res?.data?.code === 0) {
        setStoreState('company', 'memberCount', res.data.data);
      }
    });
  },

  // 获取成员列表
  async getCompanyMemberList({}, param: PostApiParams) {
    const { params, data } = param;
    return await CompanyService.getCompanyMemberList(params, data);
  },

  // 创建企业
  async createCompany({}, data: CreateCompanyData) {
    const result = await CompanyService.applyCompany(data);
    if (result?.data?.code === 1) {
      setStoreState('company', 'status', result.data.data);
    }
    return result;
  },

  // 更新成员列表
  async updateNopassCompany({}, param: PostApiParams) {
    const { params, data } = param;
    return await CompanyService.updateNopassCompany(params, data);
  },

  async getSpaceDetail(store: any, params: any) {
    const result = await CompanyService.getCorporationInfo(params);
    if (result?.data?.code === 1) {
      setStoreState('company', 'spaceDetail', result.data.result);
    }
    store.dispatch('getBusiAdminList', { busiIdSet: [result?.data?.result?.busiId] });
    return result;
  },

  async getBusiAdminList(store: any, data: any) {
    const result = await CompanyService.getBusiAdminList(data);
    if (result?.data?.code === 1) {
      const res = result.data.result;
      if (res && res.length > 0) {
        const busiManager = res[0].busiMemberVOList.filter(
          (item: any) => item.memberType === AdminType.BUSINESS_ADMIN
        );
        const costManager = res[0].busiMemberVOList.filter(
          (item: any) => item.memberType === AdminType.COST_MANAGER
        );
        setStoreState('company', 'busiAdminList', busiManager);
        setStoreState('company', 'spaceDetail', {
          ...store.state.spaceDetail,
          busiManager,
          costManager,
          busiName: res[0].busiName,
          busiId: res[0].busiId,
        });
      }
    }
    return result;
  },
};

type CompanyActionsType = typeof companyActions;

export { CompanyActionsType };
export default companyActions;
