/*
 * @Author: beenlezhang
 * @Date: 2021-02-22 10:01:12
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-10-25 15:17:56
 * @Descripttion: 所有跟用户相关的接口
 */
import { HttpResponse } from '@/@types';
import {
  LoginParams,
  RegisterParams,
  CheckExistParam,
  CheckEmailParam,
  SendCodeParam,
  BindWxParams,
  ResetPasswordParam,
  WxloginKeyParam,
  UpdateUserParams,
  VerifyCodeParam,
  InviteCodeParam,
  LoginChannelParam,
  CheckLoginParam,
  BindInfoParams,
  QywxLoginRedirectUrlParam,
} from '@/@types/login';
import Axios from './axios';

class UserService {
  // 注册
  public static async register(data: RegisterParams): Promise<HttpResponse> {
    return Axios('/authserver/register/ap', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 登录
  public static async login(data: LoginParams): Promise<HttpResponse> {
    return Axios('/authserver/login/ap', {
      // return Axios('/authserver/login/wx/small/ap', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 绑定微信用户并注册登录
  public static async bindWxRegister(params: RegisterParams): Promise<HttpResponse> {
    return Axios('/server/tob/wx/web/bind/register/auth', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 绑定微信用户并登录
  public static async bindWxLogin(data: BindWxParams): Promise<HttpResponse> {
    return Axios('/authserver/register/ap/bind', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 退出
  public static async logout(): Promise<HttpResponse> {
    return Axios('/authserver/web/logout', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 我的审批列表
  public static async getApprovalList(): Promise<HttpResponse> {
    return Axios('/server/tob/list', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 判断账号是否已存在
  public static async checkUserExist(params: CheckExistParam): Promise<HttpResponse> {
    return Axios('/server/tob/user/exist/auth', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 判断账号是否已存在
  public static async checkEmailExist(data: CheckEmailParam): Promise<HttpResponse> {
    return Axios('/authserver/login/check/ap', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 重置账号密码
  public static async resetPassword(data: ResetPasswordParam): Promise<HttpResponse> {
    return Axios('/authserver/login/reset/ap', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 微信扫码前调用获取key
  public static async getWxloginKey(data: WxloginKeyParam): Promise<HttpResponse> {
    return Axios('/authserver/login/get/key', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 微信扫码前调用获取key
  public static async getQywxloginKey(params: WxloginKeyParam): Promise<HttpResponse> {
    return Axios('/authserver/login/getRedirectUrl', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 更新用户name信息
  public static async getUserInfo(): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 获取当前用户已绑定的登录方式
  public static async getOtherLoginInfo(): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/other/listLoginChannel', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 修改注册用户名
  public static async updateUserName(data: UpdateUserParams): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/name', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改账密注册用户邮箱前安全验证
  public static async verifyUserEmail(data: VerifyCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/ap/verify', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改账密注册用户邮箱前安全验证
  public static async verifyUserPhone(data: VerifyCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/ap/verify', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 当前用户绑定邮箱是检查是否设置了密码
  public static async checkPasswordExistForBindEmail(): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/checkSetPwd', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 用户绑定新邮箱
  public static async bindUserEmail(data: BindInfoParams): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/bindAp', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改账密注册用户邮箱
  public static async updateUserEmail(data: VerifyCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/ap/email', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  public static async bindUserPhone(data: BindInfoParams): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/bindAp', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改账密注册用户邮箱
  public static async updateUserPhone(data: VerifyCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/phone', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 解除绑定登录
  public static async unbindWechat(params: LoginChannelParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/unbindLoginChannel', {
      method: 'post',
      responseType: 'json',
      params,
    });
  }

  public static async sendRegisterVerifyCode(data: SendCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/register/ap/sendVerificationCode', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 发送邮箱或手机验证码
  public static async sendVerifyCode(data: SendCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/ap/sendVerificationCode', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 个人账号页扫码绑定微信前获取key
  public static async getWechatKey(data: WxloginKeyParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/get/key', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 个人账号页扫码绑定微信前获取key
  public static async getInfoByInviteCode(params: InviteCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/get/key', {
      method: 'post',
      responseType: 'json',
      params,
    });
  }

  // 个人账号页扫码绑定微信前获取key
  public static async getBindWechatKey(data: WxloginKeyParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/get/key', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 更新用户name信息
  public static async checkLogin(params: CheckLoginParam): Promise<HttpResponse> {
    return Axios('/authserver/login/status', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 退出
  public static async testLogout(): Promise<HttpResponse> {
    return Axios('/server/auth/logout', {
      method: 'get',
      responseType: 'json',
    });
  }

  // 外网：“去扫码绑定”
  public static async submitRegisterInfo(data: RegisterParams): Promise<HttpResponse> {
    return Axios('/authserver/register/submitRegisterInfo', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 新增绑定某个登录方式
  public static async getExternalBindUrl(params: LoginChannelParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/toBindLoginChannel', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  // 找回密码发送验证码
  public static async sendVerificationCodeForResetPwd(data: SendCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/register/ap/sendVerificationCodeForResetPwd', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  // 修改绑定手机号
  public static async editPhone(data: SendCodeParam): Promise<HttpResponse> {
    return Axios('/authserver/web/user_info/updateUser/phone', {
      method: 'post',
      responseType: 'json',
      data,
    });
  }

  public static async getPublicKey(): Promise<HttpResponse> {
    return Axios('/authserver/login/getPublicKey', {
      method: 'get',
      responseType: 'json',
    });
  }
}

export default UserService;
