import { DataAssetType, DataAuthAction, AppKeyType, BEAppKeyType } from './types';

export const ASSET_TYPE_TEXT = {
  [DataAssetType.DATASOURCE]: '数据连接',
  [DataAssetType.RESOURCE]: '数据集',
  [DataAssetType.SPACE_AUTHENTICATION]: '空间',
};

export const ROLE_GROUP_TABLE_COL = [
  { label: '可查看', prop: DataAuthAction.EXE },
  { label: '可编辑', prop: DataAuthAction.MODIFY },
  { label: '可授权', prop: DataAuthAction.GRANT },
  { label: '全选', prop: 'all' },
] as const;

export const getRoleGroupTypes = (
  enableOrg?: boolean
): {
  label: string;
  type: string;
}[] => {
  const defaultGroupType = [
    { label: '空间用户权限', type: 'USER' },
    { label: '角色组权限', type: 'ROLE' },
  ];
  const orgGroupType = [{ label: '组织架构权限', type: 'ORG' }];
  return enableOrg ? defaultGroupType.concat(orgGroupType) : defaultGroupType;
};

export const AppKeyMapping: Record<AppKeyType, BEAppKeyType> = {
  datainsight: 'beacon_olap',
  datatalk: 'data_talk',
  datalink: 'data_link',
  datahub: 'datahub3_team',
  datapy: 'data_py',
  tab: 'tab',
  venus: 'venus',
  wuji: 'wuji',
  mixquery: 'mix_query',
  console: 'console',
  dataaudience: 'cdp',
  datamanager: 'data_manager',
  dataauth: 'data_auth',
  metacat: 'metacat',
  monitor: 'monitor',
  push: 'push',
  resourcemanagement: 'resource_management',
  template: 'template',
  datareporting: 'data_reporting',
  'mobile-control-app': 'console',
  xingpan: 'xingpan',
  tx: 'tx',
};
