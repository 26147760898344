import { useToggle } from '@vueuse/core';
import { emailValidate, phoneValidate } from '@/views/one-login/views/login/helper/validate';
import { computed, nextTick, Ref, ref, watch } from 'vue';
interface InputItem {
  accountId: string;
  invalid: boolean;
  areaCode?: string;
}
export const useCustomInput = ({
  inputInst,
  inputWrapperInst,
}: {
  inputInst: Ref<HTMLElement | undefined>;
  inputWrapperInst: Ref<HTMLElement | undefined>;
}): {
  onDelete: (idx: number) => void;
  onFocus: (e: MouseEvent) => void;
  onEnter: (e: MouseEvent) => void;
  onChange: () => void;
  disableSubmit: Ref<boolean>;
  onBackspace: (e: MouseEvent) => void;
  onClickinputWrapperInst: (e: MouseEvent) => void;
  onInputWrapperInstBackspace: () => void;
  onInputWrapperInstPressLeftRight: (v: number) => void;
  toggleFocusingInput: (v: boolean) => void;
  curActiveConfirmIdx: Ref<number>;
  focusingInput: Ref<boolean>;
  isAdmin: Ref<boolean>;
  options: {
    value: boolean;
    label: string;
  }[];
  confirmList: Ref<InputItem[]>;
  inputVal: Ref<string>;
} => {
  const inputVal = ref('');
  const confirmList = ref<InputItem[]>([]);
  const curActiveConfirmIdx = ref<number>(-1);
  const isAdmin = ref<boolean>(false);
  const options: {
    value: boolean;
    label: string;
  }[] = [
    { value: false, label: '普通成员' },
    { value: true, label: '管理员' },
  ];
  const [focusingInput, toggleFocusingInput] = useToggle();

  const onClickinputWrapperInst = (e: MouseEvent): void => {
    e.preventDefault();
    inputInst.value?.focus();
    inputWrapperInst.value?.scrollTo({
      top: inputWrapperInst.value?.scrollHeight,
      behavior: 'smooth',
    });
  };
  const resetCurActiveConfirmIdx = (): number => (curActiveConfirmIdx.value = -1);
  const onInputWrapperInstBackspace = (): void => {
    if (confirmList.value.length - 1 < curActiveConfirmIdx.value || curActiveConfirmIdx.value < 0) {
      return;
    }
    confirmList.value.splice(curActiveConfirmIdx.value, 1);
    curActiveConfirmIdx.value -= 1;
    if (confirmList.value.length === 0) {
      inputInst.value?.focus();
    }
  };
  const onInputWrapperInstPressLeftRight = (v: number): void => {
    const newVal = curActiveConfirmIdx.value + v;
    // 到达边界，则不处理
    if (curActiveConfirmIdx.value === -1 || newVal === -1) return;
    if (newVal === confirmList.value.length) {
      inputInst.value?.focus();
      resetCurActiveConfirmIdx();
      return;
    }
    inputInst.value?.blur();
    curActiveConfirmIdx.value = newVal;
  };
  const onChange = (): void => {
    inputVal.value = inputVal.value.trim();
  };
  const onEnter = (e: MouseEvent): void => {
    // 弹出输入法时，回车事件不处理
    if (
      e?.target?.composing ||
      // 空值不处理
      !inputVal.value ||
      // 已存在不处理
      confirmList.value.some(i => i.accountId === inputVal.value)
    )
      return;
    const { enablePhoneInvite } = window.AppConfig?.corporation;
    let invalid = false;
    if (enablePhoneInvite) {
      invalid = !(phoneValidate(inputVal.value) || emailValidate(inputVal.value));
    } else {
      invalid = !emailValidate(inputVal.value);
    }
    confirmList.value.push({
      accountId: inputVal.value.replace(' ', ''),
      invalid,
      areaCode: phoneValidate(inputVal.value) ? '+86' : '',
    });
    inputVal.value = '';
    // 吸底
    nextTick(() => {
      inputWrapperInst.value?.scrollTo({
        top: inputWrapperInst.value?.scrollHeight,
        behavior: 'smooth',
      });
    });
  };

  const onFocus = () => {
    toggleFocusingInput();
    resetCurActiveConfirmIdx();
  };
  const onDelete = (idx: number): void => {
    confirmList.value.splice(idx, 1);
    curActiveConfirmIdx.value = -1;
  };
  const onBackspace = (e: MouseEvent): void => {
    // confirmList为空 或 已有输入内容 则不处理
    if (!confirmList.value.length || inputVal.value.length) return;
    e.stopPropagation();
    curActiveConfirmIdx.value = confirmList.value.length - 1;
    inputWrapperInst.value?.focus();
  };
  const disableSubmit = computed(
    () => !confirmList.value.length || confirmList.value.some(i => i.invalid)
  );
  watch(
    () => inputVal.value,
    (nv: string) => {
      if (nv) {
        setTimeout(() => {
          inputInst.value?.focus();
        });
      }
    }
  );
  return {
    onDelete,
    onFocus,
    onEnter,
    onChange,
    disableSubmit,
    onInputWrapperInstBackspace,
    onInputWrapperInstPressLeftRight,
    onBackspace,
    onClickinputWrapperInst,
    curActiveConfirmIdx,
    focusingInput,
    toggleFocusingInput,
    isAdmin,
    options,
    confirmList,
    inputVal,
  };
};
