/*
 * @Author: kelvinywang
 * @Date: 2022-10-09 20:25:49
 * @LastEditors: kelvinywang kelvinywang@tencent.com
 * @LastEditTime: 2022-10-16 13:19:22
 * @Description: 登录相关接口
 */
import {
  DataAuthReleaseConfig,
  LoginChannelEnum,
  RegisterChannelEnum,
  ReleaseEnum,
} from '../types';
import request, { buildUrl } from './request';
import { ResStatus } from './types';

// 账密登录请求体
export interface LoginWithAPReq {
  // 账号
  // loginChannel = AP 情况下，是邮箱
  // loginChannel = PHONE_AP 情况下，是手机号 +国家码
  account: string;
  // 公钥的key
  key: string;
  // 密码
  password: string;
  // 登录方式（只有 AP 和 PHONE_AP 两种）
  loginChannel: LoginChannelEnum.AP | LoginChannelEnum.PHONE_AP;
}

// 账号密码登录接口响应体
export type LoginWithAPRes = {
  status: ResStatus.Success | ResStatus.InvalidExist | ResStatus.InvalidPassword;
  ticket: string;
};

// 手机号登录接口请求体
export interface LoginWithPhoneReq {
  // 手机号（需要带国家码）
  phone: string;
  // 区号
  areaCode: string;
  // 验证码
  phoneVerificationCode: string;
}

// 手机号登录接口响应体
export type LoginWithPhoneRes = {
  status: ResStatus.Success | ResStatus.SuccessRegister;
  ticket?: string;
};

// 获取三方登录完成后回调地址请求参数
export interface ThirdRedirectUrlReq {
  loginChannel: LoginChannelEnum.WX | LoginChannelEnum.QYWX;
  // 必须传，登录成功后的回跳地址
  dataAuthRedirectUrl: string;
  // 注册页地址
  // 当三方登录后，发现用户未注册后端会回跳该地址
  // 对于 saas 版本自身会前往 三方手机号绑定页
  // 对于投后版本会前往 邮箱注册页
  registerUrl: string;
  // 回跳到业务系统时候是否需要携带登录相关参数，如login_ticket
  needLoginParam: boolean;
}

// 获取三方登录完成后回调地址响应
export interface ThirdRedirectUrlRes {
  // 用于后端缓存记录 key，需要带到三方回调的 state 内
  key: string;
  // 回调地址，需要带给三方回调
  redirectUrl: string;
}

// 重置账密请求体
export interface ResetAPReq {
  key: string;
  password: string;
  phone: string;
  areaCode: string;
  verificationCode: string;
}

/**
 * 账号密码登录
 * - 支持手机号/邮箱登录
 * @param req
 * @returns
 */
export function loginWithAP(req: LoginWithAPReq): Promise<LoginWithAPRes> {
  return request
    .post(buildUrl('/api/dataauth/authserver/login/v1/ap', true), req, {}, true)
    .then(res => res?.result);
}

/**
 * 手机号登录/注册（当用户未注册过该接口会自动帮用户注册）
 * @param req
 * @returns
 */
export function loginWithPhone(req: LoginWithPhoneReq): Promise<LoginWithPhoneRes> {
  return request
    .post(buildUrl('/api/dataauth/authserver/login/phone'), req, {}, true)
    .then(res => res?.result);
}

/**
 * 获取三方登录完成后回调地址
 * @returns
 */
export function fetchThirdRedirectUrl(req: ThirdRedirectUrlReq): Promise<ThirdRedirectUrlRes> {
  return request
    .get(buildUrl('/api/dataauth/authserver/login/getRedirectUrl', true), req, {}, true)
    .then(res => res?.result);
}

/**
 * 重置账号密码
 * @param req
 * @returns
 */
export function resetAccountPassword(req: ResetAPReq): Promise<boolean> {
  return request
    .post(buildUrl('/api/dataauth/authserver/login/resetAp'), req, {}, true)
    .then(res => res?.result);
}

/**
 * 获取当前版本支持的登录版本配置
 * @returns
 */
export function fetchLoginReleaseConfig(): Promise<DataAuthReleaseConfig[]> {
  const hackCode = (releaseConfigList: DataAuthReleaseConfig[]) => {
    const getVal = (v: string | undefined, dftVal: string): string => {
      if (!v) return dftVal;
      // 把default的转成location.host
      return v.replace('{DEFAULT}', location.host);
    };
    return releaseConfigList.map(config => ({
      ...config,
      limitedRedirect: {
        rules: config.limitedRedirect?.rules ?? [],
        default: getVal(config.limitedRedirect?.default, location.host),
      },
      serverHost: getVal(config.serverHost, location.host),
    }));
  };
  return request
    .get('/api/dataauth/authserver/login/getFrontendLoginReleaseConfig', {}, {})
    .then(res => hackCode(res?.result.releases))
    .catch(e => {
      console.log('读取配置异常', e);
      return hackCode([
        {
          id: ReleaseEnum.DEFAULT,
          serverHost: '{DEFAULT}',
          loginChannelConfig: [
            {
              id: LoginChannelEnum.AP,
              title: '密码登录',
              default: true,
            },
          ],
          registerChannelConfig: [
            {
              id: RegisterChannelEnum.EMAIL_PHONE,
              title: '邮箱注册',
              default: true,
            },
          ],
          limitedRedirect: {
            rules: ['(\\.oa)|(\\.woa)|(\\.qq)\\.com$'],
            default: 'https://{DEFAULT}/console',
          },
        },
        {
          id: ReleaseEnum.EXTERNAL,
          title: '投资公司版本',
          serverHost: 'tttt.beacon.qq.com',
          loginChannelConfig: [
            {
              id: LoginChannelEnum.AP,
              title: '密码登录',
              default: true,
            },
          ],
          registerChannelConfig: [
            {
              id: RegisterChannelEnum.EMAIL,
              title: '邮箱注册',
              default: true,
            },
          ],
          limitedRedirect: {
            rules: ['(\\.oa)|(\\.woa)|(\\.qq)\\.com$'],
            default: 'https://tttt.beacon.qq.com/console',
          },
        },
        {
          id: ReleaseEnum.INNER,
          title: '腾讯公司内部',
          serverHost: 'beacon.woa.com',
          loginChannelConfig: [
            {
              id: LoginChannelEnum.IOA,
              title: 'IOA 登录',
            },
          ],
        },
      ]);
    });
}

/**
 * 用户信息
 * @param req
 * @returns
 */
export function getUserInfo(): Promise<{
  areaCode: string;
  avatar: string;
  is_ioa_user: number;
  phone: string;
  user_id: string;
  user_name: string;
}> {
  return request
    .get(buildUrl('/api/dataauth/authserver/web/user_info'), {}, {}, false)
    .then(res => res?.result);
}
