import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { defineComponent, reactive } from 'vue';
import Nav from '@/components/Nav/index.vue';
import { useRouter } from 'vue-router';
import { isMobile } from '@/utils/index';
import { setDocTitle } from '@/utils/micrpApp';
export default defineComponent({
  name: 'Layout',
  components: {
    AppNav: Nav
  },
  setup: function setup() {
    setDocTitle();
    var router = useRouter();
    var isInMicroApp = window.__MICRO_APP_ENVIRONMENT__;
    var routeInfo = reactive({
      header: false,
      nav: false,
      education: false,
      company: false,
      showAvatar: true,
      bizlist: false,
      showCorpList: false
    });
    var noAvatarNavList = ['/login', '/register', '/wxlogin', '/account/bind', '/account/find', '/registerBeacon'];
    var corpRouteNameList = ['CompanyHome'];
    router.beforeEach(function (to) {
      var _to$meta, _to$meta2, _to$meta3, _to$meta4, _to$meta5, _to$name;

      console.log('layout组件的beforeEach: ', to);
      routeInfo.nav = !!((_to$meta = to.meta) !== null && _to$meta !== void 0 && _to$meta.nav);
      routeInfo.header = !!((_to$meta2 = to.meta) !== null && _to$meta2 !== void 0 && _to$meta2.header);
      routeInfo.education = !!((_to$meta3 = to.meta) !== null && _to$meta3 !== void 0 && _to$meta3.education);
      routeInfo.company = !!((_to$meta4 = to.meta) !== null && _to$meta4 !== void 0 && _to$meta4.company);
      routeInfo.bizlist = !!((_to$meta5 = to.meta) !== null && _to$meta5 !== void 0 && _to$meta5.bizlist);
      routeInfo.showAvatar = !noAvatarNavList.includes(to.path);
      routeInfo.showCorpList = corpRouteNameList.includes("".concat((_to$name = to.name) === null || _to$name === void 0 ? void 0 : _to$name.toString()));
      return true;
    });
    var isMobileDevice = isMobile();
    return {
      routeInfo: routeInfo,
      isInMicroApp: isInMicroApp,
      isMobileDevice: isMobileDevice
    };
  }
});