/*
 * @Author: yanhongchen
 * @Date: 2022-10-28 18:47:41
 * @LastEditTime: 2023-03-14 16:47:42
 * @LastEditors: yanhongchen
 * @Description:
 */
import { assign, cloneDeep, get } from 'lodash-es';

const indexDefaultOptions = {
  enableSideBar: false,
  enablePrimaryNavs: true,
  enableSearch: false,
  guides: [],
  primaryNavs: [],
  spaceSwitch: {
    enable: false,
  },
  avatarDropDown: {
    enable: true,
    enableDefaultSettings: true,
  },
};

type ObjectKey = { [key: string]: any };
// 空间首页导航配置
function spacePageOptions(options: ObjectKey) {
  return assign(cloneDeep(indexDefaultOptions), options);
}

interface LoginStatusOptions {
  afterLogin: ObjectKey;
  beforeLogin: ObjectKey;
}

export const microAppEnvEval = function(cbInMicro: (api: any) => void, cb?: () => void) {
  if (window.__MICRO_APP_ENVIRONMENT__) {
    const { globalAPI } = window?.microApp?.getData();
    cbInMicro?.(globalAPI);
  } else {
    cb?.();
  }
};

/**
 * 初始化基座的顶部导航条
 */
export const microAppNavigation = function(type: keyof LoginStatusOptions) {
  microAppEnvEval(globalAPI => {
    const loginStatusMap: LoginStatusOptions = {
      afterLogin: {
        guides: [],
      },
      beforeLogin: {
        guides: [],
        avatarDropDown: {
          enable: false,
        },
      },
    };
    const wikiMap = get(window, 'AppConfig.wiki');
    const showFeedback = get(window, 'AppConfig.showFeedback', false);
    if (showFeedback) {
      loginStatusMap[type].guides.push({
        id: 'feedback',
        icon: 'helper-filled',
        tooltipContent: '使用反馈',
        onClick: () => {
          window.open('https://board.weixin.qq.com/board/channel?k=19140298881325261', '_blank');
        },
      });
    }
    if (wikiMap) {
      loginStatusMap[type].guides.push({
        id: 'wiki',
        icon: 'doubt-filled',
        tooltipContent: '帮助文档',
        onClick() {
          const currentPath = window.location.pathname;
          const regexMatched = Object.keys(wikiMap).some(regexp => {
            if (currentPath.search(regexp) !== -1) {
              window.open(wikiMap[regexp]);
              return true;
            }
            return false;
          });
          !regexMatched && window.open(wikiMap.general);
        },
      });
    }
    const options = spacePageOptions(loginStatusMap[type]);
    globalAPI.navigation.setTopNavigation(options);
    if (globalAPI.navigation.onClickLogo) {
      globalAPI.navigation.onClickLogo(() => {
        window.location.replace('/console');
      });
    }
  });
};

export const setDocTitle = (prefix = '') => {
  let innerTitle = '';
  microAppEnvEval(
    globalAPI => {
      // 处于基座中时：
      const appTitle = globalAPI.utils.getAppTitle();
      innerTitle = prefix
        ? `${prefix} - DataAuth | ${appTitle?.simple}`
        : `DataAuth | ${appTitle?.simple}`;
    },
    () => {
      // 独立版打开时：
      innerTitle = prefix ? `${prefix} - DataAuth` : 'DataAuth';
    }
  );
  document.title = innerTitle;
};

/**
 * 注入meta标签配置
 */
export const injectMetas = (): void => {
  const addMeta = (name: string, content: string) => {
    try {
      const meta = document.createElement('meta');
      meta.content = content;
      meta.name = name;
      document.getElementsByTagName('head')?.[0]?.appendChild?.(meta);
    } catch (e) {
      console.error(e);
    }
  };
  // 注入屏幕不能缩放的配置
  addMeta(
    'viewport',
    'width=device-width,initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no'
  );
};
