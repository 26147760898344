/*
 * @Author: beenlezhang
 * @Date: 2021-02-22 10:02:13
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-25 17:07:32
 * @Descripttion:
 */
import Axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
// import router from '@/router';
import { useRouter } from 'vue-router';
import { BaseMessage } from '@/components/base-message';
import store from '@/store';
import { setStoreState } from '@/store/utils';
import { loginUrlMap, locationHost, locationHref } from '@/config/constant';
import { genHrefOpenUrl } from '@/utils/login';

/**
 * get status code
 * @param {AxiosResponse} response Axios  response object
 */
const getErrorCode2text = (response: AxiosResponse): string => {
  const code = response.status;
  let message = '请求失败';
  switch (code) {
    case 401:
      message = '未授权';
      break;
    case 403:
      message = '服务拒绝访问';
      break;
    case 404:
      message = '访问资源不存在';
      break;
    case 502:
      message = '网关错误';
      break;
    case 504:
      message = '网关超时';
      break;
    default:
      message = '请求失败';
  }
  return message;
};

function getEncodeParams(queryString: string) {
  if (!queryString) {
    return '';
  }
  const queryParameterPairRE = /([^&;=]+)=?([^&;]*)/g;
  function decode(s: string) {
    return decodeURIComponent(s.replace(/\+/g, ' '));
  }
  const result = [];
  let matchedQueryPair;
  // eslint-disable-next-line no-cond-assign
  while ((matchedQueryPair = queryParameterPairRE.exec(queryString)) !== null) {
    result.push(`${decode(matchedQueryPair[1])}=${decode(matchedQueryPair[2])}`);
  }
  return result.join('&');
}

function getQueryString(name: string) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r) {
    return unescape(r[2]);
  }
  return '';
}

function getLoginUrl() {
  const redirectUrl = encodeURIComponent(locationHref);
  if (loginUrlMap[locationHost]) {
    return `${loginUrlMap[locationHost]}${redirectUrl}`;
  }
  return `/login?redirect_url=${redirectUrl}`;
}

function goLogin() {
  if (!store.state.auth.hasWarnNotLogin) {
    BaseMessage({
      message: '登录凭证已过期，请重新登录',
      type: 'warning',
    });
    setStoreState('auth', 'hasWarnNotLogin', true);
  }
  console.log('goLogin');
  location.href = genHrefOpenUrl(getLoginUrl());
}

/**
 * @returns  {AxiosResponse} result
 */
const service = Axios.create({
  baseURL: `${window.microApp?.getData()?.requestBaseURL || ''}/`,
  withCredentials: true, // 跨域请求时发送cookie
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 2000000, // 毫秒
});
// service.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * @description 请求发起前的拦截器
 * @returns {AxiosRequestConfig} config
 */
service.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const { usePrKey } = window.AppConfig;
    const { clientTime, serverTime } = store.state.auth;
    if (usePrKey && clientTime && serverTime) {
      config.params = {
        ...config.params,
        _pr_key: (serverTime + Math.round(Date.now() / 1000) - clientTime).toString(16),
      };
    }
    const datatalkToken = getQueryString('Datatalk-Token');
    if (datatalkToken) {
      config.headers.beaconunionkey = datatalkToken;
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

/**
 * @description 响应收到后的拦截器
 * @returns {}
 */
service.interceptors.response.use(
  /** 请求有响应 */
  async (response: AxiosResponse) => {
    if (response.status === 200 || response.status === 201) {
      if (response.data.code === 20003 && useRouter()?.currentRoute.value.path !== '/login') {
        goLogin();
      }
      if (response.data.code === 20053) {
        const { globalAPI } = window.microApp.getData();
        globalAPI.cloud.goNotPurchasedPage();
        return;
      }
      return Promise.resolve(response);
    }
    if (response.status === 302) {
      return Promise.resolve(response);
    }
    const text = getErrorCode2text(response);
    return Promise.reject(new Error(text));
  },
  /** 请求无响应 */
  (error: AxiosError) => {
    let emsg: string = error?.message || '';
    if (error?.response) {
      emsg = error.response.data?.message || error.response.statusText;
    }
    if (error?.response?.status === 401) {
      if (window.AppConfig.login?.methods?.includes('ioa')) {
        const path = `${location.pathname}?${getEncodeParams(location.search.substring(1))}`;
        let dataAuthRedirectUrl = `/authserver/login/self?redirect_url=${path}`;
        let hrefUrl = `/authserver/login?loginChannel=IOA&dataAuthRedirectUrl=${dataAuthRedirectUrl}`;
        if (window.__MICRO_APP_ENVIRONMENT__) {
          dataAuthRedirectUrl = `/api/dataauth${dataAuthRedirectUrl}`;
          hrefUrl = `/api/dataauth/authserver/login?loginChannel=IOA&dataAuthRedirectUrl=${dataAuthRedirectUrl}`;
        }
        location.href = hrefUrl;
      } else if (useRouter()?.currentRoute.value.path !== '/login') {
        goLogin();
      }
      return Promise.reject(new Error('401'));
    }
    BaseMessage({
      message: emsg,
      type: 'error',
    });
    return Promise.reject(new Error(emsg));
  }
);

export default service;
