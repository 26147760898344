import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../../assets/default-outer-company.jpg';
import _imports_1 from '../../../assets/default-company.png';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-46372850"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "company-list"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 1,
  src: _imports_0
};
var _hoisted_5 = {
  key: 2,
  src: _imports_1
};
var _hoisted_6 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, function (item) {
    return _openBlock(), _createElementBlock("div", {
      class: "company-item",
      key: item.corporation_id,
      onClick: function onClick($event) {
        return _ctx.goAppPage(item.corporation_id);
      }
    }, [item.logo ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: item.logo,
      alt: ""
    }, null, 8, _hoisted_3)) : _ctx.outerWebFlag ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : (_openBlock(), _createElementBlock("img", _hoisted_5)), _createElementVNode("p", _hoisted_6, _toDisplayString(item.corporation_name), 1)], 8, _hoisted_2);
  }), 128))]);
}