import { getMemberList } from '@/views/one-login/apis/invite';
import { MemberItem as IMemberItem } from '@/@types/company';
import { InviteType, MemberStatus } from '@/views/one-login/types';
import { Ref, ref } from 'vue';

export const useMemeberList = (): {
  initMemberList: (corporationId: string) => void;
  count: Ref<number>;
  memberList: Ref<IMemberItem[]>;
} => {
  const memberList = ref<IMemberItem[]>([]);
  const count = ref<number>(0);
  /**
   * 初始化成员列表
   */
  const initMemberList = async (corporationId: string) => {
    try {
      let { itemList } = await getMemberList(
        {
          pageNo: 1,
          pageSize: 9999,
          name: '',
          memberRole: -99, //
          memberStatus: -99,
        },
        {
          corporation_id: corporationId,
        }
      );
      count.value = 0;
      const getAddress = (member: IMemberItem): string => {
        if (member.invite_type === InviteType.EMAIL) {
          // 优先展示邮箱
          return member.email || member.phone;
        }
        return member.phone || member.email;
      };
      itemList = itemList.map((member: IMemberItem) => {
        if (member.member_status === MemberStatus.Success) count.value += 1;
        return {
          ...member,
          address: getAddress(member),
        };
      });
      memberList.value = itemList;
    } catch (e) {
      console.log(e);
    }
  };
  return {
    initMemberList,
    count,
    memberList,
  };
};
