/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-10-10 11:34:20
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-11-17 10:55:06
 * @Description: 验证码服务
 *
 */

type CallBackFunction = () => void;

/**
 * @description: 注入js
 * @param {string} jsUrl
 * @param {CallBackFunction} cb
 * @return {*}
 */
export const injectJS = (jsUrl: string, cb?: CallBackFunction): void => {
  const scriptEle: HTMLScriptElement = document.createElement('script');
  scriptEle.type = 'text/javascript';
  scriptEle.onload = function() {
    cb?.();
    scriptEle.remove();
  };
  scriptEle.src = jsUrl;
  // 绕过基座请求拦截
  // https://zeroing.jd.com/docs.html#/zh-cn/configure?id=ignore%e5%bf%bd%e7%95%a5%e5%85%83%e7%b4%a0
  scriptEle.setAttribute('ignore', 'true');
  document.getElementsByTagName('head')[0].appendChild(scriptEle);
};
interface AuthRes {
  // 应用id
  appid?: string;
  // 验证结果，0：验证成功。2：用户主动关闭验证码。
  ret: number;
  // 验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
  ticket: string;
  // 本次验证的随机串，后续票据校验时需传递该参数。
  randstr?: string;
  // 错误码
  errorCode?: number;
  // 错误信息
  errorMessage?: string;
}

// 后端需要的凭证参数
export interface BackendAuthCertificate {
  ticket?: string;
  randomString?: string;
}
let injected = false;
export const useTencentCaptCha = (): {
  initCaptChaService: () => void;
  openCaptcha: () => Promise<BackendAuthCertificate | void>;
} => {
  const initCaptChaService = (): void => {
    // 避免重复注入
    if (injected) return;
    injected = true;
    injectJS('https://turing.captcha.qcloud.com/TCaptcha.js', () => {
      console.log('验证码服务注入成功');
    });
  };

  /**
   * @description: 打开验证码
   * resolve时候则校验通过，反则reject不通过
   */
  const openCaptcha = (): Promise<BackendAuthCertificate> =>
    new Promise((resolve, reject) => {
      try {
        const callback = (res: AuthRes): void => {
          // res（用户主动关闭验证码）= {ret: 2, ticket: null}
          // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
          // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据）=
          //  {ret: 0, ticket:"String", randstr: "String",  errorCode: Number, errorMessage: "String"}
          if (res.ret === 0) {
            resolve({
              ticket: res.ticket,
              randomString: res.randstr as string,
            });
            return;
          }
          reject();
        };
        if (!window.AppConfig.tencentCaptchaAppId) {
          reject(new Error('必须提供tencentCaptchaAppId，请检查版本配置'));
        }
        const captcha = new window.TencentCaptcha(
          window.AppConfig.tencentCaptchaAppId,
          callback,
          {}
        );
        captcha?.show();
      } catch (e) {
        reject(e);
      }
    });
  return {
    initCaptChaService,
    openCaptcha,
  };
};
