/*
 * @Author: kelvinywang kelvinywang@tencent.com
 * @Date: 2022-10-16 11:25:57
 * @LastEditors: kelvinywang kelvinywang@tencent.com
 * @LastEditTime: 2022-10-16 14:33:35
 * @Description: IOA 登录逻辑
 */
import { ref } from 'vue';
import $router from '@/router';
import { verifyInIOA } from '../../apis/verification-code';
import { useDAReleasesStore } from '../../store/common';
import { ReleaseEnum } from '../../types';
import beaconSDK from '@/utils/report';
import { useLoginStore } from '@/views/one-login/store/login';

const visible = ref(false);
const inIoa = ref(false);

export const useIOALogin = function useIOALogin() {
  const releaseStore = useDAReleasesStore();
  return {
    visible,
    inIoa,
    /**
     * 处理 IOA 登录
     * @returns
     */
    handleInnerIOALogin() {
      // 如果当前存在内网版本
      const ioaLogin = useIOALogin();
      // 检测是否在内网
      // 触发 IOA 登录提示
      return ioaLogin.checkInIOA();
    },
    judgeCanAuthOpen() {
      // 是否请求参数为空
      const ifQueryEmpty = Object.keys($router.currentRoute.value.query).length === 0;
      // 是否包含内网版本
      const hasInnerConfig = releaseStore.allReleaseConfig.some(
        config => config.id === ReleaseEnum.INNER
      );
      if (!(hasInnerConfig && ifQueryEmpty)) {
        return false;
      }
      return true;
    },
    async checkInIOA() {
      try {
        await verifyInIOA();
        inIoa.value = true;
        if (this.judgeCanAuthOpen()) this.open();
        return true;
      } catch (error) {
        console.log('校验是否在内网失败', error);
        return false;
      }
    },
    open() {
      visible.value = true;
    },
    close() {
      visible.value = false;
    },
    goToIOALogin() {
      const innerServerHost = releaseStore.getReleaseConfig(ReleaseEnum.INNER)?.serverHost;
      if (!innerServerHost) return;
      console.log('跳ioa登录', innerServerHost);
      beaconSDK.sdkReport('click_login_button', {
        login_channel: useLoginStore().channel,
        login_register_version: useDAReleasesStore().release,
        feedback: 'success',
      });
      location.href = `//${innerServerHost}`;
    },
  };
};
