/*
 * @Author: beenlezhang
 * @Date: 2022-04-27 16:58:13
 * @LastEditors: cocogong
 * @LastEditTime: 2022-05-07 14:21:06
 * @Descripttion:
 */
import router from './router';
import AuthService from '@/api/auth';
import store from '@/store';
import { setStoreState } from '@/store/utils';
import beaconSDK from '@/utils/report';

router.beforeEach(async (to, from, next) => {
  // TODO 测试代码先注释
  // 获取服务器时间, 计算与本地的时间偏移量
  const { usePrKey } = window.AppConfig;
  try {
    if (usePrKey && !store.state.auth.clientTime) {
      const data = await AuthService.getPrKey();
      const clientTime = Math.round(Date.now() / 1000);
      const serverTime = parseInt(data.data.result, 16);
      setStoreState('auth', 'clientTime', clientTime);
      setStoreState('auth', 'serverTime', serverTime);
    }
    beaconSDK.sdkReport(`${to.name?.toLocaleLowerCase()}_visit`);
  } catch (err) {
    console.log('AuthService.getPrKey接口出错: ', err);
  }
  next();
});
