export enum RowColType {
  ROW = 'ROW',
  COL = 'COL',
}

export interface ColData {
  groupId?: string;
  groupName: string;
  groupDesc: string;
  cols: {
    fieldKey: string;
    fieldName: string;
    createdBy: string;
    modifiedBy: string;
  }[];
}
export const DEFAULT_ROW_CONFIG = {
  bizId: '',
  fieldKey: '',
  fieldName: '',
  createdBy: '',
  modifiedBy: '',
};
export type RowConfig = typeof DEFAULT_ROW_CONFIG;

export const DEFAULT_COL_CONFIG = {
  bizId: '',
  fieldKey: '',
  fieldName: '',
  groupDesc: '',
  groupName: '',
  createdBy: '',
  modifiedBy: '',
  groupId: '' as string | undefined,
};
export type ColConfig = typeof DEFAULT_COL_CONFIG;
