/*
 * @Author: cocogong
 * @Date: 2022-04-14 09:48:18
 * @LastEditTime: 2022-04-19 14:37:52
 * @LastEditors: cocogong
 * @Description:
 */
export const myApplyHeader = [
  {
    title: '申请时间',
    key: 'createTime',
    hideH: true,
  },
  {
    title: '申请内容',
    key: 'content',
  },
  {
    title: '内容类型',
    key: 'categoryName',
  },
  {
    title: '申请权限类型',
    key: 'modifyName',
  },
  {
    title: '审批通过的权限',
    key: 'realAuthType',
  },
  {
    title: '当前审批人',
    key: 'handlerName',
  },
  {
    title: '申请内容的路径',
    key: 'location',
  },
  {
    title: '审批进度',
    key: 'approvalHistoryDesc',
  },
  {
    title: '一级审批时间',
    key: 'firstApprovalTime',
    hideH: true,
  },
  {
    title: '二级审批时间',
    key: 'secondApprovalTime',
    hideH: true,
  },
];
export const myApproveHeader = [
  {
    title: '申请时间',
    key: 'createTime',
    hideH: true,
  },
  {
    title: '申请人',
    key: 'applicantName',
  },
  {
    title: '申请内容',
    key: 'content',
  },
  {
    title: '内容类型',
    key: 'categoryName',
  },
  {
    title: '权限归属空间',
    key: 'bizName',
    hideH: true,
  },
  {
    title: '申请人组织架构',
    key: 'applicantOrgName',
  },
  {
    title: '申请内容的路径',
    key: 'location',
  },
  {
    title: '申请人用户类型',
    key: 'applicantUserType',
  },
  {
    title: '申请权限类型',
    key: 'modifyName',
    editFlag: true,
  },
  {
    title: '审批通过的权限',
    key: 'realAuthType',
  },
  {
    title: '审批进度',
    key: 'approvalHistoryDesc',
  },
  {
    title: '一级审批时间',
    key: 'firstApprovalTime',
    hideH: true,
  },
  {
    title: '二级审批时间',
    key: 'secondApprovalTime',
    hideH: true,
  },
  {
    title: '申请理由',
    key: 'applyReason',
  },
];
