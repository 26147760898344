import { isBcenterEnv } from './constant';

interface Map {
  [key: string]: any;
}

export interface FilterMap {
  [key: string]: string;
}

const accountTypeMap: Map = {
  1: 'member',
  2: 'invite',
  3: 'space',
};

// 成员列表
let MEMBER_COLUMNS: Map[] = [
  {
    title: '成员名称',
    dataIndex: 'user_name',
    key: 'user_name',
    ellipsis: true,
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    width: '260px',
    ellipsis: true,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
    width: '260px',
    ellipsis: true,
  },
  {
    title: '身份',
    dataIndex: 'roleName',
    key: 'roleName',
    ellipsis: true,
    filterMultiple: false,
    filtered: true,
    filterKey: 'role',
    slots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'rolefilterIcon',
      // customRender: 'status',
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    filtered: true,
    filterKey: 'status',
    filterMultiple: false,
    slots: {
      filterDropdown: 'memberStatusFilter',
      filterIcon: 'statusFilterIcon',
    },
  },
  {
    title: '添加日期',
    dataIndex: 'create_time',
    key: 'create_time',
    ellipsis: true,
  },
];
const { enablePhoneInvite } = window.AppConfig;
if (enablePhoneInvite) {
  // 仅部分版本支持手机号
  MEMBER_COLUMNS = MEMBER_COLUMNS.filter(i => i.key !== 'phone');
}
if (!isBcenterEnv) {
  MEMBER_COLUMNS.push({
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
  });
}

// 邀请列表
const INVITE_COLUMNS: Map[] = [
  {
    title: '邀请成员邮箱',
    dataIndex: 'invitee_email',
    key: 'invitee_email',
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    filtered: true,
    filterKey: 'invite',
    slots: {
      filterDropdown: 'inviteStatusfilter',
      filterIcon: 'inviteFilterIcon',
      // customRender: 'status',
    },
  },
  {
    title: '邀请人',
    dataIndex: 'inviter_username',
    key: 'inviter_username',
    ellipsis: true,
    width: '400px',
  },
  {
    title: '邀请日期',
    dataIndex: 'create_time',
    key: 'create_time',
    ellipsis: true,
  },
];

// 空间列表
const SPACE_COLUMNS: Map[] = [
  {
    title: '空间ID',
    dataIndex: 'biz_id',
    key: 'biz_id',
    ellipsis: true,
    width: 200,
  },
  {
    title: '空间名称',
    dataIndex: 'biz_name',
    key: 'biz_name',
    // width: 260,
    ellipsis: true,
  },
  {
    title: '空间管理员',
    dataIndex: 'creator_manager',
    key: 'creator_manager',
    // width: 360,
    ellipsis: true,
  },
  {
    title: '创建日期',
    dataIndex: 'create_time',
    key: 'create_time',
    ellipsis: true,
    width: 200,
  },
];

if (window.AppConfig.showOpenedProductsColumn) {
  SPACE_COLUMNS.push({
    title: '已开通产品',
    dataIndex: 'project',
    key: 'project',
    ellipsis: true,
  });
}

export enum MemberRoleEnum {
  ALL = -99,
  MEMBER = 0,
  CREATOR = 1,
  ADMIN = 2,
}

// 成员身份
const MEMBER_ROLE: Map = {
  [MemberRoleEnum.ALL]: '全部',
  [MemberRoleEnum.MEMBER]: '普通成员',
  [MemberRoleEnum.CREATOR]: '创建人',
  [MemberRoleEnum.ADMIN]: '企业管理员',
};

// 成员状态
const memberStatus: Map = {
  '-99': '全部',
  1: '正常',
  2: '已移除',
  3: '待处理邀请',
};

// 邀请状态
const inviteStatus: Map = {
  '-99': '全部',
  '-1': '已拒绝',
  0: '已邀请',
  1: '已加入',
};

// 修改个人信息
const modifyTip: Map = {
  nameSuccess: '修改用户名成功',
  emptyName: '用户名不能为空',
  unbindWechatSuccess: '解绑成功',
  logoSuccess: '修改logo成功',
  emptyContact: '企业联系人不能为空',
  invalidPhone: '手机号码格式不符合要求',
  applySuccess: '新建企业成功',
  updateSuccess: '更新企业申请提交成功',
  updateEmailSuccess: '更新邮箱成功',
  updatePhoneSuccess: '更新手机号成功',
  updateCompanySuccess: '修改企业信息成功',
  deleteMemberSuccess: '移除成员成功',
  managerEmpty: '企业管理员不能为空',
  logoTooBig: 'logo图片大小不能超过10M',
};

const companyDetailTip: Map = {
  error: '获取企业列表失败',
  searchFailed: '搜索企业成员失败',
};

// 云上版本企业设置侧边栏白名单，对应的别名
const cloudAppInfoMap: Map = {
  data_talk: {
    alias: '数据洞察',
    introImg: 'https://beacon.cdn.qq.com/beacon_data_suite/static/app-desc/dt.jpg',
  },
  beacon_olap: {
    alias: '用户行为分析',
    introImg: 'https://beacon.cdn.qq.com/beacon_data_suite/static/app-desc/di.jpg',
  },
  cdp: {
    alias: '用户画像分析',
    introImg: 'https://beacon.cdn.qq.com/beacon_data_suite/static/app-desc/cdp.jpg',
  },
  tab: {
    alias: 'A/B实验',
    introImg: 'https://beacon.cdn.qq.com/beacon_data_suite/static/app-desc/abtest.jpg',
  },
  datahub3_team: {
    alias: '数据接入治理',
    introImg: 'https://beacon.cdn.qq.com/beacon_data_suite/static/app-desc/dh.jpg',
  },
};

// 开通产品的详情
const appFeature: Map = {
  data_talk: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    logo: require('../assets/logo/datatalk.png'),
    title: 'DataTalk',
    feature: '自由灵活',
    subtitle: '的数据可视化 BI 工具',
    tagList: ['即连即用', '灵活分析与交互', '多场景覆盖'],
    detail: [
      '数据源即连即用：轻松直连多类DB（MySQL、PgSQL、ClickHouse、Druid…）和API，同时支持本地文件、腾讯文档等数据源导入。',
      '灵活的数据分析及交互：无需编码，简单拖拽即可完成多类型图表制作，配合时间面板和筛选器等与数据进行联动，让分析更加自由。',
      '多场景消费覆盖：PC、大屏、移动端，让您随时随地跟踪获取业务最新变化；更支持订阅到邮件、QQ、企业微信等多个渠道。',
    ],
    protocal: [
      {
        name: '《DataTalk服务协议》',
        url:
          'https://resource.growth.qq.com/dataauth/resource/DataTalk%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf',
      },
    ],
  },
  beacon_olap: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    logo: require('../assets/logo/datainsight.png'),
    title: 'DataInsight',
    feature: '敏捷高效',
    subtitle: '的用户行为分析工具',
    tagList: ['全场景覆盖', '实时精准'],
    detail: [
      '用户行为分析场景全面覆盖：内置事件、留存、漏斗、实验、路径、分布分析等模型，全面覆盖日常的用户行为分析场景，更支持多维度下钻和关联目标人群分析。',
      '实时精准的分析结果：实时查询，秒级响应，稳定支持亿级DAU、千亿级事件量应用。',
    ],
    protocal: [
      {
        name: '《DataInsight SDK隐私保护指引》',
        url:
          'https://resource.growth.qq.com/dataauth/resource/DataInsight%20SDK%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E6%8C%87%E5%BC%95.pdf',
      },
    ],
  },
  ab_test: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    logo: require('../assets/logo/abtest.png'),
    title: 'A/Btest',
    feature: '智能流量分配',
    subtitle: '的科学实验平台',
    tagList: ['简单易用', '敏捷实验', '科学验证', '智能洞察'],
    detail: [
      '低门槛易用的实验工具：提供面向产品、运营等非技术角色均可轻松上手的可视化实验编辑能力，大大降低实验使用门槛，提高实验执行的效率。',
      '快速实验，敏捷迭代：仅需几分钟即可完成多个实验版本的配置到上线全流程，支持实时查看实验数据，快速决策实验效果，敏捷迭代。',
      '科学实验方法赋能：基于科学的AB实验方法，支持互斥实验、正交实验管理，实现更科学的流量分配，提升流量利用率。',
      '多维数据分析，智能洞察：基于标准的假设检验方法智能判断实验优胜组，帮助业务快速决策，亦支持多维度过滤对比实验分析结果。',
    ],
    protocal: [
      {
        name: '《AB实验平台服务协议》',
        url:
          'https://resource.growth.qq.com/dataauth/resource/AB%E5%AE%9E%E9%AA%8C%E5%B9%B3%E5%8F%B0%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf',
      },
      {
        name: '《AB实验平台隐私声明》',
        url:
          'https://resource.growth.qq.com/dataauth/resource/AB%E5%AE%9E%E9%AA%8C%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E5%A3%B0%E6%98%8E.pdf',
      },
    ],
  },
  data_profile: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    logo: require('../assets/logo/dataprofile.png'),
    title: 'DataProfile',
    feature: '多方数据结合',
    subtitle: '的群体洞察分析服务',
    tagList: ['行业数据整合', '多维度标签', '快捷共享'],
    detail: [
      '公私域行业数据整合：协同生态合作伙伴，整合多场景、多行业海量用户行为数据，沉淀企业/个人常用画像洞察报告，深度解析行业人群特征。',
      '完善地标签体系：盖用户属性、触媒属性、内容偏好等多维度的标签特征，高效管理复用人群包。',
      '科学实验方法赋能：基于科学的AB实验方法，支持互斥实验、正交实验管理，实现更科学的流量分配，提升流量利用率。',
      '快捷共享报告：支持单一/对比人群实时分析，灵活便捷地共享高价值报告。',
    ],
    // protocalName: '《DataProfile 隐私保护指引》',
    // protocalUrl:
    // 'https://resource.growth.qq.com/dataauth/resource/DataInsight%20SDK%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E6%8C%87%E5%BC%95.pdf',
  },
};

export {
  MEMBER_COLUMNS,
  INVITE_COLUMNS,
  SPACE_COLUMNS,
  MEMBER_ROLE,
  memberStatus,
  inviteStatus,
  modifyTip,
  companyDetailTip,
  appFeature,
  accountTypeMap,
  cloudAppInfoMap,
};
