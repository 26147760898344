/*
 * @Author: kelvinywang
 * @Date: 2022-10-11 16:09:02
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-10-11 16:43:02
 * @Description: 通用声明
 */
export enum ResStatus {
  // 成功
  Success = 0,
  // 手机号注册成功（该手机号之前未注册）
  SuccessRegister = 1,
  // 账号不存在
  InvalidExist = -1,
  // 密码错误
  InvalidPassword = -2,
  // 手机验证码错误
  InvalidVerificationCode = -3,
  // 邮箱验证码错误
  InvalidVerificationCodeEmail = -4,
  // 手机号已注册
  InvalidPhoneExist = -5,
  // 邮箱已注册
  InvalidEmailExist = -6,
  // ------租户相关--------
  // 邀请注册成功（点击手机或邮箱邀请链接）
  InviteSuccessByEmailOrMessage = 2,
  // 邀请链接失效
  InvalidInviteLink = 3,
  // 加入邀请企业失败（到达企业成员上限）
  InviteFail = 4,
}

export const errorResText: Record<ResStatus, string> = {
  [ResStatus.Success]: '成功',
  [ResStatus.SuccessRegister]: '手机号注册成功',
  [ResStatus.InvalidExist]: '账号或密码错误',
  [ResStatus.InvalidPassword]: '账号或密码错误',
  [ResStatus.InvalidVerificationCode]: '手机验证码错误',
  [ResStatus.InvalidVerificationCodeEmail]: '邮箱验证码错误',
  [ResStatus.InvalidPhoneExist]: '手机号已注册',
  [ResStatus.InvalidEmailExist]: '邮箱已注册',
  [ResStatus.InviteSuccessByEmailOrMessage]: '邀请注册成功',
  [ResStatus.InvalidInviteLink]: '邀请链接失效',
  [ResStatus.InviteFail]: '加入邀请企业失败（到达企业成员上限）',
};
