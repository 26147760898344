/*
 * @Author: kelvinywang
 * @Date: 2022-09-13 11:24:50
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-09-29 16:31:47
 * @Description: services
 */
import { fetchWWLoginKey, fetchWxLoginKey } from './apis';
import { UserType, Ww3rdLoginParams } from './ww-3rd-login';
import { WxLoginParams } from './wx-login';
import { fetchThirdRedirectUrl } from '@/views/one-login/apis/login';
import { LoginChannelEnum } from '@/views/one-login/types';
import useRedirectUrl from '@/views/one-login/composables/use-redirect-url';
export interface LoginParams {
  appid: string;
  host: string;
}

export async function buildWw3rdLoginParams(
  loginParams: LoginParams & { el: string; thirdBindQuery: string }
): Promise<Ww3rdLoginParams> {
  const { key, redirectUrl } = await fetchThirdRedirectUrl({
    loginChannel: LoginChannelEnum.QYWX,
    dataAuthRedirectUrl: useRedirectUrl(),
    registerUrl: `${location.origin}/dataauth/register/third-bind?${loginParams.thirdBindQuery}&entrance=qywx&extraType=qywx_login`,
    needLoginParam: false,
  });

  return {
    ...loginParams,
    usertype: UserType.Member,
    redirectUrl,
    state: key,
    referer: 'https://test.beacon.woa.com',
    href:
      'https://beacon-1258344701.cos.ap-guangzhou.myqcloud.com/beacon_home_ui/sub_app/qrcode.css',
  };
}

export async function buildWxLoginParams(
  loginParams: LoginParams & { el: string; thirdBindQuery: string }
): Promise<WxLoginParams> {
  const { key, redirectUrl } = await fetchThirdRedirectUrl({
    loginChannel: LoginChannelEnum.WX,
    dataAuthRedirectUrl: useRedirectUrl(),
    registerUrl: `${location.origin}/dataauth/register/third-bind?${loginParams.thirdBindQuery}&entrance=wx&extraType=wx_login`,
    needLoginParam: false,
  });
  return {
    ...loginParams,
    selfRedirect: false,
    scope: 'snsapi_login,snsapi_userinfo',
    state: key,
    redirectUrl,
    href:
      'https://beacon-1258344701.cos.ap-guangzhou.myqcloud.com/beacon_home_ui/sub_app/qrcode.css',
  };
}
