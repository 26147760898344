/*
 * @Author: xqbzheng xqbzheng@tencent.com
 * @Date: 2022-11-17 10:52:06
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-25 14:05:28
 * @Description: 密码rsa加密服务
 *
 */
import { ref } from 'vue';
import { getPublicKey } from '@/views/one-login/apis/register';
import JsEncrypt from 'jsencrypt';
import { decode } from 'js-base64';
import beaconSDK from '@/utils/report';

const key = ref<string>('');
const publicKey = ref<string>('');
let timer: ReturnType<typeof setInterval>;
export const useEncrypt = () => {
  // 加密
  const encrypt = (v: string, thePublicKey: string = publicKey.value): string => {
    const encrypt = new JsEncrypt();
    encrypt.setPublicKey(thePublicKey);
    const res = encrypt.encrypt(v.trim()) as string;
    return res;
  };
  // 初始化公钥
  const initPublicKey = async (): Promise<void> => {
    try {
      const res = await getPublicKey();
      publicKey.value = decode(res.publicKey);
      key.value = res.key;
    } catch (e) {
      beaconSDK.sdkReport('get_public_key_error', {
        msg: JSON.stringify(e),
      });
    }
  };
  // 关闭过期检查定时器
  const closeCheckExpireInterval = (): void => {
    clearInterval(timer);
  };
  // 开启检查过期定时器
  const openCheckKeyExpiredInterVal = (): void => {
    closeCheckExpireInterval();
    timer = setInterval(() => {
      initPublicKey();
      // 20分钟刷新一次
    }, 20 * 60 * 1000);
  };
  return {
    encrypt,
    initPublicKey,
    key,
    openCheckKeyExpiredInterVal,
    closeCheckExpireInterval,
  };
};
