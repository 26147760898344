/*
 * @Author: kelvinywang
 * @Date: 2022-10-09 16:20:46
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-10-09 16:34:43
 * @Description: 统一注册页
 */
import { defineStore } from 'pinia';
import $router from '@/router';
import { RegisterChannelEnum, RegisterQuery, ReleaseEnum } from '../types';
import { useDAReleasesStore } from './common';
import { modifyRouteQuery } from '../composables/use-fake-router';

interface RegisterState {
  channel: RegisterChannelEnum;
}
const releasesStore = useDAReleasesStore();

export const useRegisterStore = defineStore('registerStore', {
  state: () => ({
    // 当前注册渠道，默认为 手机号注册
    channel: RegisterChannelEnum.PHONE,
  }),
  getters: {
    // 支持的所有注册渠道
    allChannels: () => releasesStore.releaseConfig?.registerChannelConfig,
    // 默认注册渠道
    dftChannel: () =>
      releasesStore.releaseConfig?.registerChannelConfig?.find(i => i.default)?.id ||
      releasesStore.releaseConfig?.registerChannelConfig?.[0]?.id,
    // 登录页 URL 参数
    query: (): RegisterQuery => {
      const registerQuery = ($router.currentRoute.value.query as unknown) as RegisterQuery;
      // copy
      return {
        ...registerQuery,
      };
    },
  },
  actions: {
    /**
     * 初始化注册 URL query
     * @returns
     */
    initRegisterQuery() {
      const registerQuery = ($router.currentRoute.value.query as unknown) as RegisterQuery;
      if (
        registerQuery.registerChannel &&
        this.allChannels?.some?.(i => i.id === registerQuery.registerChannel)
      ) {
        // url 上存在注册方式，赋值
        this.channel = registerQuery.registerChannel;
      } else {
        this.channel = this.dftChannel as RegisterChannelEnum;
      }
      this.selectRegisterChannel(this.channel);
      return;
    },
    /**
     * 选择注册渠道
     * @param channel
     * @returns
     */
    selectRegisterChannel(channel: RegisterChannelEnum) {
      this.channel = channel;
      return modifyRouteQuery<Partial<RegisterQuery>>({
        registerChannel: this.channel,
      });
    },
  },
});
