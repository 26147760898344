export enum RuleType {
  ROW = 'ROW',
  COL = 'COL',
  ROW_COL = 'ROW_COL',
}

export enum Operator {
  In = 'IS',
  NotIn = 'NIS',
}

export const OPERATOR_LIST = [
  { label: '是', value: Operator.In },
  { label: '不是', value: Operator.NotIn },
];
