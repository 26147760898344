/*
 * @Author: kelvinywang
 * @Date: 2022-10-10 11:09:20
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-10-11 16:53:45
 * @Description: 注册相关 API
 */
import request, { buildUrl } from './request';
import { ResStatus } from './types';

// 邮箱注册接口请求体
interface RegisterWithEmailReq {
  // 公钥key
  key: string;
  email: string;
  password: string;
  verificationCode: string;
  corpInvitationCode?: string;
  loginReqKey?: string;
  userInfoKey?: string;
}

/**
 * 邮箱注册（无需强制绑定手机）
 * - 投后公司版本接口
 * - 用于注册，或微信（企业微信）扫码后绑定邮箱
 * @param req
 * @returns
 */
export function registerWithEmail(
  req: RegisterWithEmailReq
): Promise<{
  status: ResStatus.Success | ResStatus.InvalidVerificationCodeEmail | ResStatus.InvalidEmailExist;
  ticket?: string;
}> {
  return request
    .post(buildUrl('/api/dataauth/authserver/register/v1/submitRegisterInfo', true), req, {}, true)
    .then(res => res?.result);
}

// 三方登录绑定手机号接口请求参数
export interface RegisterThirdWithPhoneReq {
  // 微信扫码后端生成的key，微信扫码后端跳转到前端注册页的时候会带回此参数，此场景下前端调注册接口时需传入此参数
  loginReqKey: string;
  // 微信用户信息key，微信扫码后端跳转到前端注册页的时候会带回此参数，此场景下前端调注册接口时需传入此参数
  userInfoKey: string;
  // 手机号
  phone: string;
  // 区号
  areaCode: string;
  // 验证码
  phoneVerificationCode: string;
}

// 三方登录绑定手机号接口响应参数
export type RegisterThirdWithPhoneRes = {
  status: ResStatus.Success | ResStatus.InvalidVerificationCode | ResStatus.InvalidPhoneExist;
  ticket?: string;
};

/**
 * 三方登录绑定手机号接口
 * @param req
 * @returns
 */
export function registerThirdWithPhone(
  req: RegisterThirdWithPhoneReq
): Promise<RegisterThirdWithPhoneRes> {
  return request
    .post(buildUrl('/api/dataauth/authserver/register/thirdBindPhone'), req, {}, true)
    .then(res => res?.result);
}

export interface RegisterWithEmailPhoneReq {
  // 公钥key
  key: string;
  // 邀请码
  corpInvitationCode?: string;
  // 邮箱
  email: string;
  // 邮箱验证码
  mailVerificationCode: string;
  // 密码
  password: string;
  // 手机号
  phone: string;
  // 区号
  areaCode: string;
  // 手机验证码
  phoneVerificationCode: string;
}

/**
 * 邮箱注册
 * - 适用于 saas 邮箱手机号注册
 * @param req
 * @returns
 */
export function registerWithEmailPhone(
  req: RegisterWithEmailPhoneReq
): Promise<{
  status:
    | ResStatus.Success
    | ResStatus.InvalidVerificationCode
    | ResStatus.InvalidVerificationCodeEmail
    | ResStatus.InvalidEmailExist
    | ResStatus.InvalidPhoneExist
    | ResStatus.InviteSuccessByEmailOrMessage;
  ticket: string;
}> {
  return request
    .post(buildUrl('/api/dataauth/authserver/register/mail'), req, {}, true)
    .then(res => res?.result);
}

export interface RegisterWithPhoneReq {
  // 公钥key
  key: string;
  // 邀请码
  corpInvitationCode?: string;
  // 密码
  password: string;
  // 手机号
  phone: string;
  // 区号
  areaCode: string;
  // 手机验证码
  phoneVerificationCode: string;
}
/**
 * 手机号注册
 * - 适用于 saas
 * @param req
 * @returns
 */
export function registerWithPhone(
  req: RegisterWithPhoneReq
): Promise<{
  status:
    | ResStatus.Success
    | ResStatus.InvalidVerificationCode
    | ResStatus.InvalidPhoneExist
    | ResStatus.InviteSuccessByEmailOrMessage
    | ResStatus.InvalidInviteLink
    | ResStatus.InviteFail;
  ticket: string;
}> {
  return request
    .post(buildUrl('/api/dataauth/authserver/register/phone'), req, {}, true)
    .then(res => res?.result);
}

interface SendCodeParam {
  receive_address: string;
  send_type: string;
}

/**
 * 投后修改密码-发送邮箱验证码
 * @param req
 */
export function sendVerificationCodeForResetPwd(req: SendCodeParam): Promise<void> {
  return request
    .post(
      buildUrl('/api/dataauth/authserver/register/ap/sendVerificationCodeForResetPwd'),
      req,
      {},
      true
    )
    .then(res => res?.result);
}

interface SubmitResetApParams {
  // 公钥的key
  key: string;
  email: string;
  password: string;
  verification_code: string;
}
/**
 * 投后修改密码-提交
 * @param req
 */
export function submitResetAp(req: SubmitResetApParams): Promise<void> {
  return request
    .post(buildUrl('/api/dataauth/authserver/login/reset/ap', true), req, {}, true)
    .then(res => res?.result);
}

interface RsaResParams {
  key: string;
  publicKey: string;
  type: string;
}
/**
 * 获取公钥
 * 返回的共钥需要base64解密
 */
export function getPublicKey(): Promise<RsaResParams> {
  return request
    .get(buildUrl('/api/dataauth/authserver/login/getPublicKey', true), {})
    .then(res => res.result);
}
