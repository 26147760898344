export interface FieldsInfo {
  name: string;
  dataTypeCategory: string;
  logicalName: string;
  physicalName: string;
  resourceType: string;
}
/*
 * @Author: henghaowu
 * @Date: 2022-09-06
 * @LastEditors: henghaowu
 * @LastEditTime: 2022-09-06
 * @Description:
 */
export enum FieldQueryType {
  Virtual = 'VIRTUAL',
  Metric = 'METRIC',
}

export enum FieldKeyType {
  string = 'STRING',
  bigint = 'BIGINT',
  double = 'DOUBLE',
  unix = 'UNIX',
  m_unix = 'M_UNIX',
  y_m_d_s = 'Y_M_D_S',
  y_m_d_s_s = 'Y_M_D_S_S',
  y_m_d = 'Y_M_D',
  y_m_d_h = 'Y_M_D_H',
  y_m_d_h_m = 'Y_M_D_H_M',
  y_m = 'Y_M',
  y = 'Y',
  ym = 'YM',
  ymd = 'YMD',
  ymdh = 'YMDH',
  ymdm = 'YMDM',
  ymds = 'YMDS',
  int_y = 'INT_Y',
  int_ym = 'INT_YM',
  int_ymd = 'INT_YMD',
  int_ymdh = 'INT_YMDH',
  int_ymdhm = 'INT_YMDHM',
  int_ymdhms = 'INT_YMDHMS',
  date = 'DATE',
  array_string = 'ARRAY_STRING',
  array_bigint = 'ARRAY_BIGINT',
  array_double = 'ARRAY_DOUBLE',
  set_string = 'SET_STRING',
  map = 'MAP',
  struct = 'STRUCT',
}

export const FieldKeyTypeTxt = {
  [FieldKeyType.string]: '字符串',
  [FieldKeyType.bigint]: '整型',
  [FieldKeyType.double]: '小数型',
  [FieldKeyType.unix]: 'Unix timestamp 秒',
  [FieldKeyType.m_unix]: 'Unix timestamp 毫秒',
  [FieldKeyType.y_m_d_s]: 'yyyy-MM-dd HH:mm:ss字符串',
  [FieldKeyType.y_m_d_s_s]: 'yyyy-MM-dd HH:mm:ss.SSS字符串',
  [FieldKeyType.y_m_d]: 'yyyy-MM-dd字符串',
  [FieldKeyType.y_m_d_h]: 'yyyy-MM-dd HH字符串',
  [FieldKeyType.y_m_d_h_m]: 'yyyy-MM-dd HH:mm字符串',
  [FieldKeyType.y_m]: 'yyyy-MM字符串',
  [FieldKeyType.y]: 'yyyy字符串',
  [FieldKeyType.ym]: 'yyyyMM字符串',
  [FieldKeyType.ymd]: 'yyyyMMdd字符串',
  [FieldKeyType.ymdh]: 'yyyyMMddHH字符串',
  [FieldKeyType.ymdm]: 'yyyyMMddHHMM字符串',
  [FieldKeyType.ymds]: 'yyyyMMddHHmmss字符串',
  [FieldKeyType.int_y]: 'yyyy整型',
  [FieldKeyType.int_ym]: 'yyyyMM整型',
  [FieldKeyType.int_ymd]: 'yyyyMMdd整型',
  [FieldKeyType.int_ymdh]: 'yyyyMMddHH整型',
  [FieldKeyType.int_ymdhm]: 'yyyyMMddHHmm整型',
  [FieldKeyType.int_ymdhms]: 'yyyyMMddHHmmss整型',
  [FieldKeyType.date]: 'date类型',
  [FieldKeyType.array_string]: '字符串数组',
  [FieldKeyType.array_double]: '小数型数组',
  [FieldKeyType.array_bigint]: '整数型数组',
  [FieldKeyType.set_string]: '去重数组',
  [FieldKeyType.map]: '键值对',
  [FieldKeyType.struct]: '结构体',
};

// 参数描述 字段类型 下拉选择
export const KET_TYPE_OPTION = [
  { value: FieldKeyType.string, label: FieldKeyTypeTxt[FieldKeyType.string] },
  {
    label: '数值',
    children: [
      { value: FieldKeyType.bigint, label: FieldKeyTypeTxt[FieldKeyType.bigint] },
      { value: FieldKeyType.double, label: FieldKeyTypeTxt[FieldKeyType.double] },
    ],
  },
  {
    label: '日期',
    children: [
      { value: FieldKeyType.unix, label: FieldKeyTypeTxt[FieldKeyType.unix] },
      { value: FieldKeyType.m_unix, label: FieldKeyTypeTxt[FieldKeyType.m_unix] },
      { value: FieldKeyType.y_m_d_s, label: FieldKeyTypeTxt[FieldKeyType.y_m_d_s] },
      { value: FieldKeyType.y_m_d_s_s, label: FieldKeyTypeTxt[FieldKeyType.y_m_d_s_s] },
      { value: FieldKeyType.y_m_d, label: FieldKeyTypeTxt[FieldKeyType.y_m_d] },
      { value: FieldKeyType.y_m_d_h, label: FieldKeyTypeTxt[FieldKeyType.y_m_d_h] },
      { value: FieldKeyType.y_m_d_h_m, label: FieldKeyTypeTxt[FieldKeyType.y_m_d_h_m] },
      { value: FieldKeyType.y_m, label: FieldKeyTypeTxt[FieldKeyType.y_m] },
      { value: FieldKeyType.y, label: FieldKeyTypeTxt[FieldKeyType.y] },
      { value: FieldKeyType.ym, label: FieldKeyTypeTxt[FieldKeyType.ym] },
      { value: FieldKeyType.ymd, label: FieldKeyTypeTxt[FieldKeyType.ymd] },
      { value: FieldKeyType.ymdh, label: FieldKeyTypeTxt[FieldKeyType.ymdh] },
      { value: FieldKeyType.ymdm, label: FieldKeyTypeTxt[FieldKeyType.ymdm] },
      { value: FieldKeyType.ymds, label: FieldKeyTypeTxt[FieldKeyType.ymds] },
      { value: FieldKeyType.ymd, label: FieldKeyTypeTxt[FieldKeyType.ymd] },
      { value: FieldKeyType.ymds, label: FieldKeyTypeTxt[FieldKeyType.ymds] },
      { value: FieldKeyType.int_y, label: FieldKeyTypeTxt[FieldKeyType.int_y] },
      { value: FieldKeyType.int_ym, label: FieldKeyTypeTxt[FieldKeyType.int_ym] },
      { value: FieldKeyType.int_ymd, label: FieldKeyTypeTxt[FieldKeyType.int_ymd] },
      { value: FieldKeyType.int_ymdh, label: FieldKeyTypeTxt[FieldKeyType.int_ymdh] },
      { value: FieldKeyType.int_ymdhm, label: FieldKeyTypeTxt[FieldKeyType.int_ymdhm] },
      { value: FieldKeyType.int_ymdhms, label: FieldKeyTypeTxt[FieldKeyType.int_ymdhms] },
      { value: FieldKeyType.date, label: FieldKeyTypeTxt[FieldKeyType.date] },
    ],
  },
  // 暂时隐藏
  // {
  //   label: '数组',
  //   children: [
  //     { value: FieldKeyType.array_string, label: FieldKeyTypeTxt[FieldKeyType.array_string] },
  //     { value: FieldKeyType.array_bigint, label: FieldKeyTypeTxt[FieldKeyType.array_bigint] },
  //     { value: FieldKeyType.array_double, label: FieldKeyTypeTxt[FieldKeyType.array_double] },
  //     { value: FieldKeyType.set_string, label: FieldKeyTypeTxt[FieldKeyType.set_string] },
  //   ],
  // },
  // { value: FieldKeyType.map, label: FieldKeyTypeTxt[FieldKeyType.map] },
  // { value: FieldKeyType.struct, label: FieldKeyTypeTxt[FieldKeyType.struct] },
];

export const LETTER_MARKER = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export enum FieldModel {
  DSL = 'DSL',
  SQL = 'SQL',
}

export enum FieldValueType {
  LITERAL = 'LITERAL',
  FIELD = 'FIELD',
}

export const FIELD_VALUE_TYPES = [
  { value: FieldValueType.LITERAL, label: '固定值' },
  { value: FieldValueType.FIELD, label: '其他字段' },
];

// 字段默认过滤值
export const FIELD_CONDITION_DF = {
  compareField: '', // 过滤所选的字段值，只有聚合指标才有
  valueType: FieldValueType.LITERAL, // 过滤值类型，固定值或其它字段
  compareValue: '', // 固定值或其它字段的值
  valueSeparator: ',', // 固定值或其它字段的分隔符
  relationOperator: '', // 操作符
  alias: '', // 取值结果，只有虚拟字段才有
  dataTypeCategory: '', // 字段类型，聚合指标回显需要用到
  compareFieldDataType: undefined,
};

export enum CompareOperator {
  EQ = 'EQ', // = 相等
  GE = 'GE', // >= 大于等于
  LT = 'LT', // < 小于
  LE = 'LE', // <= 小于等于
  NE = 'NE', // != 不相等
  GT = 'GT', // > 大于

  IS_NULL = 'IS_NULL', // -null 为空
  NON_NULL = 'NON_NULL', // -nnll 不为空
  IS_BLANK = 'IS_BLANK', // -blank 空白
  NON_BLANK = 'NON_BLANK', // -nblank 不为空白

  BETWEEN = 'BETWEEN', // -btn  在区间
  NOT_BETWEEN = 'NOT_BETWEEN', // -nbtn 不在区间
  IN = 'IN', // -is   是
  NOT_IN = 'NOT_IN', // -nis  不是

  START_WITH = 'START_WITH', // -slike 开头包含
  END_WITH = 'END_WITH', // -elike 结尾包含
  LIKE = 'LIKE', // -like  包含
  NOT_LIKE = 'NOT_LIKE', // -nlike 不包含
  REG = 'REG', // -reg   正则表达式

  FT = 'FT', // 绝对时间
  RT = 'RT', // 相对时间
}

export enum FieldSimpleType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATETIME = 'DATETIME',
}

export const FIELD_TYPE_DESC_OBJ = {
  [FieldSimpleType.STRING]: '字符串',
  [FieldSimpleType.NUMBER]: '数值',
  [FieldSimpleType.DATETIME]: '时间',
};

export const OPERATORS_OPTIONS = [
  { value: CompareOperator.EQ, label: '等于', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.NE, label: '不等于', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.GT, label: '大于', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.GE, label: '大于等于', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.LT, label: '小于', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.LE, label: '小于等于', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.BETWEEN, label: '在区间', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.NON_NULL, label: '有值', type: FieldSimpleType.NUMBER },
  { value: CompareOperator.IS_NULL, label: '无值', type: FieldSimpleType.NUMBER },

  { value: CompareOperator.START_WITH, label: '开头匹配', type: FieldSimpleType.STRING },
  { value: CompareOperator.END_WITH, label: '结尾匹配', type: FieldSimpleType.STRING },
  { value: CompareOperator.IN, label: '是', type: FieldSimpleType.STRING },
  { value: CompareOperator.NOT_IN, label: '不是', type: FieldSimpleType.STRING },
  { value: CompareOperator.LIKE, label: '匹配', type: FieldSimpleType.STRING },
  { value: CompareOperator.NOT_LIKE, label: '不匹配', type: FieldSimpleType.STRING },
  { value: CompareOperator.NON_NULL, label: '有值', type: FieldSimpleType.STRING },
  { value: CompareOperator.IS_NULL, label: '无值', type: FieldSimpleType.STRING },
  { value: CompareOperator.IS_BLANK, label: '为空', type: FieldSimpleType.STRING },
  { value: CompareOperator.NON_BLANK, label: '不为空', type: FieldSimpleType.STRING },
  { value: CompareOperator.REG, label: '正则表达式', type: FieldSimpleType.STRING },

  { value: CompareOperator.FT, label: '绝对时间', type: FieldSimpleType.DATETIME },
  { value: CompareOperator.RT, label: '相对时间', type: FieldSimpleType.DATETIME },
];

export const NO_VALUE_OPERATORS = [
  CompareOperator.IS_NULL,
  CompareOperator.NON_NULL,
  CompareOperator.IS_BLANK,
  CompareOperator.NON_BLANK,
];

export enum LogicDictValue {
  COUNT = 'COUNT',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
  AVG = 'AVG',
  APPROX_PERCENTILE = 'APPROX_PERCENTILE',
}
export const LOGIC_OPTIONS = [
  { value: LogicDictValue.COUNT, label: '计数' },
  { value: LogicDictValue.COUNT_DISTINCT, label: '去重计数' },
  { value: LogicDictValue.MAX, label: '最大值' },
  { value: LogicDictValue.MIN, label: '最小值' },
  { value: LogicDictValue.SUM, label: '求和' },
  { value: LogicDictValue.AVG, label: '平均值' },
  {
    value: LogicDictValue.APPROX_PERCENTILE,
    label: '百分位值',
    children: [
      { value: '1', label: '第1分位' },
      { value: '5', label: '第5分位' },
      { value: '10', label: '第10分位' },
      { value: '25', label: '第25分位' },
      { value: '50', label: '第50分位' },
      { value: '75', label: '第75分位' },
      { value: '90', label: '第90分位' },
      { value: '95', label: '第95分位' },
      { value: '99', label: '第99分位' },
    ],
  },
];

export const DEFAULT_PAGER = { pageNo: 1, pageSize: 10 };

export const DEFAULT_BTNS = [
  { key: 'edit', title: '编辑' },
  { key: 'delete', title: '删除', type: 'danger' },
];
export const DEFAULT_CONFIRM = {
  title: '请确定是否删除',
  width: 384,
  buttonType: 'danger',
  buttonText: '删除',
};

export enum DictTypeValue {
  NONE = 'NONE',
  MEMORY_MAP = 'MEMORY_MAP',
  DICT_TABLE = 'DICT_TABLE',
}

// 业务意义
export enum FieldScene {
  DIMENSION = 'DIMENSION',
  METRIC = 'METRIC',
  TIMESTAMP = 'TIMESTAMP',
}

export interface ResourceField {
  physicalName: string;
  physicalType: string;
  logicalName: string;
  name: string;
  dataType: FieldKeyType;
  fieldScene: FieldScene;
}
