/*
 * @Author: kelvinywang
 * @Date: 2022-09-09 17:07:21
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-09-29 16:43:43
 * @Description:
 */

export const setLoginIframeAttrs = function(iframeEl: HTMLElement): void {
  const wxIframeAttrs = {
    frameborder: '0',
    width: '100%',
    height: '280px',
    scrolling: 'no',
    allowTransparency: 'true',
    sandbox: 'allow-scripts allow-top-navigation allow-same-origin',
  };
  Object.entries(wxIframeAttrs).forEach(([attr, value]) => {
    iframeEl.setAttribute(attr, value);
  });
};
