/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-10-11 15:03:18
 * @Descripttion:
 */
import { StateType } from '@/@types';
import { Module } from 'vuex';
import { defaultAvatar } from '@/config/constant';

const state = {
  token: {
    access_token: '',
  },
  isLogin: false,
  userDetail: {
    id: '',
    name: '',
    email: '',
    phone: '',
    login_name: '',
    isAdmin: false,
    avatar: defaultAvatar,
    wechat: {},
    wework: {},
  },
};

type UserStateType = typeof state;

const user: Module<UserStateType, StateType> = {
  namespaced: true,
  ...state,
};

export { UserStateType, state };

export default user;
