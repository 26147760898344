/*
 * @Author: beenlezhang
 * @Date: 2020-12-25 14:23:04
 * @LastEditors: yanhongchen
 * @LastEditTime: 2023-03-15 17:21:04
 * @Descripttion: router
 */
import { createRouter, createWebHistory, RouteRecordRaw, RouterHistory, Router } from 'vue-router';
import { reportPv } from '@/views/one-login/composables/use-report';

const routeList: Array<RouteRecordRaw> = [
  {
    path: '/authority/:biz_url',
    name: 'Authority',
    component: () => import(/* webpackChunkName: "authority" */ '@/views/AuthorityNew/index.vue'),
    meta: { header: false, nav: true, education: false, bizlist: true, company: true },
    children: [
      {
        path: '', // 默认权限首页-我的申请列表
        name: 'AuthDefault',
        component: () =>
          import(/* webpackChunkName: "authlist" */ '@/views/AuthorityNew/myAuthList.vue'),
      },
      {
        // 我的审批列表
        path: 'myApproveList/:project',
        name: 'ApproveList',
        component: () =>
          import(/* webpackChunkName: "approvelist" */ '@/views/AuthorityNew/myApproveList.vue'),
      },
      {
        // 我的申请列表
        path: 'myApplyList/:project',
        name: 'ApplyList',
        component: () =>
          import(/* webpackChunkName: "applylist" */ '@/views/AuthorityNew/myApplyList.vue'),
      },
      {
        // 我有权限的列表
        path: 'myAuthList/:project',
        name: 'myAuthList',
        component: () =>
          import(/* webpackChunkName: "authlist" */ '@/views/AuthorityNew/myAuthList.vue'),
      },
      {
        // 角色组管理
        path: 'authRoleGroup/:project',
        name: 'AuthRoleGroup',
        component: () =>
          import(/* webpackChunkName: "authRoleGroup" */ '@/views/AuthorityNew/authRoleGroup.vue'),
      },
      {
        path: 'authRoleGroup/:project/:roleId', // 查看指定角色组
        name: 'AuthRoleGroupFix',
        component: () =>
          import(
            /* webpackChunkName: "authRoleGroupFix" */ '@/views/AuthorityNew/authRoleGroup.vue'
          ),
      },
      {
        // 空间成员管理
        path: 'bizMemberManager/:project',
        name: 'BizMemberManager',
        component: () =>
          import(
            /* webpackChunkName: "bizMemberManager" */ '@/views/AuthorityNew/bizMemberManager.vue'
          ),
      },
      {
        // 权限审批流程设置
        path: 'authProcess/:project',
        name: 'AuthProcess',
        component: () =>
          import(/* webpackChunkName: "authProcess" */ '@/views/AuthorityNew/authProcess.vue'),
      },
      {
        // 权限审批流程设置
        path: 'dataGrant/:project',
        name: 'DataGrant',
        component: () =>
          import(/* webpackChunkName: "dataGrant" */ '@/views/AuthorityNew/dataGrant.vue'),
      },
      {
        // 行列权限
        path: 'rowColGrant/:project',
        name: 'RowColGrant',
        component: () =>
          import(
            /* webpackChunkName: "rowColGrant" */ '@/views/AuthorityNew/RowColGrant/index.vue'
          ),
      },
      {
        // 权限数据下载
        path: 'authDownload/:project',
        name: 'AuthDownload',
        component: () =>
          import(/* webpackChunkName: "authDownload" */ '@/views/AuthorityNew/authDownload.vue'),
      },
      {
        // 推送下载
        path: 'downloadPush/:project',
        name: 'DownloadPush',
        component: () =>
          import(/* webpackChunkName: "downloadPush" */ '@/views/AuthorityNew/downloadPush.vue'),
      },
      {
        // approveDetail
        // 权限审批流程设置
        path: 'approveDetail/:applyId',
        name: 'ApproveDetail',
        component: () =>
          import(/* webpackChunkName: "approveDetail" */ '@/views/AuthorityNew/approveDetail.vue'),
      },
      {
        name: 'MyDataAuthList',
        path: 'myDataAuthList/:project/:userId/:userName', // 数据资产权限管理
        component: () =>
          import(
            /* webpackChunkName: "myDataAuthList" */ '@/views/AuthorityNew/myDataAuthList.vue'
          ),
      },
      // tob
      {
        // 我的审批列表
        path: 'approvelist/:project',
        name: 'TobApproveList',
        component: () =>
          import(/* webpackChunkName: "approvelist" */ '@/views/AuthorityNew/myApproveList.vue'),
      },
      {
        // 我的申请列表
        path: 'applylist/:project',
        name: 'TobApplyList',
        component: () =>
          import(/* webpackChunkName: "applylist" */ '@/views/AuthorityNew/myApplyList.vue'),
      },
      {
        // 角色组管理
        path: 'authrole/:project',
        name: 'TobAuthRoleGroup',
        component: () =>
          import(/* webpackChunkName: "authRoleGroup" */ '@/views/AuthorityNew/authRoleGroup.vue'),
      },
      {
        // 权限审批流程设置
        path: 'authprocess/:project',
        name: 'TobAuthProcess',
        component: () =>
          import(/* webpackChunkName: "authProcess" */ '@/views/AuthorityNew/authProcess.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login/index.vue'),
    meta: { header: false, nav: true, education: true },
  },
  {
    path: '/register',
    name: 'TobRegister',
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register/index.vue'),
    meta: { header: false, nav: true, education: true },
  },
  {
    path: '/wxlogin',
    name: 'Wxlogin',
    component: () => import(/* webpackChunkName: "wxlogin" */ '@/views/Wxlogin/index.vue'),
    meta: { header: false, nav: true, education: true },
  },
  {
    path: '/account/home',
    name: 'TobAccountHome',
    component: () => import(/* webpackChunkName: "accountHome" */ '@/views/Account/Home.vue'),
    meta: { header: false, nav: true, education: false, company: true },
  },
  {
    path: '/account/bind',
    name: 'BindAccount',
    component: () => import(/* webpackChunkName: "bindaccount" */ '@/views/BindAccount/index.vue'),
    meta: { header: false, nav: true, education: true },
  },
  {
    path: '/account/find',
    name: 'FindAccount',
    component: () => import(/* webpackChunkName: "findaccount" */ '@/views/FindAccount/index.vue'),
    meta: { header: false, nav: true, education: true },
  },
  {
    path: '/company/list',
    name: 'TobCompanyList',
    component: () => import(/* webpackChunkName: "company" */ '@/views/Company/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/company/list/mobile',
    name: 'CompanyMobile',
    component: () =>
      import(/* webpackChunkName: "companyMobile" */ '@/views/Mobile/Company/index.vue'),
    meta: { header: false, nav: false },
  },
  {
    path: '/company/:company_id',
    name: 'CompanyDefault',
    component: () => import(/* webpackChunkName: "companyHome" */ '@/views/Company/Home/index.vue'),
    meta: { header: false, nav: true, company: true },
    children: [
      {
        path: 'basic',
        name: 'Basic',
        component: () =>
          import(/* webpackChunkName: "BasicInfo" */ '@/views/Company/Home/basic.vue'),
      },
      {
        path: 'app/:appKey',
        name: 'App',
        component: () => import(/* webpackChunkName: "App" */ '@/views/Company/Home/app.vue'),
      },
      {
        path: 'business',
        name: 'Business',
        component: () =>
          import(/* webpackChunkName: "Business" */ '@/views/Company/Home/business.vue'),
      },
      {
        path: 'resource/:type',
        name: 'Resource',
        component: () => import(/* webpackChunkName: "Space" */ '@/views/Company/Home/staff.vue'),
      },
    ],
  },
  {
    path: '/spaceManage/:company_id/:biz_url',
    name: 'SpaceManage',
    component: () =>
      import(/* webpackChunkName: "SpaceManage" */ '@/views/Company/Space/index.vue'),
    meta: { header: false, nav: true, company: true },
    children: [
      {
        path: 'basic/:project?',
        name: 'SpaceBasic',
        component: () =>
          import(/* webpackChunkName: "SpaceBasic" */ '@/views/Company/Space/basic.vue'),
      },
      {
        path: 'info/:project?',
        name: 'SpaceInfo',
        component: () =>
          import(/* webpackChunkName: "SpaceInfo" */ '@/views/Company/Space/info.vue'),
      },
      {
        path: 'product/:project?',
        name: 'SpaceProduct',
        component: () =>
          import(/* webpackChunkName: "SpaceProduct" */ '@/views/Company/Space/product.vue'),
      },
    ],
  },
  {
    path: '/company/new',
    name: 'TobCompanyNew',
    component: () => import(/* webpackChunkName: "companyNew" */ '@/views/Company/New/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/company/modify',
    name: 'CompanyModify',
    component: () =>
      import(/* webpackChunkName: "companyModify" */ '@/views/Company/Modify/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/spacelist',
    name: 'SpaceList',
    component: () => import(/* webpackChunkName: "spaceList" */ '@/views/SpaceList/index.vue'),
    meta: { header: false, nav: true, company: true },
  },
  {
    path: '/growth',
    name: 'GrowthHome',
    component: () => import(/* webpackChunkName: "company" */ '@/views/Company/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/connect/oauth',
    name: 'Oauth',
    component: () => import(/* webpackChunkName: "oauth" */ '@/views/Oauth/index.vue'),
    meta: { header: false, nav: false },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "invite" */ '@/views/Invite/index.vue'),
    meta: { header: false, nav: false },
  },
  {
    path: '/registerBeacon',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register/index.vue'),
    meta: { header: false, nav: true, education: true },
  },
  {
    path: '/groupList',
    name: 'CompanyList',
    component: () => import(/* webpackChunkName: "company" */ '@/views/Company/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/addGroup',
    name: 'CompanyNew',
    component: () => import(/* webpackChunkName: "companyNew" */ '@/views/Company/New/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/userInfoManage',
    name: 'AccountHome',
    component: () => import(/* webpackChunkName: "accountHome" */ '@/views/Account/Home.vue'),
    meta: { header: false, nav: true, education: false, company: true },
  },
  {
    path: '/console',
    name: 'AppList',
    component: () => import(/* webpackChunkName: "applist" */ '@/views/AppList/index.vue'),
    meta: { header: false, nav: true, company: true },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "company" */ '@/views/Company/index.vue'),
    meta: { header: false, nav: true },
  },
  {
    path: '/login',
    name: 'OneLogin',
    component: () => import('@/views/one-login'),
    meta: { header: false, nav: false, education: false },
    children: [
      {
        path: 'password-forget',
        name: 'OnePasswordForget',
        component: () => import('@/views/one-login'),
      },
      {
        path: 'password-forget',
        name: 'OnePasswordForget',
        component: () => import('@/views/one-login'),
      },
    ],
  },
  {
    path: '/invite',
    name: 'OneInvite',
    component: () => import('@/views/one-login'),
    meta: { header: false, nav: false, education: false },
    children: [
      {
        path: 'link',
        name: 'OneInviteLink',
        component: () => import('@/views/one-login'),
      },
      {
        path: 'result',
        name: 'OneInviteResult',
        component: () => import('@/views/one-login'),
      },
    ],
  },
  {
    path: '/register',
    name: 'OneRegister',
    component: () => import('@/views/one-login'),
    meta: { header: false, nav: false, education: false },
    children: [
      {
        path: 'third-bind',
        name: 'OneThirdPhoneRegister',
        component: () => import('@/views/one-login'),
      },
      {
        path: 'email-bind-phone',
        name: 'EmailBindPhone',
        component: () => import('@/views/one-login'),
      },
      {
        path: 'success',
        name: 'OneRegistSuccess',
        component: () => import('@/views/one-login'),
      },
    ],
  },
];

const routes = routeList.filter(item => {
  if (item.children?.length) {
    item.children = item.children.filter(child => {
      return window.AppConfig.routes.includes(child.name);
    });
  }
  return window.AppConfig.routes.includes(item.name);
});

const router = createRouter({
  history: createWebHistory(window.__MICRO_APP_BASE_ROUTE__ || '/'),
  routes,
});

router.afterEach((to, from) => {
  reportPv();
});

export default router;
