/*
 * @Author: beenlezhang
 * @Date: 2020-12-25 14:23:04
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-04 18:21:29
 * @Descripttion:
 */
import './public-path';
import { createApp, App as AppInstance } from 'vue';
import { createPinia, Pinia } from 'pinia';
import router from './router';
import App from './App.vue';
import store from './store';
import ElementPlus from 'element-plus';
import * as Icons from '@element-plus/icons-vue';
import BeaconPlugin from '@tencent/beacon-plugin';
import { microAppNavigation, microAppEnvEval, injectMetas } from '@/utils/micrpApp';
import '@/styles/index.less';
import '@/styles/element-plus.css';
import 'vant/lib/index.css';
import '@/permission';

let app: AppInstance | null = null;
let pinia: Pinia | null = null;

// vue实例渲染方法
function render(): void {
  // 初始化插件sdk
  BeaconPlugin.init({ host: '' });
  app = createApp(App);
  // 注册Icons 全局组件
  Object.keys(Icons).forEach(key => {
    app?.component(key, Icons[key as keyof typeof Icons]);
  });
  pinia = createPinia();
  app
    .use(ElementPlus)
    .use(router)
    .use(pinia)
    .use(store);
  app.mount('#app');
}
// micro app挂载方法
function mount(): void {
  render();
  microAppEnvEval(globalAPI => {
    // 监听基座登录信息，user?.userId 为 true 则证明已经登录，为false则为未登录
    globalAPI?.user?.onStateChange?.((userState: any) => {
      const status = userState?.user?.userId ? 'afterLogin' : 'beforeLogin';
      microAppNavigation(status);
      const { corpId } = userState;
      if (status === 'afterLogin') {
        store.dispatch('user/setUserId', {
          userId: userState?.user?.userId,
        });
      }
      if (corpId) {
        store.dispatch('company/getBeaconPlugin', {
          corporation_id: corpId,
        });
      }
    });
    injectMetas();
  });
}

// micro app卸载方法
function unmount(): void {
  app?.unmount();
  pinia = null;
  app = null;
}

// 微前端环境下，注册mount和unmount方法
if (window.__MICRO_APP_ENVIRONMENT__) {
  // eslint-disable-next-line
  // @ts-ignore
  window[`micro-app-${window.__MICRO_APP_NAME__}`] = { mount, unmount };
} else {
  // 非微前端环境直接渲染
  mount();
}
