/*
 * @Author: beenlezhang
 * @Date: 2021-02-23 14:46:01
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-11-10 14:48:17
 * @Descripttion: utils
 */

import defaultAvatar from '@/assets/default-avatar.png';

export const getCookie = (name: string): string => {
  let arr;
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  if ((arr = document.cookie.match(reg))) {
    return unescape(arr[2]);
  }
  return '';
};

export const debounce = (fn: any, delay: number) => {
  let timer: number | undefined;
  return function(this: any, ...args: any) {
    const context = this; // 取debounce执行作用域的this
    if (timer) {
      clearTimeout(timer);
    }
    timer = window.setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
};

export const getQueryString = (name: string): string => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r) {
    return unescape(r[2]);
  }
  return '';
};

// 是否是移动端
export function isMobile(): boolean {
  return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
}

export function isWechatMiniApp(): boolean {
  return (
    window.__wxjs_environment === 'miniprogram' &&
    /micromessenger/i.test(navigator.userAgent.toLowerCase())
  );
}

export function getDeviceWidth() {
  return document.documentElement.clientWidth;
}

export const getAvatar = (item: any): string => {
  return item.avatar ? item.avatar : defaultAvatar;
};

export const avatarOnError = (e: any) => {
  const img = e.srcElement;
  img.src = defaultAvatar;
  img.onerror = null; // 防止闪图
};
