/*
 * @Author: kelvinywang
 * @Date: 2022-10-09 10:33:10
 * @LastEditors: kelvinywang kelvinywang@tencent.com
 * @LastEditTime: 2022-10-16 14:10:03
 * @Description: 登录页 store
 */
import { defineStore } from 'pinia';
import $router from '@/router';
import { modifyRouteQuery } from '../composables/use-fake-router';
import {
  LoginChannelEnum,
  LoginEntryEnum,
  LoginQuery,
  RegisterChannelEnum,
  ReleaseEnum,
} from '../types';
import { useDAReleasesStore } from './common';
import useRedirectUrl from '@/views/one-login/composables/use-redirect-url';
import beaconSDK from '@/utils/report';
// const useRedirect = () => {}
interface LoginState {
  channel: LoginChannelEnum;
  pageEntry: LoginEntryEnum;
}

// 根据登录渠道判断登录页面入口
const judgeLoginEntry = (channel: LoginChannelEnum): LoginEntryEnum =>
  [LoginChannelEnum.WX, LoginChannelEnum.QYWX].includes(channel)
    ? LoginEntryEnum.QRCODE
    : LoginEntryEnum.OTHERS;

// 根据登录页面入口判断渠道
const judgeLoginChannel = (entry: LoginEntryEnum): LoginChannelEnum => {
  return entry === LoginEntryEnum.QRCODE ? LoginChannelEnum.WX : LoginChannelEnum.AP;
};

// 外网独立版跳转需要携带标识
const useSelfServerHost = () => {
  if (location.search.includes('fromSelfDomain')) return '&fromSelfDomain=1';
  return '';
};

const releasesStore = useDAReleasesStore();

export const useLoginStore = defineStore('loginStore', {
  state: (): LoginState => ({
    // 当前登录渠道
    channel: LoginChannelEnum.PHONE_SMS,
    // 登录页入口 区分
    // 1.扫码登录
    // 2.验证码+密码
    pageEntry: LoginEntryEnum.OTHERS,
  }),
  getters: {
    // 支持的所有登录渠道
    allChannels: () => releasesStore.releaseConfig?.loginChannelConfig,
    // 登录页 URL 参数
    query: (state): LoginQuery => {
      const loignQuery = ($router.currentRoute.value.query as unknown) as LoginQuery;
      return {
        ...loignQuery,
        loginChannel: state.channel,
      };
    },
    thirdBindQuery: (): string => {
      if (releasesStore.release === ReleaseEnum.EXTERNAL) {
        return `registerChannel=${RegisterChannelEnum.EMAIL}&release=${
          releasesStore.release
        }&dataAuthRedirectUrl=https://${useRedirectUrl()}${useSelfServerHost()}`;
      }
      return `registerChannel=${RegisterChannelEnum.PHONE}&release=${
        releasesStore.release
      }&dataAuthRedirectUrl=https://${useRedirectUrl()}`;
    },
  },
  actions: {
    /**
     * 初始化登录参数
     */
    async initLoginQuery() {
      const loignQuery = ($router.currentRoute.value.query as unknown) as LoginQuery;
      if (
        loignQuery.loginChannel &&
        this.allChannels?.some(channel => channel.id === loignQuery.loginChannel)
      ) {
        // url 上存在登录方式，赋值
        this.channel = loignQuery.loginChannel;
      } else {
        this.channel = this.allChannels?.[0].id as LoginChannelEnum;
      }
      beaconSDK.sdkReport('show_login_page', {
        login_channel: this.channel?.toLowerCase(),
        login_register_version: releasesStore.release?.toLowerCase(),
        entrance: $router.currentRoute.value.query.entrance,
      });
      this.selectLoginChannel(this.channel);
      this.pageEntry = judgeLoginEntry(this.channel);
      return;
    },
    /**
     * 选择登录方式
     * @param channelId
     */
    selectLoginChannel(channelId: LoginChannelEnum) {
      this.channel = channelId;
      return modifyRouteQuery<Partial<LoginQuery>>({
        loginChannel: this.channel,
      });
    },
    selectLoginPageEntry(entry: LoginEntryEnum) {
      this.pageEntry = entry;
      this.selectLoginChannel(judgeLoginChannel(entry));
    },
  },
});
