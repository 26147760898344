import type { FormItemRule } from 'element-plus';

export function checkDisplayName(
  msg = '只允许为中英文括号、#、_、/、-、中文或字母或数字',
  pattern = /^[\u4e00-\u9fa5#a-zA-Z0-9_/\-/(/)/（/）]*$/,
): FormItemRule {
  return {
    trigger: ['change', 'blur'],
    validator(_rule, value, callback) {
      if (pattern.test(value)) {
        callback();
      } else {
        callback(new Error(msg));
      }
    },
  };
}

export function checkTableId(msg = '只允许数字、字母、下划线', pattern = /^[_$a-zA-Z][_$a-zA-Z0-9]*$/): FormItemRule {
  return {
    trigger: ['change', 'blur'],
    validator(_rule, value, callback) {
      if (pattern.test(value)) {
        callback();
      } else {
        callback(new Error(msg));
      }
    },
  };
}

export function checkRowFieldKey(msg = '只允许数字、字母、下划线、英文逗号', pattern = /^[_,$a-zA-Z][_,$a-zA-Z0-9]*$/): FormItemRule {
  return {
    trigger: ['change', 'blur'],
    validator(_rule, value, callback) {
      if (pattern.test(value)) {
        callback();
      } else {
        callback(new Error(msg));
      }
    },
  };
}

export function checkEmpty(msg = '不能为空', trigger?: string[]): FormItemRule {
  return {
    trigger: trigger ?? ['change', 'blur'],
    validator(_rule, value, callback) {
      if (typeof value === 'number') {
        return callback();
      }
      if (typeof value === 'boolean') {
        return callback();
      }
      if (value?.length) {
        return callback();
      }
      callback(new Error(msg));
    },
  };
}

/**
 * 检查输入长度限制
 */
export function checkLengthLimit(limit = 255, msg = '输入长度超过限制'): FormItemRule {
  return {
    trigger: ['change', 'blur'],
    validator(_rule, value, callback) {
      if (value?.length > limit) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },
  };
}
