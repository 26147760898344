/*
 * @Author: kelvinywang
 * @Date: 2022-10-08 17:03:49
 * @LastEditors: henghaowu henghaowu@tencent.com
 * @LastEditTime: 2022-11-30 17:35:20
 * @Description: 统一登录注册页相关 声明
 */

import { LocationQueryRaw } from 'vue-router';
import { ResStatus } from '@/views/one-login/apis/types';

// 版本枚举
export enum ReleaseEnum {
  DEFAULT = 'DEFAULT',
  // 投后阅文外网版
  EXTERNAL = 'EXTERNAL',
  // 内网
  INNER = 'INNER',
  PRIVATE = 'PRIVATE',
  OVERSEAS = 'OVERSEAS',
}

// 页面登录入口类型
export enum LoginEntryEnum {
  // 二维码
  QRCODE = 'QRCODE',
  // 其他，包含邮箱+手机
  OTHERS = 'OTHERS',
}

// 登录方式枚举
export enum LoginChannelEnum {
  // 微信扫码
  WX = 'WX',
  // 企业微信扫码
  QYWX = 'QYWX',
  // 免密登录（手机号/验证码登录）
  PHONE_SMS = 'PHONE_SMS',
  // 邮箱账号密码登录
  AP = 'AP',
  // 手机账号密码登录
  PHONE_AP = 'PHONE_AP',
  // 内网 IOA 登录
  IOA = 'IOA',
}

// 注册方式枚举
export enum RegisterChannelEnum {
  // 手机号注册
  PHONE = 'PHONE',
  // 邮箱注册，邮箱注册完必须再绑定手机号
  EMAIL_PHONE = 'EMAIL_PHONE',
  // 邮箱注册，邮箱注册完无需绑定手机号
  EMAIL = 'EMAIL',
}

export interface BaseQuery extends LocationQueryRaw {
  // 登录版本
  release: ReleaseEnum;
  // 登录后 重定向页面，需要通过 encodeURIComponent 编码
  dataAuthRedirectUrl?: string;
}

// 登录页 URL 参数
export interface LoginQuery extends BaseQuery {
  // 登录方式
  loginChannel?: LoginChannelEnum;
}

// 注册页 URL 参数
export interface RegisterQuery extends BaseQuery {
  // 注册渠道
  registerChannel?: RegisterChannelEnum;
  // 邀请码
  corpInvitationCode?: string;
  // 被邀请人邮箱
  email?: string;
  // 企业名称
  corporation?: string;
}

// 邀请页 URL 参数
export interface InviteQuery extends BaseQuery {
  // 邀请状态
  inviteState: JoinCorpState;
  // 被邀请租户
  beInvitedMember: string;
  // 邀请租户
  corpName: string;
  // 租户管理员
  corpManager: string;
  corpInvitationCode?: string;
}

// 三方登录后注册手机页 URL 参数
export interface RegisterThirdPhoneQuery extends BaseQuery {
  // 微信扫码后端生成的key，微信扫码后端跳转到前端注册页的时候会带回此参数，此场景下前端调注册接口时需传入此参数
  loginReqKey: string;
  // 微信用户信息key，微信扫码后端跳转到前端注册页的时候会带回此参数，此场景下前端调注册接口时需传入此参数
  userInfoKey: string;
}

// 登录渠道配置
export interface LoginChannelConfig {
  id: LoginChannelEnum;
  title: string;
  appId?: string;
  default?: boolean;
}

// 注册渠道配置
export interface RegisterChannelConfig {
  id: RegisterChannelEnum;
  title: string;
  default?: boolean;
}
export interface LimitedRedirect {
  // 正则匹配字符串数组，在该规则内的允许跳转
  rules: string[];
  // 默认跳转地址，当 rules 都无法匹配的时候，默认跳转到 xxx
  default: string;
}
// 统一登录注册 DA 版本配置
export interface DataAuthReleaseConfig {
  id: ReleaseEnum;
  // 版本标题
  title?: string;
  // 版本 icon
  icon?: string;
  // dataauth 服务地址，例如：beacon.tencent.com
  serverHost?: string;
  // 独立部署 服务地址，例如：datatalk.beacon.tencent.com
  selfServerHost?: string;
  // 移动端控制台url
  mobileCtlUrl?: string;
  // 登录方式
  loginChannelConfig: LoginChannelConfig[];
  // 注册方式
  registerChannelConfig?: RegisterChannelConfig[];
  // 限制重定向的
  limitedRedirect?: LimitedRedirect;
  featureLimit?: {
    // 禁用修改密码
    disableChangePass: boolean;
    // 禁用主动注册
    disableRegist: boolean;
    // 禁用同样协议
    disablePolicy: boolean;
  };
}

export enum JoinCorpState {
  // 没有加入租户
  NotJoinCorp = '0',
  // 加入成功
  JoinCorpSuccess = '1',
  // 已加入租户
  JoinedCorp = '2',
  // 邀请失效
  InviteInvalid = '-1',
  // 加入租户失效
  JoinCorpFailed = '-2',
  // 未登录【前端自己的】
  NoLogin = '-99',
}

export type RegistCorpRes =
  | ResStatus.InviteSuccessByEmailOrMessage
  | ResStatus.InviteFail
  | ResStatus.InvalidInviteLink;
// 注册结果对加入租户状态的映射
export const RegistResTopJoinCorpStateMap: {
  [key in RegistCorpRes]: JoinCorpState;
} = {
  [ResStatus.InviteSuccessByEmailOrMessage]: JoinCorpState.JoinCorpSuccess,
  [ResStatus.InviteFail]: JoinCorpState.InviteInvalid,
  [ResStatus.InvalidInviteLink]: JoinCorpState.InviteInvalid,
};

/**
 * 成员状态
 */
export enum MemberStatus {
  // 等待中
  Waiting = 3,
  // 已加入
  Success = 1,
  // 已移除
  Removed = 2,
  // 其他
  OTNHERS = -99,
}

/**
 * 成员状态
 */
export const MemberStatusWords = {
  // 已加入
  [MemberStatus.Success]: '已加入',
  // 已移除
  [MemberStatus.Removed]: '已移除',
  // 等待中
  [MemberStatus.Waiting]: '待处理邀请',
};

export enum InviteTimeGroup {
  Day = 'd',
  Hour = 'h',
  Month = 'm',
}

/**
 * 邀请类型
 */
export enum InviteType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  URL = 'URL',
}
