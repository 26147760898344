/*
 * @Author: reexliu reexliu@tencent.com
 * @Date: 2022-06-10 16:20:46
 * @LastEditors: reexliu reexliu@tencent.com
 * @LastEditTime: 2022-06-29 23:43:36
 * @FilePath: /beacon_data_suite_copy/packages/data-manager/src/composables/table-data.ts
 */
import { computed, Ref } from 'vue';

export function useSearchData<T>(
  data: Ref<T[]>,
  filter: Ref<string>,
  getText: (row: T) => string[]
): Ref<T[]> {
  return computed(() => {
    if (!filter.value) {
      return data.value ?? [];
    }
    const pattern = filter.value.toLowerCase();
    return (data.value ?? []).filter(row =>
      getText(row).some(text => text?.toLowerCase().includes(pattern))
    );
  });
}

export function useDisplayData<T>(
  data: Ref<T[] | null>,
  pagination: {
    pageNo: number;
    pageSize: number;
  }
): Ref<T[]> {
  return computed(() =>
    (data.value ?? []).slice(
      (pagination.pageNo - 1) * pagination.pageSize,
      (pagination.pageNo - 1) * pagination.pageSize + pagination.pageSize
    )
  );
}
