/*
 * @Author: kelvinywang
 * @Date: 2022-10-08 16:34:02
 * @LastEditors: kelvinywang kelvinywang@tencent.com
 * @LastEditTime: 2022-10-16 13:44:50
 * @Description: 模拟路由，处理登录/注册模块
 */
import { cloneDeep, merge, pick } from 'lodash-es';
import { Component, computed, defineAsyncComponent, Ref } from 'vue';
import { LocationQueryRaw, NavigationFailure } from 'vue-router';
import $router from '@/router';
import {
  BaseQuery,
  JoinCorpState,
  LoginQuery,
  RegisterChannelEnum,
  RegisterQuery,
  RegisterThirdPhoneQuery,
} from '../types';
import { useDAReleasesStore } from '../store/common';

const ROUTE_COMPONENTS = {
  '/login': () => import('../views/login'),
  '/login/password-forget': () => import('../views/password-forget'),
  '/register': () => import('../views/register'),
  '/register/third-bind': () => import('../views/register-third-bind'),
  '/register/email-bind-phone': () => import('../views/register/email-bind-phone.vue'),
  '/register/success': () => import('../views/register/regist-success.vue'),
  '/invite/link': () => import('../views/invite/invite-link.vue'),
  '/invite/result': () => import('../views/invite/intive-result.vue'),
};

type RouteComponentTypes = keyof typeof ROUTE_COMPONENTS;

/**
 * 登录注册页路由
 * @returns
 */
export const useFakeRouter = function useFakeRouter(): Ref<Component | undefined> {
  return computed(() => {
    // 只有当登录版本配置初始化完成后，才可显示界面
    if (!useDAReleasesStore().done) return;
    const route = $router.currentRoute.value;
    const pageComponent = ROUTE_COMPONENTS[route.path as RouteComponentTypes];
    return defineAsyncComponent(pageComponent);
  });
};

/**
 * 修改路由 query
 * 注意：修改路由的动作为异步，避免在同步方法内多次调用，必须注意调用顺序，使用 await
 * @param query
 */
export const modifyRouteQuery = function modifyRouteQuery<T>(
  query: T
): Promise<NavigationFailure | void | undefined> {
  const newRoute = merge(cloneDeep(pick($router.currentRoute.value, ['path', 'query', 'params'])), {
    query,
  });
  return $router.replace(newRoute);
};

/**
 * 获取 URL 基础参数（版本、重定向 url）
 * @returns
 */
const buildQuery = function buildQuery<T extends object>(query?: T): LocationQueryRaw {
  const baseQuery = pick(($router.currentRoute.value.query as unknown) as BaseQuery, [
    'release',
    'dataAuthRedirectUrl',
    'inviteCode',
  ]);
  return {
    ...(query || {}),
    ...baseQuery,
  };
};

/**
 * 前往统一登录注册内的其他页面
 */
export const gotoPage = {
  login(query?: LoginQuery) {
    return $router.push({
      path: `/login`,
      query: buildQuery(query),
    });
  },
  register(query?: RegisterQuery) {
    return $router.push({
      path: `/register`,
      query: buildQuery(query),
    });
  },
  backToRegister(
    query?: RegisterQuery,
    params?: {
      email: string;
      password: string;
      mailVerificationCode: string;
      phoneNum: string;
      phoneVerificationCode: string;
    }
  ) {
    return $router.push({
      name: `OneRegister`,
      query: buildQuery(query),
      params,
    });
  },
  passwordForget(query?: BaseQuery) {
    return $router.push({
      path: `/login/password-forget`,
      query: buildQuery(query),
    });
  },
  registerPhoneThird(query?: RegisterThirdPhoneQuery) {
    return $router.push({
      path: `/register/third-bind`,
      query: buildQuery(query),
    });
  },
  registerEmailBindPhone(params: {
    email: string;
    mailVerificationCode: string;
    password: string;
    corpInvitationCode?: string;
    phoneNum?: string;
    phoneVerificationCode?: string;
  }) {
    return $router.push({
      name: 'EmailBindPhone',
      query: buildQuery(),
      params,
    });
  },
  registerSuccess(query?: {
    registerChannel?: RegisterChannelEnum | undefined;
    extraType?: string;
    ticket?: string;
  }) {
    return $router.push({
      path: '/register/success',
      query: buildQuery(query),
    });
  },
  inviteResult(query?: {
    inviteState?: JoinCorpState;
    corpInvitationCode?: string;
    corpName?: string;
    corpManager?: string;
    beInvitedMember?: string;
  }) {
    return $router.push({
      path: '/invite/result',
      query: buildQuery(query),
    });
  },
  inviteLink(query?: { inviteCode?: string }) {
    return $router.push({
      path: '/invite/link',
      query: buildQuery(query),
    });
  },
};
