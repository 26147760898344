import Axios from 'axios';
import { BaseMessage } from '@/components/base-message';
import router from '@/router';
import {
  ApplyRuleConfig,
  PermissionList,
  ReqRowColConfigList,
  RowColInfo,
  SensitiveCol,
  SensitiveRow,
} from './types';
import { FormData } from './EditRuleDrawer/types';
import { ColConfig, RowConfig } from '../components/DeclareDrawer/types';
import { PageViewTreeNode, RowColRuleWhiteListItem } from './ApplyRangeDialog/types';

const axios = Axios.create({
  baseURL: '/api',
  withCredentials: true, // 跨域请求时发送cookie
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 2000000, // 毫秒
});

axios.interceptors.request.use(config => {
  const bizId = router.currentRoute.value.params.biz_url;
  const bizIdObj = { bizId };
  if (config.headers) {
    Object.assign(config.headers, bizIdObj);
  } else {
    Object.assign(config, { headers: bizIdObj });
  }
  if (config.params) {
    Object.assign(config.params, bizIdObj);
  } else {
    Object.assign(config, { params: bizIdObj });
  }
  return config;
});

axios.interceptors.response.use(async response => {
  if (response.status !== 200) {
    BaseMessage({ type: 'error', message: '服务异常，请稍后重试' });
    return Promise.reject(response);
  }
  if (response.data.code) {
    BaseMessage({ type: 'error', message: response.data.message });
    return Promise.reject(response);
  }
  return response.data;
});

// 获取全量的敏感行信息
export const getSensitiveRows = (): Promise<SensitiveRow[]> =>
  axios.get('/datatalk/server/sensitive/rows').then(({ data }) => data);

// 获取全量的敏感列信息
export const getSensitiveCols = (): Promise<SensitiveCol[]> =>
  axios.get('/datatalk/server/sensitive/cols').then(({ data }) => data);

// 保存全量的敏感列信息
export const saveSensitiveCols = (sensitiveColList: ColConfig[]): Promise<any> =>
  axios.post('/datatalk/server/sensitive/cols/save', { sensitiveColList });

// 获取全局权限白名单豁免列表
export const getRowColRuleWhiteList = (): Promise<RowColRuleWhiteListItem[]> =>
  axios.get('/datatalk/server/rowandcol/white/list').then(({ data }) => data);

// 保存全局权限白名单豁免列表
export const saveRowColRuleWhiteList = (pageIds: number[]): Promise<any> =>
  axios.post('/datatalk/server/rowandcol/white/add', { pageIds });

// 保存全量的敏感行信息
export const saveSensitiveRows = (sensitiveRowList: RowConfig[]): Promise<any> =>
  axios.post('/datatalk/server/sensitive/rows/save', { sensitiveRowList });

// 添加行列权限规则
export const createRowColConfig = (data: FormData): Promise<any> =>
  axios.post('/datatalk/server/rowandcol/config/add', data);

// 修改行列权限规则
export const modifyRowColConfig = (data: FormData): Promise<any> =>
  axios.post('/datatalk/server/rowandcol/config/modify', data);

// 获取行列权限规则详情
export const getRowColConfigDetail = (params: { id: number }): Promise<FormData> =>
  axios.get('/datatalk/server/rowandcol/config/detail', { params }).then(res => res.data);

// 获取行列权限规则列表
export const getRowColConfigList = (params: ReqRowColConfigList): Promise<RowColInfo> =>
  axios.get('/datatalk/server/rowandcol/config/page', { params }).then(res => res.data);

// 删除行列权限规则
export const deleteRowColConfigDetail = (data: { id: number }): Promise<any> =>
  axios.post('/datatalk/server/rowandcol/config/delete', data);

// 获取权限
export const getRowColPermission = (): Promise<PermissionList[]> =>
  axios.get('/datatalk/server/biz/category').then(({ data }) => data);

// 批量申请
export const batchApply = (data: ApplyRuleConfig): Promise<any> =>
  axios.post('/datatalk/server/permission/batch/apply', data);

// 空间获取目录树
export const getPageTree = (params: {
  bizId: string;
  parentId?: number;
  showAll?: boolean;
  queryType?: string;
}): Promise<PageViewTreeNode> =>
  axios.get('/datatalk/server/page_view/tree', { params }).then(({ data }) => data);
