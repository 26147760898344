import * as EmailValidator from 'email-validator';

export const emailValidate = (v: string): boolean => EmailValidator.validate(v);

export const phoneValidate = (v: string, code?: string): boolean => {
  const tmp = v?.trim() || '';
  if (!code || code === '+86') {
    return /^[1][3-9][0-9]{9}$/.test(tmp);
  }
  // TW 9开头，长度9
  if (code === '+886') {
    return /^[9][0-9]{8}$/.test(tmp);
  }
  // HK 长度9
  if (code === '+852') {
    return /^[0-9]{8}$/.test(tmp);
  }
  // 澳门 6开头 长度8
  if (code === '+853') {
    return /^[6][0-9]{7}$/.test(tmp);
  }
  // 其他长度默认大于4
  return tmp.length > 4;
};

/**
 * 生成组合
 * @param arr
 * @param k
 * @param prefix
 */
const regexCombinations = (arr: string[], k: number, prefix: string[] = []): any => {
  if (k === 0) return [prefix];
  return arr.flatMap((v: any, i) => regexCombinations(arr.slice(i + 1), k - 1, [...prefix, v]));
};

export const passwordTypeLimitRegExps = regexCombinations(
  ['[A-Z]{1,}', '[a-z]{1,}', '[0-9]{1,}', '[_!@#$%^&*+-.~]{1,}'],
  3
).map((i: string[]) => i.map(j => new RegExp(j)));

/**
 * 密码正则校验
 * 改正则时候，记得运行一下validate.spec.ts文件
 * 可视化网站推荐：https://regexper.com/
 * @param v
 */
export const passwordValidate = (v: string): boolean =>
  /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z!@#$%^&*+\-.~_]+$)(?![a-z\d]+$)(?![a-z!@#$%^&*+\-.~_]+$)(?![\d!@#$%^&*+\-.~_]+$)[A-Za-z\d!@#$%^&*+\-.~_]{8,32}$/.test(
    v
  );
