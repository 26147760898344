/*
 * @Author: kelvinywang
 * @Date: 2022-10-08 19:57:34
 * @LastEditors: kelvinywang kelvinywang@tencent.com
 * @LastEditTime: 2022-10-16 13:57:57
 * @Description: 统一登录注册 公共
 */
import { defineStore } from 'pinia';
import $router from '@/router';
import { merge, omit } from 'lodash-es';
import { BaseQuery, DataAuthReleaseConfig, ReleaseEnum } from '@/views/one-login/types';
import { modifyRouteQuery } from '../composables/use-fake-router';
import { useIOALogin } from '../widgets/ioa-login-dialog/use-ioa-login';
import { fetchLoginReleaseConfig } from '../apis/login';
import { useEncrypt } from '@/views/one-login/composables/use-encrypt';

interface DAReleaseState {
  release: ReleaseEnum;
  allReleaseConfig: DataAuthReleaseConfig[];
}

const genExtraQuery = (release: ReleaseEnum): { dataAuthRedirectUrl?: string } => {
  if ($router.currentRoute.value.query.release !== release) {
    return {
      dataAuthRedirectUrl: '',
    };
  }
  return {};
};

// 当前支持的所有 DA 版本配置
export const useDAReleasesStore = defineStore('dataauthReleases', {
  state: (): DAReleaseState => ({
    // 当前版本
    release: ReleaseEnum.DEFAULT,
    // DataAuth 版本配置信息
    allReleaseConfig: [],
  }),
  getters: {
    // 当前版本配置
    releaseConfig: state =>
      state.allReleaseConfig.find((config: DataAuthReleaseConfig) => config.id === state.release),
    otherRealseVersion: state =>
      state.allReleaseConfig.filter((config: DataAuthReleaseConfig) => config.id !== state.release),
    isNotDftVersion: state => state.release && state.release !== ReleaseEnum.DEFAULT,
    // 是否初始化完成
    done: state => !!state.allReleaseConfig.length,
    // 是否只有一个版本
    singleRelease: state => state.allReleaseConfig.length === 1,
  },
  actions: {
    /**
     * 获取支持的版本配置
     * @returns
     */
    async fetchReleasesConfig() {
      this.allReleaseConfig = await fetchLoginReleaseConfig();
      this.allReleaseConfig = [
        ...this.allReleaseConfig,
        {
          id: ReleaseEnum.PRIVATE,
          title: '私有化企业版',
          icon: 'privatization-filled',
          selfServerHost: 'https://doc-private.beacon.tencent.com/page/home',
        },
        {
          loginChannelConfig: [{ id: 'IOA', title: 'IOA 登录' }],
          id: ReleaseEnum.OVERSEAS,
          icon: 'overseas-filled',
          title: '腾讯海外版',
          selfServerHost: 'https://beacon.sgwoa.com',
        },
      ] as any;
      console.log('当前统一登录注册支持的所有版本配置', this.allReleaseConfig);
      return this.allReleaseConfig;
    },
    /**
     * 初始化 设置当前版本
     */
    async initRelease() {
      const queryRelease = $router.currentRoute.value.query.release as ReleaseEnum;
      await this.selectRelease(queryRelease);
      return;
    },
    /**
     * 选择版本
     * @param release
     * @returns
     */
    async selectRelease(release: ReleaseEnum) {
      // 如果 URL 上的版本不在当前支持的版本内，使用默认版本
      if (release === ReleaseEnum.DEFAULT || !Object.values(ReleaseEnum).includes(release)) {
        this.release = ReleaseEnum.DEFAULT;
        const { query: oldQuery, path } = $router.currentRoute.value;
        const query = omit(oldQuery, 'release');
        // 删除 query.release
        return $router.replace({
          path,
          query: merge(query, genExtraQuery(release)),
        });
      }
      this.release = release;
      return modifyRouteQuery<BaseQuery>({
        release: this.release,
        ...genExtraQuery(release),
      });
    },
    /**
     * 获取版本配置
     * @param release
     * @returns
     */
    getReleaseConfig(release: ReleaseEnum): DataAuthReleaseConfig | undefined {
      return this.allReleaseConfig.find(releaseConfig => releaseConfig.id === release);
    },
  },
});
