/*
 * @Author: kelvinywang
 * @Date: 2022-06-16 18:08:45
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-10-26 18:06:35
 * @Description: 基础 Message 弹窗
 */
import 'element-plus/es/components/message/style/css';
import './base-message.less';
import { ElMessage } from 'element-plus';
import type { MessageOptions } from 'element-plus/lib/components/message/src/message';
import { showToast } from 'vant';
import { isMobile } from '@/utils';
import { MessageHandle, MessageParamsTyped } from 'element-plus/es/components/message/src/message';
export const BaseMessage = (
  options: MessageParamsTyped,
  appContext = null,
): MessageHandle | undefined => {
  const scopeOptions = options as Partial<MessageOptions>;
  if (isMobile()) {
    const teleport = document.getElementById('app');
    showToast({ message: scopeOptions.message as string, teleport, className: 'vant-error-toast', duration: 3000 });
    return;
  }
  return ElMessage({
    customClass: 'dsjtj-base-message',
    ...scopeOptions,
  }, appContext);
};
