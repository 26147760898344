/*
 * @Author: kelvinywang
 * @Date: 2022-09-09 16:16:57
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-09-26 17:13:12
 * @Description: 微信扫码登录 SDK
 * @Doc: https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
 */
import { setLoginIframeAttrs } from './login-iframe-attrs';

export interface WxLoginParams {
  host: string;
  // 二维码挂载的节点 ID
  el: string;
  // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，
  // false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。
  // 默认为 false。
  selfRedirect: boolean;
  // 应用唯一标识，在微信开放平台提交应用审核通过后获得
  appid: string;
  // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
  scope: string;
  // 重定向地址，需要进行UrlEncode
  redirectUrl: string;
  // 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
  state?: string;
  // 提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
  style?: string;
  // 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
  href?: string;
}

export default function wxLogin(params: WxLoginParams): void {
  const container = window.document.getElementById(params.el);
  if (!container) {
    throw Error(`WxLogin ID 为 ${params.el} Dom 节点不存在`);
  }
  const selfRedirect = params.selfRedirect ? 'true' : 'false';
  const wxIframe = window.document.createElement('iframe');
  const url = `https://open.weixin.qq.com/connect/qrconnect?
    appid=${params.appid}
    &scope=${params.scope}
    &redirect_uri=${params.redirectUrl}
    &state=${params.state}
    &login_type=jssdk
    &self_redirect=${selfRedirect}
    ${params.style ? `&style=${params.style}` : ''}
    ${params.href ? `&href=${params.href}` : ''}`.replaceAll(' ', '');
  wxIframe.src = url;
  setLoginIframeAttrs(wxIframe);
  container.innerHTML = '';
  container.appendChild(wxIframe);
}
