/*
 * @Author: kelvinywang
 * @Date: 2022-10-10 11:00:21
 * @LastEditors: kelvinywang kelvinywang@tencent.com
 * @LastEditTime: 2022-10-16 13:50:11
 * @Description: 验证码相关接口
 */
import request, { buildUrl } from './request';
import { ResStatus } from './types';

// 发送验证码的类型
export enum SendVerificationCodeType {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export interface MachineVerificationCode {
  // 人机校验
  randomString: string;
  // 人机校验
  ticket: string;
}

// 发送手机/邮箱验证码请求体
export interface SendVerificationCodeReq extends MachineVerificationCode {
  // 接收验证码的地址（即区号+手机号）
  receiveAddress: string;
  // 区号，手机必传
  areaCode?: string;
  // 校验邮箱后缀，邮箱必传
  checkEmailSuffix?: boolean;
  // 是否校验手机号/邮箱注册情况
  checkRegister: boolean;
  sendType: SendVerificationCodeType;
}

// 发送手机验证码接口响应
export type SendVerificationCodeRes =
  | ResStatus.Success
  // 该手机号已注册，在传参 checkPhoneRegister: true 情况下
  | ResStatus.InvalidPhoneExist
  | ResStatus.InvalidEmailExist
  // 手机号不存在，在传参 checkPhoneRegister: true 情况下
  | ResStatus.InvalidExist;

// 校验验证码是否正确
export interface CheckVerificationCodeReq {
  // 接收验证码的地址,邮箱地址或手机号
  receiveAddress: string;
  // 验证码
  verificationCode: string;
  sendType: SendVerificationCodeType;
}

/**
 * 发送验证码
 * - 手机号登录
 * - 忘记密码时发送验证码需要校验手机号（checkPhoneRegister：true）
 * @param req
 * @returns
 */
export function sendVerificationCode(
  req: SendVerificationCodeReq
): Promise<SendVerificationCodeRes> {
  return request
    .post(buildUrl('/api/dataauth/authserver/login/verificationCode/send', true), req, {}, true)
    .then(res => res?.result);
}

/**
 * 校验验证码状态是否正确
 * @param req
 * @returns {Promise<boolean>} true正确，false 验证码错误
 */
export function checkVerificationCode(req: CheckVerificationCodeReq): Promise<boolean> {
  return request
    .get(buildUrl('/api/dataauth/authserver/login/verificationCode/check', true), req)
    .then(res => res?.result);
}

/**
 * 校验 IOA 登录
 * @returns
 */
export function verifyInIOA(): Promise<void> {
  return request
    .get(
      'https://test.beacon.woa.com/api/dataauth/authserver/login/checkInner',
      {},
      {
        timeout: 1000,
      }
    )
    .then(res => res.result);
}
