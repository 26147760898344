import { Operator, RuleType } from '../constants';
import { AuthContent } from './widgets/auth-search/types';

export interface RowConf {
  fieldKey: string;
  relationalOperator: Operator;
  fieldValue: string;
  fieldValueList: string[];
}

export const DEFAULT_FORM_DATA = {
  id: null as null | number,
  remark: '',
  before: [] as AuthContent[],
  members: [] as AuthContent[],
  ruleType: RuleType.ROW,
  rowRuleConfigList: [
    {
      fieldKey: '',
      relationalOperator: Operator.In,
      fieldValue: '',
      fieldValueList: [],
    },
  ] as RowConf[],
  colRuleConfigList: [] as { groupId: string }[],
  firstApprover: 'DIRECT_LEADER',
  secondApprover: '',
};

export type FormData = typeof DEFAULT_FORM_DATA;
