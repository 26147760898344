/*
 * @Author: kelvinywang
 * @Date: 2022-09-09 16:46:07
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-09-26 17:13:10
 * @Description: 企业微信三方扫码登录
 * @Doc: https://developer.work.weixin.qq.com/document/path/91124
 */
import { setLoginIframeAttrs } from './login-iframe-attrs';

export enum UserType {
  Admin = 'admin',
  Member = 'member',
}

export interface Ww3rdLoginParams {
  host: string;
  // 二维码挂载的节点 ID
  el: string;
  // 服务商的 CorpID
  appid: string;
  // 授权登录之后目的跳转网址，需要做urlencode处理。所在域名需要与授权完成回调域名一致
  redirectUrl: string;
  // 需要与登录的服务域名一致，示例：https://beacon.tencent.com
  referer: string;
  // 用于企业或服务商自行校验session，防止跨域攻击
  state?: string;
  // 支持登录的类型。
  // admin: 代表管理员登录（使用微信扫码）
  // member: 代表成员登录（使用企业微信扫码）
  // 默认为admin
  usertype?: UserType;
  // 自定义语言，支持zh、en；lang为空则从Headers读取Accept-Language
  // 默认值为 zh
  lang?: string;
  // 样式代码
  href: string;
}

export default function ww3rdLogin(params: Ww3rdLoginParams): void {
  const container = window.document.getElementById(params.el);
  if (!container) {
    throw Error(`WxLogin ID 为 ${params.el} Dom 节点不存在`);
  }
  const url = `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?
      appid=${params.appid}
      &usertype=${params.usertype || UserType.Admin}
      &redirect_uri=${params.redirectUrl}
      &referer=${params.referer}
      &href=${params.href}
      ${params.state ? `&state=${params.state}` : ''}`.replaceAll(' ', '');
  const wwCordIframe = document.createElement('iframe');
  wwCordIframe.src = url;
  setLoginIframeAttrs(wwCordIframe);
  container.innerHTML = '';
  container.appendChild(wwCordIframe);
}
