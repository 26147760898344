/*
 * @Author: cocogong
 * @Date: 2022-04-20 16:42:28
 * @LastEditTime: 2022-04-27 16:06:32
 * @LastEditors: cocogong
 * @Description:
 */
import { defineComponent, render, createVNode } from 'vue';
import DtMessage from './message.vue';

const count = 6000;

const dtMessage = (options = {}) => {
  if (typeof options === 'string') {
    // eslint-disable-next-line no-param-reassign
    options = {
      message: options,
    };
  }

  // 创建一个 div 容器
  const container = document.createElement('div');
  // 创建 Message 实例，createVNode 的性能比 h 更好
  const vm = createVNode(DtMessage, options);
  render(vm, container);
  container.style.zIndex = count;
  document.body.appendChild(container);

  // 返回一个主动关闭的方法
  const close = () => {
    vm.closed = true;
  };
  return close;
};

export default dtMessage;
