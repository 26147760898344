/*
 * @Author: beenlezhang
 * @Date: 2021-02-19 11:49:19
 * @LastEditors: yanhongchen
 * @LastEditTime: 2023-03-15 15:07:16
 * @Descripttion:
 */
export interface Map {
  [key: string]: string;
}

interface NumberMap {
  [key: string]: number;
}

// 时间粒度
const timeScale = {
  year: 'year',
  month: 'month',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  dayWithLine: 'dayWithLine',
  secondWithLine: 'secondWithLine',
};

// 时间面板显示格式
const dateFormat: Map = {
  [timeScale.year]: 'YYYY',
  [timeScale.month]: 'YYYY-MM',
  [timeScale.day]: 'YYYY-MM-DD',
  [timeScale.hour]: 'YYYY-MM-DD HH',
  [timeScale.minute]: 'YYYY-MM-DD HH:mm',
  [timeScale.second]: 'YYYY-MM-DD HH:mm:ss',
  [timeScale.dayWithLine]: 'YYYY/MM/DD',
  [timeScale.secondWithLine]: 'YYYY/MM/DD HH:mm:ss',
};

// 审批状态
const approvalStatus: Map = {
  '-1': '全部',
  0: '未审批',
  1: '已通过',
  2: '已拒绝',
};

// 审批人类型
const approvalUser: Map = {
  '-1': '全部',
  0: '自己',
  1: '其他人',
};

// 登录错误提示
const loginTip: Map = {
  emptyName: '账号名称不可为空',
  emptyPwd: '密码为空或不满足格式要求',
  invalidPwd: '账号或密码错误',
  name20Less: '账号名称不超过20个字符',
  weakPwd: '密码设置不符合要求',
  pwdNotEqual: '两次输入的密码不一致，请重新输入',
  invalidPhone: '手机号码格式不符合要求',
  captchaFailed: '验证码校验失败',
  nameExist: '该账号已存在',
  nameNotExist: '账号不存在，请重新输入',
  registerFailed: '注册失败',
  loginSuccess: '登录成功',
  registerSuccess: '注册成功',
  registerBindWxSuccess: '注册成功，并与微信完成绑定',
  invalidEmail: '邮箱为空或格式不符合要求',
  emailExist: '该邮箱已存在',
  emailNotExist: '该邮箱未在平台注册，请确认是否输入正确',
  invalidCodeFormat: '验证码为空或格式不符合要求',
  sendCodeFail: '发送验证码失败，请重试',
  checkAgreement: '请阅读注册用户协议并勾选',
  resetFail: '重置密码失败，请重试',
  fullNameEmpty: '真实姓名不能为空',
  usernameTooLong: '用户名不可超过20字',
};

// 公司注册及修改提示
const updateCompanyTip: Map = {
  emptyName: '企业名称不可为空',
  nameTooLong: '企业名称不可超过20字',
  repeatName: '该企业名称已存在',
  emptyIndustry: '请选择所属行业',
  emptyContact: '联系人不可为空',
  invalidPhone: '联系电话为空或格式不符合要求',
  notAdmin: '当前页面仅支持企业管理员查看',
  nameExist: '该企业名称已存在',
  addMemberFail: '添加成员失败',
  invalidUploadType: '请上传csv格式文件',
  captchaFailed: '验证码验证失败',
  openChecked: '请勾选需要开通的服务',
  contactTooLong: '联系人不可超过20字',
  noCompanyApp: '您的企业没有开通当前产品，请联系管理员',
};

const addCompanyMemberTip: Map = {
  emptyValue: '请输入账号',
  success: '操作成功',
};

const uploadCompanyMemberTip: Map = {
  0: '全部邀请失败',
  1: '邀请发送成功',
  2: '部分账号不合规，仅部分邀请成功',
};

const createCompanyErrorTip: Map = {
  3: '验证码验证失败',
};

// 微信登录或微信账号绑定失败
const wxLoginBindTip: Map = {
  0: '请先完成账号绑定',
  1: '绑定成功',
  2: '请先完成账号绑定',
  3: '微信登录成功',
  '-1': '登录失败，请重试',
  '-2': '微信登录失败，请重试',
  '-3': '账号注册后绑定微信失败，请重试',
  '-4': '微信登录失败，请重试',
  '-5': '账号注册后绑定微信失败，请重试',
  '-6': '微信登录失败，请重试',
  '-7': '账号注册后绑定微信失败，请重试',
  '-8': '账号注册后绑定微信失败，请重试',
  '-9': '微信账号已绑定其他账密账号',
  '-10': '微信登录失败，请重试',
};

const registerTip: Map = {
  0: '注册成功',
  '-4': '验证码验证失败',
  '-5': '密码不合规',
  '-6': '操作失败，请重新输入',
  '-7': '绑定失败，请重新微信扫码登录',
};

// 登录并账号绑定
const loginBindAccountTip: Map = {
  0: '绑定成功',
  '-5': '该邮箱已绑定其他微信号',
  '-6': '绑定失败，请重新输入',
  '-7': '绑定失败，请重新微信扫码登录',
  '-8': '账号不存在，请重新输入',
  '-9': '密码错误',
};

const checkEmailTip: Map = {
  '-1': '邮箱格式不符合要求',
  '-2': '该邮箱未在平台注册，请确认是否输入正确',
  '-3': '该邮箱已存在',
};

const externalBindFailMap: Map = {
  '-9': '当前扫码账号已经绑定其它邮箱',
  '-20': '当前扫码账号已经绑定其它邮箱',
  '-22': '企业邀请码无效',
  '-999': '系统未知异常，请重新扫码',
};

const deleteDesc: Map = {
  deleteRole: '删除角色组后，同步会收回该角色组的全部授权，是否确认删除该角色组？',
  notEmptyRole: '该角色组仍有成员，暂不支持删除，若坚持删除请移除成员后再进行删除操作。',
};

// 接口操作提示信息
const messageDesc: Map = {
  loginSuccess: '登录成功',
  logoutSuccess: '退出登录成功，前往登录页',
  notManager: '您还不是当前空间和应用的管理员，暂无该页面权限',
  roleNotEmpty: '角色组名称不能为空',
  addRoleSucc: '创建角色组成功',
  memberNotEmpty: '新添加的角色不能为空',
  addMemberSucc: '添加成员成功',
  deleteMemberSucc: '删除成员成功',
  deleteRoleSucc: '删除角色组成功',
  saveRoleSucc: '保存角色组权限成功',
  applyRoleSucc: '申请成功，你可以在【我的申请列表】查看你详细的申请记录',
};

// 注册登录后所属企业状态，-1-未绑定企业，0-企业待审批，1-企业审批通过，2-企业审批未通过
const companyStatusMap: NumberMap = {
  none: -1,
  review: 0,
  approved: 1,
  rejected: 2,
};

// 权限列表审批状态
const authStateMap: Map = {
  FIRST_WAIT: '等待一级审批',
  SECOND_WAIT: '等待二级审批',
  PASS: '通过',
  REJECT: '拒绝',
};

// 权限列表审批状态
const authStepMap: Map = {
  1: '一级审批人',
  2: '二级审批人',
};

const pathMap: Map = {
  TobApplyList: 'applylist',
  TobApproveList: 'approvelist',
  TobAuthRoleGroup: 'authrole',
  TobAuthProcess: 'authprocess',
  ApproveList: 'myApproveList',
  ApplyList: 'myApplyList',
  myAuthList: 'myAuthList',
  AuthRoleGroup: 'AuthRoleGroup',
  AuthRoleGroupFix: 'authRoleGroup',
  BizMemberManager: 'bizMemberManager',
  AuthProcess: 'authProcess',
  DataGrant: 'dataGrant',
  RowColGrant: 'rowColGrant',
  AuthDownload: 'authDownload',
  ApproveDetail: 'approveDetail',
  MyDataAuthList: 'myDataAuthList',
};

// DA登录地址
const loginUrlMap: Map = {
  'dataauth-dev1.testsite.woa.com':
    'https://dataauth-dev1.testsite.woa.com/authserver/login?loginChannel=YF&dataAuthRedirectUrl=',
  'bcenter-dataauth.growth.qq.com':
    'https://bcenter-dataauth.growth.qq.com/authserver/login?loginChannel=YF&dataAuthRedirectUrl=',
};

const { host: locationHost, href: locationHref } = location;
const isBcenterEnv = !!loginUrlMap[locationHost];
const captchaAppId = '2066765732';
const olapAppKey = 'beacon_olap';
const approvalCategory = 'DATA_COMMON';
const defaultAvatar =
  'https://beacon.cdn.qq.com/beacon_data_suite/static/images/default-avatar.png';

const unlimitedQuota = -1;

enum AdminType {
  COST_MANAGER = 'COST_MANAGER', // 成本负责人
  BUSINESS_ADMIN = 'ADMIN', // 业务负责人
}

export enum ApplyProcessType {
  default = 'default',
  custom = 'custom',
}

export const APPLY_TYPE_OPTIONS: { [key: string]: any }[] = [
  {
    label: '默认审批流程',
    value: ApplyProcessType.default,
    desc: '由统一权限审批人负责空间内的权限申请处理',
  },
  {
    label: '自定义审批流程',
    value: ApplyProcessType.custom,
    desc: '为空间内的角色组设置不同的权限审批人',
  },
];

export enum TableMode {
  preview,
  edit,
}

export const EVENT_NAME = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
};

export enum MemberType {
  USER = 'USER',
  ORG = 'ORG',
}

export enum CategoryKey {
  BIZ = 'BIZ', // 默认审批流
  BIZ_CUSTOMIZED_DEFAULT = 'BIZ_CUSTOMIZED_DEFAULT', // 空间自定义默认审批流
  BIZ_ROLE = 'BIZ_ROLE', // 空间自定义角色组审批流,
}

export enum ApprovalStep {
  UNKNOWN, // 暂时未用
  FIRST, // 一级审批
  SECOND, // 二级审批
}

export enum ApproverType {
  DIRECT_LEADER = 'DIRECT_LEADER',
  NORMAL_USER = 'NORMAL_USER',
}

export {
  olapAppKey,
  dateFormat,
  approvalStatus,
  approvalUser,
  loginTip,
  deleteDesc,
  messageDesc,
  approvalCategory,
  defaultAvatar,
  captchaAppId,
  companyStatusMap,
  registerTip,
  updateCompanyTip,
  createCompanyErrorTip,
  addCompanyMemberTip,
  uploadCompanyMemberTip,
  wxLoginBindTip,
  externalBindFailMap,
  loginBindAccountTip,
  checkEmailTip,
  authStateMap,
  authStepMap,
  pathMap,
  isBcenterEnv,
  loginUrlMap,
  locationHost,
  locationHref,
  unlimitedQuota,
  AdminType,
};
