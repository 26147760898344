/*
 * @Author: kelvinywang
 * @Date: 2022-10-09 20:06:57
 * @LastEditors: yanhongchen
 * @LastEditTime: 2022-10-25 15:02:49
 * @Description: 统一登录注册请求封装
 */
import axios, { AxiosRequestConfig } from 'axios';
import { BaseMessage } from '@/components/base-message';
import { useDAReleasesStore } from '@/views/one-login/store/common';

export interface ResponseType<T = any> {
  code: number;
  msg: string;
  extra: string;
  result: T;
}

const http = axios.create({
  withCredentials: true, // 跨域请求时发送cookie
  timeout: 30000,
});

// 返回code枚举
enum RetCodeEnum {
  // 请求正常
  Normal = 1,
}

const request = function request<T>(
  config: AxiosRequestConfig & { showErrorMsg?: boolean }
): Promise<ResponseType<T>> {
  return http.request(config).then(res => {
    if (res?.data?.code === RetCodeEnum.Normal) {
      return res.data;
    }
    // 其他都为异常
    if (config.showErrorMsg) {
      BaseMessage({ type: 'error', message: res?.data?.msg || '服务异常' });
    }
    throw res?.data;
  });
};
export const buildUrl = function buildUrl(url: string, useVersionHost?: boolean): string {
  if (!useVersionHost) return url;
  const releasesStore = useDAReleasesStore();
  const suiteHost = releasesStore?.releaseConfig?.serverHost || '';
  const selfHost = releasesStore?.releaseConfig?.selfServerHost || suiteHost;
  // 请求后台域名区分独立跟套件版
  return `//${location.search.includes('fromSelfDomain') ? selfHost : suiteHost}${url}`;
};

export default {
  get<T = any, D = any>(
    url: string,
    params?: D,
    config?: AxiosRequestConfig,
    showErrorMsg?: boolean
  ): Promise<ResponseType<T>> {
    return request<T>({
      ...(config || {}),
      url,
      method: 'GET',
      params,
      showErrorMsg,
    });
  },
  post<T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
    showErrorMsg?: boolean
  ): Promise<ResponseType<T>> {
    return request<T>({
      ...(config || {}),
      url,
      method: 'POST',
      data,
      showErrorMsg,
    });
  },
};
