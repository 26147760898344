/*
 * @Author: beenlezhang
 * @Date: 2020-12-22 11:12:10
 * @LastEditors: beenlezhang
 * @LastEditTime: 2022-04-27 11:30:19
 * @Descripttion:
 */
import { StateType, RoleItem } from '@/@types';
import { Module } from 'vuex';

const state = {
  roleList: [] as RoleItem[],
  language: 'zhCN',
  theme: 'light',
  // 审批页的路由tab
  approvalTab: 'list',
  appKey: '',
  wxAppId: '',
  qywxAppId: '',
  clientTime: 0,
  serverTime: 0,
  hasWarnNotLogin: false,
  commonConfigFromRainbow: {},
};
type AuthStateType = typeof state;

const auth: Module<AuthStateType, StateType> = { namespaced: true, ...state };

export { AuthStateType, state };

export default auth;
